import React from "react";
import { contextapi } from "../../helpers/contextapi";
import is_null from "../../helpers/is_null";
import SelectStatus from "../../Select/SelectStatus";
const ChangeOfCourseLevelandSemester = ({ id }) => {
  const [level, setlevel] = React.useState(undefined);
  const [semester, setsemester] = React.useState(undefined);
  const context = React.useContext(contextapi);

  React.useEffect(() => {
    if (!is_null(level)) {
      context.setvalues({
        ...context.values,
        changeofCourseLevel: level,
      });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [level]);
  React.useEffect(() => {
    if (!is_null(semester)) {
      context.setvalues({
        ...context.values,
        changeofCourseSemester: semester,
      });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [semester]);
  return (
    <div>
      <SelectStatus
        label={"Select level to place the student"}
        options={[
          { name: "100 Level", value: "100 Level" },
          { name: "200 Level", value: "200 Level" },
          { name: "300 Level", value: "300 Level" },
          { name: "400 Level", value: "400 Level" },
        ]}
        setpagegroup={setlevel}
      />
      <SelectStatus
        label={"Select semester to place the student"}
        options={[
          { name: "First Semester", value: "First Semester" },
          { name: "Second Semester", value: "Second Semester" },
        ]}
        setpagegroup={setsemester}
      />
    </div>
  );
};

export default ChangeOfCourseLevelandSemester;
