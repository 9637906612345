import React from "react";
import { contextapi } from "../../helpers/contextapi";
import is_null from "../../helpers/is_null";

const TblGPADisplay = ({ sn, result }) => {
  const context = React.useContext(contextapi);
  let ptcur = 0;
  let ptue = 0;
  let ptcp = 0;
  let pgpa = 0;
  if (sn !== 0) {
    let { tcur: ptcur, tcue: ptue, tcp: ptcp, gpa: pgpa } = context.values;
  }
  const ctcur = Number(result?.map((v) => v.credit).reduce((a, b) => a + b, 0));
  const ctue = Number(
    result
      ?.filter((v) => v.ispass === true)
      ?.map((v) => v.credit)
      ?.reduce((a, b) => a + b, 0)
  );

  const ctcp = Number(result?.map((v) => v.point).reduce((a, b) => a + b, 0));
  let gpa = ctcp / ctcur;
  const cgpa = gpa.toFixed(2);

  React.useEffect(() => {
    context.setvalues({
      ...context.values,
      tcur: Number(ptcur) + ctcur,
      tcue: Number(ptue) + ctue,
      tcp: Number(ptcp) + ctcp,
      gpa: Number(pgpa) + cgpa,
    });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  let f = Number(cgpa) + Number(pgpa);
  const fcgpa = f.toFixed(2);
  return (
    <div>
      <table className="styled-tables" border={1}>
        <thead>
          <tr>
            <th></th>
            <th>TCUR</th>
            <th>TCUE</th>
            <th>TCP</th>
            <th>GPA</th>
            <th>CARRYOVER MODULE(S)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>CURRENT</th>
            <td>{ctcur}</td>
            <td>{ctue}</td>
            <td>{ctcp}</td>
            <td>{cgpa}</td>
            <td rowSpan={3}>
              {result
                ?.filter((v) => v.ispass === false)
                .map((v, k) => (
                  <span key={k} className="carryoverItem">
                    {v.code}
                  </span>
                ))}
            </td>
          </tr>
          <tr>
            <th>PREVIOUS</th>
            <td>{!is_null(ptcur) ? ptcur : 0}</td>
            <td>{!is_null(ptue) ? ptue : 0}</td>
            <td>{!is_null(ptcp) ? ptcp : 0}</td>
            <td>{!is_null(pgpa) ? pgpa : 0}</td>
          </tr>
          <tr>
            <th>CUMULATIVE</th>
            <td>{Number(ctcur) + Number(ptcur)}</td>
            <td>{Number(ctue) + Number(ptue)}</td>
            <td>{Number(ctcp) + Number(ptcp)}</td>
            <td>{fcgpa}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default TblGPADisplay;
