import React from "react";
import { axiospost, axiosput } from "../../helpers/httprequest";
import is_null from "../../helpers/is_null";
import swal from "../../helpers/swal";
import SelectStatus from "../../Select/SelectStatus";
import Modal from "react-modal";
import levelData from "../../StaticData/LevelDate.json";

const BtnTuitionFee = ({ modules, programme: prog }) => {
  const [showmodal, setshowmodal] = React.useState(false);
  const [programme, setprogramme] = React.useState(
    modules ? modules.programme : ""
  );
  const [name, setname] = React.useState(modules ? modules.label : "");
  const [amount, setamount] = React.useState(modules ? modules.amount : "");
  const [isnewstudent, setisnewstudent] = React.useState(
    modules ? modules.isnewstudent : ""
  );
  const [levelpayment, setlevelpayment] = React.useState(
    modules ? modules.levelpayment : ""
  );
  const [loading, setloading] = React.useState(false);
  const [status, setstatus] = React.useState(false);

  const _save = async () => {
    try {
      setloading(true);
      if (
        is_null(programme) ||
        is_null(name) ||
        is_null(status) ||
        is_null(amount)
      ) {
        swal("error", "Please fill the form properly", setloading);
        return;
      }
      let req;
      if (modules !== undefined) {
        const { _id } = modules;
        req = await axiosput(`ms_programme_tuition_fee_items/${_id}`, {
          programme,
          label: name,
          status,
          amount,
          isnewstudent,
          levelpayment,
        });
      } else {
        req = await axiospost("ms_programme_tuition_fee_items", {
          programme,
          label: name,
          status,
          amount,
          isnewstudent,
          levelpayment,
        });
      }

      if (req.status !== 200)
        throw new Error("Sorry something went wrong, please try again");
      const { type, message } = req.data;

      if (type === "success") {
        setloading(false);
        swal(type, message, setshowmodal, false);
      } else {
        swal(type, message, setloading, false);
      }
    } catch ({ message }) {
      swal("error", message, setloading);
    }
  };

  const _close = () => {
    setshowmodal(false);
  };
  const _show = () => {
    setshowmodal(true);
  };

  return (
    <div>
      <Modal
        isOpen={showmodal}
        onRequestClose={() => setshowmodal(false)}
        className="ReactModal"
      >
        <div className="modal-dialog " role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-center">
                {modules ? `Update ${modules.name}` : "Create Tuition Fee Item"}
              </h5>
            </div>
            <div className="modal-body">
              <SelectStatus
                setpagegroup={setprogramme}
                options={prog}
                label="Select Programme"
                active={programme}
              />
              <div className="form-group">
                <label htmlFor="exampleInputEmail2">Item Name/Label</label>
                <input
                  className="form-control form-control-lg"
                  id="exampleInputEmail2"
                  onChange={(e) => setname(e.target.value)}
                  placeholder="Item Name/Label"
                  value={name}
                />
              </div>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Amount (Number)</label>
                <input
                  type="number"
                  autoFocus
                  onChange={(e) => setamount(e.target.value)}
                  className="form-control form-control-lg"
                  id="exampleInputEmail1"
                  placeholder="Amount (Number)"
                  value={amount}
                />
              </div>
              <SelectStatus
                setpagegroup={setisnewstudent}
                options={[
                  { name: "Yes", value: true },
                  { name: "No", value: false },
                ]}
                label="Is this item for new students?"
                active={isnewstudent}
              />
              <SelectStatus
                setpagegroup={setstatus}
                options={[
                  { name: "Active", value: true },
                  { name: "In Active", value: false },
                ]}
                label="Item Status (Active means student should pay, In Active mean students will not see it)"
                active={status}
              />
              <SelectStatus
                setpagegroup={setlevelpayment}
                options={levelData}
                label="Is this item for a certain level?"
                active={levelpayment}
              />
            </div>
            <div className="modal-footer">
              {loading ? (
                <button type="button" className="btn btn-primary">
                  Please wait...
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={_save}
                >
                  Save changes
                </button>
              )}
              <button type="button" className="btn btn-danger" onClick={_close}>
                Close
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <div>
        {!modules && (
          <div
            style={{
              position: "fixed",
              right: "50px",
              bottom: "50px",
            }}
          >
            <button
              className="btn btn-icon btn-primary btn-rounded"
              onClick={_show}
            >
              <i className="ti-plus"></i>
            </button>
          </div>
        )}
      </div>
      <div>
        {modules && (
          <button
            className="btn btn-icon btn-primary btn-rounded"
            onClick={_show}
          >
            <i className="ti-pencil"></i>
          </button>
        )}
      </div>
    </div>
  );
};

export default BtnTuitionFee;
