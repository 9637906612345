import React from "react";
import TblChangeStudentStatus from "../../tables/TblCreditWallet";
import swal from "../../helpers/swal";
import is_null from "../../helpers/is_null";
import { axiosget } from "../../helpers/httprequest";

const ChangeStudentStatus = () => {
  const [studentid, setstudentid] = React.useState(null);
  const [loading, setloading] = React.useState(false);
  const [bio, setbio] = React.useState(undefined);
  const _fetch = async () => {
    try {
      setloading(true);
      if (is_null(studentid)) {
        throw new Error("Please enter Student ID");
      }
      const req = await axiosget(`ms_students/student/${studentid}`);
      if (!is_null(req.data?.type)) {
        throw new Error(req.data?.message);
      }
      const { bio: bios } = req.data;
      setloading(false);
      setbio(bios);
    } catch ({ message }) {
      swal("error", message, setloading, false);
    }
  };
  return (
    <>
      <div className="row">
        <div className="col-md-8">
          <div className="form-group">
            <label htmlFor="studentid">
              Student ID <b className="text-danger">*</b>
            </label>
            <input
              className="form-control form-control-lg"
              id="studentid"
              required
              placeholder="Student ID*"
              onChange={(e) => setstudentid(e.target.value)}
            />
          </div>
        </div>
        <div className="col-md-4 pt-4">
          {loading ? (
            <button type="button" className="btn btn-primary btn-block btn-lg">
              Please wait...
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-primary btn-block btn-lg"
              onClick={_fetch}
            >
              Fetch Student
            </button>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 table-responsive">
          {bio && <TblChangeStudentStatus bio={bio} />}
        </div>
      </div>
    </>
  );
};

export default ChangeStudentStatus;
