import React from "react";
import Loader from "../../components/Loader";
import { axiosget } from "../../helpers/httprequest";
import swal from "../../helpers/swal";
import moment from "../../helpers/moment";
import BtnModulesPrerequisite from "../../Buttons/BtnModulesPrerequisite";
import BtnModules from "../../Buttons/BtnModules";
import DelModules from "../../Buttons/DelModules";
import is_null from "../../helpers/is_null";

const TblModules = ({ deptlist }) => {
  const [loading, setloading] = React.useState(true);
  const [data, setdata] = React.useState(null);
  React.useEffect(() => {
    (async () => {
      try {
        if (data === null) {
          const req = await axiosget("ms_course/active");
          setdata(req.data);
          setloading(false);
        }
      } catch ({ message }) {
        swal("error", message, setloading);
      }
    })();
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <table id="example" className="table table-hover table-striped">
          <thead>
            <tr>
              <th>#</th>
              <th>Code</th>
              <th>Name</th>
              <th>Programme</th>
              <th>Level</th>
              <th>Semester</th>
              <th>Credit</th>
              <th>College Wide</th>
              <th>Is Prereq?</th>
              <th>Status</th>
              <th>Prerequisite</th>
              <th>Edit</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((v, i) => (
              <tr key={i}>
                <th>{i + 1}</th>
                <td>{String(v.code).toLocaleUpperCase()}</td>
                <td>{v.name}</td>
                <td>{v.progname}</td>
                <td>{v.level}</td>
                <td>{v.semester}</td>
                <td>{v.credit}</td>
                <td>{v.general ? "Yes" : "No"}</td>
                <td>{!is_null(v.prerequisite) ? "Yes" : "No"}</td>
                <td>{v.status ? "Active" : "In active"}</td>
                <td>
                  <BtnModulesPrerequisite modules={data} current={v} key={i} />
                </td>
                <td>
                  <BtnModules deptlist={deptlist} modules={v} key={i} />
                </td>
                <td>
                  <DelModules modules={v} key={i} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </>
  );
};

export default TblModules;
