import React from "react";
import { axiosget } from "../../helpers/httprequest";
import Alerts from "../../helpers/Alerts";
import Loader from "../../components/Loader";
import TblCourseAllocation from "../../tables/TblCourseAllocation";
import BtnCourseAllocation from "../../Buttons/BtnCourseAllocation";

const CourseAllocation = () => {
  const [perm, setperm] = React.useState(undefined);

  const [data, setdata] = React.useState(undefined);
  const [loading, setloading] = React.useState(undefined);
  React.useEffect(() => {
    (async () => {
      try {
        setloading(true);
        const req = await axiosget("ms_department/ishod");
        if (req.data?.type) {
          throw new Error(req.data?.message);
        }
        const { status } = req?.data;
        setperm(status);
        const reqd = await axiosget("ms_course_allocation");
        setdata(reqd.data);
        setloading(false);
      } catch ({ message }) {
        console.warn(message);
      }
      setloading(false);
    })();
  }, []);
  return (
    <>
      <>
        {loading ? (
          <Loader />
        ) : (
          <>
            {perm === false ? (
              <Alerts
                message={`Even though you have permission to perform this
                            task, it is reserved for Heads of Department only.
                            You have not been assigned any department yet`}
                type={`info`}
                title={`HOD ONLY`}
              />
            ) : (
              <TblCourseAllocation data={data} />
            )}
          </>
        )}
      </>

      <BtnCourseAllocation />
    </>
  );
};

export default CourseAllocation;
