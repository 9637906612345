import React, { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import Loader from "../components/Loader";
import { axiosget } from "./httprequest";
import is_null from "./is_null";
import { contextapi } from "./contextapi";
import ErrorBoundary from "../components/ErrorBoundary";

const ProtectedRoute = ({ children }) => {
  const [loading, setloading] = useState(true);
  const [ispermitted, setispermitted] = useState(false);
  const location = useLocation();
  const isauth = localStorage.getItem("isAuth");
  const context = React.useContext(contextapi);
  useEffect(() => {
    (async () => {
      let login = false;

      if (is_null(isauth)) {
        setispermitted(login);
        setloading(false);
        return;
      }

      const { user } = context.values;
      if (is_null(user) && !is_null(localStorage.getItem("isUser"))) {
        context.setvalues({
          ...context.values,
          user: JSON.parse(localStorage.getItem("isUser")),
          classroomCourse: localStorage.getItem("classroomCourse"),
        });
      }

      if (location.pathname !== "/dashboard") {
        const req = await axiosget(
          `ms_user_permissions/permission/${btoa(location.pathname)}`
        );
        if (req.data) {
          if (req.data.permit) {
            login = true;
          }
        }
      } else {
        if (!is_null(isauth)) {
          const req = await axiosget(`ms_user_permissions/permit`);
          if (req.data) {
            if (req.data.permit) {
              login = true;
            }
          }
          login = true;
        }
      }
      setispermitted(login);
      setloading(false);
    })();
  }, []);
  return (
    <>
      {!loading ? (
        <>
          {ispermitted ? (
            <ErrorBoundary fallback={<p>Something went wrong</p>}>
              {children}
            </ErrorBoundary>
          ) : (
            <Navigate to="/" />
          )}
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default ProtectedRoute;
