import React from "react";
import moment from "../../helpers/moment";
import BtnModuleRegistration from "../../Buttons/BtnModuleRegistration";
import is_student_status from "../../helpers/is_student_status";

const TblModuleRegistration = ({ bio }) => {
  return (
    <table
      id="example"
      className="table table-hover table-striped display expandable-table w-100"
    >
      <thead>
        <tr>
          <th>#</th>
          <th>Photo</th>
          <th>Student Id</th>
          <th>Name</th>
          <th>Status</th>
          <th>Level</th>
          <th>Semester</th>
          <th>Result</th>
          <th>Created At</th>
          <th>Updated At</th>
        </tr>
      </thead>
      <tbody>
        {bio?.map((v, i) => (
          <tr key={i}>
            <th>{i + 1}</th>
            <td>
              <img src={v?.photo?.fulllink} alt="Student " />
            </td>
            <td>{v.studentid}</td>
            <td>
              {v.firstname} {v.middlename} {v.lastname}
            </td>
            <td>{v.level}</td>
            <td>{v.semester}</td>
            <td>{is_student_status(v.status)}</td>
            <td>
              <BtnModuleRegistration
                modules={v}
                key={i}
                uri={"print-result-statement"}
              />
            </td>
            <td>{moment(v.createdAt)}</td>
            <td>{moment(v.updatedAt)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default TblModuleRegistration;
