import React from "react";
import Loader from "../../components/Loader";
import { axiosget } from "../../helpers/httprequest";
import swal from "../../helpers/swal";
import DelDepartmentHead from "../../Buttons/DelDepartmentHead";

const TblDepartmentHead = () => {
  const [loading, setloading] = React.useState(true);
  const [data, setdata] = React.useState(null);
  React.useEffect(() => {
    (async () => {
      try {
        const req = await axiosget("ms_department/hod");
        setdata(req.data);
        setloading(false);
      } catch ({ message }) {
        swal("error", message, setloading);
      }
    })();
  }, []);
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <table id="example" className="table table-hover table-striped">
          <thead>
            <tr>
              <th>#</th>
              <th>Code</th>
              <th>Name</th>
              <th>Head of Department</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((v, i) => (
              <tr key={i}>
                <th>{i + 1}</th>
                <td>{String(v.code).toLocaleUpperCase()}</td>
                <td>{v.name}</td>
                <td>
                  {v.firstname} {v.middlename} {v.lastname}
                </td>

                <td>
                  <DelDepartmentHead modules={v} key={i} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </>
  );
};

export default TblDepartmentHead;
