import React from "react";
import { axiosdelete, axiosget, axiospost } from "../../helpers/httprequest";
import is_null from "../../helpers/is_null";
import swal from "../../helpers/swal";

const MarkClassAttendance = ({ contact, student }) => {
  const [data, setdata] = React.useState(false);
  const [loading, setloading] = React.useState(true);
  const check = React.useRef();
  const _store = async () => {
    try {
      setloading(true);
      if (is_null(contact) && is_null(student)) {
        swal(
          "warning",
          "Sorry the attendance could not be submitted, please try again later",
          setloading,
          false
        );
        return;
      }

      if (check.current.checked) {
        const req = await axiospost("ms_course_attendance", {
          student,
          contact,
        });
        if (req.status !== 200) {
          throw new Error("Sorry something went wrong, Please try again later");
        }
        const { type, message } = req.data;
        if (type === "success") {
          setdata(true);
          setloading(false);
          return;
        }
        swal(type, message, setloading, false);
      } else {
        const req = await axiosdelete(
          `ms_course_attendance/${contact}/${student}`
        );
        if (req.status !== 200) {
          throw new Error("Sorry something went wrong, Please try again later");
        }
        const { type, message } = req.data;
        if (type === "success") {
          setdata(false);
          setloading(false);
          return;
        }
        swal(type, message, setloading, false);
      }
    } catch ({ message }) {
      swal("error", message, setloading, false);
    }
  };
  React.useEffect(() => {
    (async () => {
      try {
        const req = await axiosget(
          `ms_course_attendance/my/${contact}/${student}`
        );
        if (req.data?.type) {
          throw new Error(req.data?.message);
        }
        setdata(req.data?.attended);
      } catch ({ message }) {
        console.warn(message);
      }
      setloading(false);
    })();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="form-check form-check-primary">
      {loading ? (
        <span>Loading</span>
      ) : (
        <label className="form-check-label">
          <input
            type="checkbox"
            className="form-check-input"
            ref={check}
            onChange={_store}
            checked={data}
          />
          Present
          <i className="input-helper"></i>
        </label>
      )}
    </div>
  );
};

export default MarkClassAttendance;
