import React from "react";
import Loader from "../../components/Loader";
import moment from "../../helpers/moment";
import BtnEditApplicationFee from "../../Buttons/BtnEditApplicationFee";
import DelApplicationFee from "../../Buttons/DelApplicationFee";
import is_money from "../../helpers/is_money";
import is_null from "../../helpers/is_null";

const TblApplicationFee = ({ dataset, tableloading }) => {
  let nt = undefined;
  let data = undefined;

  if (!is_null(dataset)) {
    data = dataset?.data;
    nt = data?.map((v) => v.amount).reduce((a, b) => a + b, 0);
  }
  return (
    <>
      {tableloading ? (
        <Loader />
      ) : (
        <table className="table table-hover table-striped">
          <thead>
            <tr>
              <th>#</th>
              <th>Programme</th>
              <th>Name</th>
              <th>Status</th>
              <th>Amount</th>
              <th>Edit</th>
              <th>Delete</th>
              <th>Created At</th>
              <th>Updated At</th>
            </tr>
          </thead>
          <tbody>
            {dataset &&
              data?.map((v, i) => {
                return (
                  <tr key={i}>
                    <th>{i + 1}</th>
                    <td>{v.progname}</td>
                    <td>{v.name}</td>
                    <td>{v.status ? "Active" : "In active"}</td>
                    <td>{is_money(v.amount)}</td>
                    <td>
                      <BtnEditApplicationFee modules={v} key={i} />
                    </td>
                    <td>
                      <DelApplicationFee modules={v} key={i} />
                    </td>
                    <td>{moment(v.createdAt)}</td>
                    <td>{moment(v.updatedAt)}</td>
                  </tr>
                );
              })}
          </tbody>
          <tfoot>
            <tr>
              <th></th>
              <th></th>
              <th></th>
              <th>Total</th>
              <th>{nt && is_money(nt)}</th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </tfoot>
        </table>
      )}
    </>
  );
};

export default TblApplicationFee;
