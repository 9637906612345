import React from "react";
import ReactModal from "react-modal";
import swal from "../../helpers/swal";
import { axiosget, axiospost } from "../../helpers/httprequest";
import is_null from "../../helpers/is_null";
import SelectStatus from "../../Select/SelectStatus";
import Alerts from "../../helpers/Alerts";

const AcademicProgression = () => {
  const [showmodal, setshowmodal] = React.useState(false);
  const [to, setto] = React.useState(undefined);
  const [loading, setloading] = React.useState();
  const [from, setfrom] = React.useState(undefined);
  const [session, setsession] = React.useState();
  React.useEffect(() => {
    (async () => {
      try {
        const req = await axiosget("ms_session");
        if (req.data?.type) {
          throw new Error(req.data?.message);
        }
        const trimester = req.data?.map((v) => ({
          name: `${v.code} ${v.name} ${v.status ? "(Active)" : ""}`,
          value: v._id,
        }));
        setsession(trimester);
      } catch ({ message }) {
        console.warn(message);
      }
      setloading(false);
    })();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const _publish = async () => {
    try {
      setloading(true);
      if (is_null(from) || is_null(to)) {
        swal("error", "Please fill the form correctly", setloading, false);
        return;
      }
      const req = await axiospost("ms_student_progression/general", {
        from,
        to,
      });
      if (req.status !== 200) {
        throw new Error("Sorry something went wrong, please try again later");
      }
      const { type, message } = req.data;
      swal(type, message, setloading, false);
    } catch ({ message }) {
      swal("error", message, setloading, false);
    }
  };
  const _close = () => {
    setshowmodal(false);
  };
  const _show = () => {
    setshowmodal(true);
  };

  return (
    <>
      <ReactModal
        isOpen={showmodal}
        className="ReactModal"
        onRequestClose={_close}
      >
        <div className="modal-dialog " role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-center">
                SELECT SCHOOL SEMESTERS
              </h5>
            </div>
            <div className="modal-body">
              <SelectStatus
                setpagegroup={setfrom}
                options={session}
                label="Select From Trimester"
              />
              <SelectStatus
                setpagegroup={setto}
                options={session}
                label="Select To Trimester"
              />
            </div>
            <div className="modal-footer">
              {loading ? (
                <button type="button" className="btn btn-primary">
                  Please wait...
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={_publish}
                >
                  Progress Student
                </button>
              )}
              <button type="button" className="btn btn-danger" onClick={_close}>
                Close
              </button>
            </div>
          </div>
        </div>
      </ReactModal>
      <Alerts
        type="info"
        title="IMPORTANT NOTIFICATION"
        message="Please note that this should be done at the end of a semester when student result has been published"
      />
      <button className="btn my-4 btn-primary btn-block btn-lg" onClick={_show}>
        Start Progress Students
      </button>
    </>
  );
};

export default AcademicProgression;
