import React from "react";
import TblCollege from "../../tables/TblCollege";
import BtnCollege from "../../Buttons/BtnCollege";

const College = () => {
  return (
    <>
      <h4>Manage Academic School/Faculty</h4>

      <TblCollege />
      <BtnCollege />
    </>
  );
};

export default College;
