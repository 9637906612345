import React from "react";
import Loader from "../../components/Loader";
import { axiosget } from "../../helpers/httprequest";
import swal from "../../helpers/swal";
import moment from "../../helpers/moment";
import BtnRegistrationSettings from "../../Buttons/BtnRegistrationSettings";

const TblRegistrationSettings = () => {
  const [loading, setloading] = React.useState(true);
  const [data, setdata] = React.useState(null);
  React.useEffect(() => {
    (async () => {
      try {
        const req = await axiosget("ms_session");
        setdata(req.data);
        setloading(false);
      } catch ({ message }) {
        swal("error", message, setloading);
      }
    })();
  }, []);
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <table
          id="example"
          className="table table-hover table-striped display expandable-table w-100"
        >
          <thead>
            <tr>
              <th>#</th>
              <th>Code</th>
              <th>Name</th>
              <th>Status</th>
              <th>Add Settings</th>
              <th>Created At</th>
              <th>Updated At</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((v, i) => (
              <tr key={i}>
                <th>{i + 1}</th>
                <td>{String(v.code).toLocaleUpperCase()}</td>
                <td>{v.name}</td>
                <td>{v.status ? "Active" : "In active"}</td>
                <td>
                  <BtnRegistrationSettings modules={v} key={i} />
                </td>
                <td>{moment(v.createdAt)}</td>
                <td>{moment(v.updatedAt)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </>
  );
};

export default TblRegistrationSettings;
