import React from "react";
import BtnClassProfile from "../../Buttons/BtnClassProfile";
import Loader from "../../components/Loader";
import { axiosget } from "../../helpers/httprequest";
import swal from "../../helpers/swal";

const TblMyModules = () => {
  const [loading, setloading] = React.useState(true);
  const [data, setdata] = React.useState(null);

  React.useEffect(() => {
    (async () => {
      try {
        const req = await axiosget("ms_course_allocation/my");
        setdata(req.data);
        setloading(false);
      } catch ({ message }) {
        swal("error", message, setloading);
      }
    })();
  }, []);
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <table
          id="example"
          className="table table-hover table-striped display expandable-table w-100"
        >
          <thead>
            <tr>
              <th>#</th>
              <th>Code</th>
              <th>Name</th>
              <th>Level</th>
              <th>Semester</th>
              <th>Type</th>
              <th>Credit</th>
              <th>Go to Module</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((v, i) => (
              <tr key={i}>
                <th>{i + 1}</th>
                <td>{String(v.coursecode).toLocaleUpperCase()}</td>
                <td>{v.coursename}</td>
                <td>{v.level}</td>
                <td>{v.semester}</td>
                <td>{v.type}</td>
                <td>{v.credit}</td>
                <td>
                  <BtnClassProfile id={v.course_id} key={v._id} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </>
  );
};

export default TblMyModules;
