import $ from "jquery";

export default () => {
  $.fn.dataTable.ext.errMode = "none";
  if (!$.fn.dataTable.isDataTable("#example")) {
    $(document).ready(function () {
      setTimeout(function () {
        $("#example").DataTable({
          pagingType: "full_numbers",
          processing: true,
          pageLength: 6,
          bfilter: true,
          dom: "Bfrtip",
          select: {
            style: "single",
          },
          buttons: [
            {
              extend: "copy",
              className: "btn btn-primary",
            },
            {
              extend: "csv",
              className: "btn btn-primary",
            },
            {
              extend: "print",
              className: "btn btn-primary",
            },
          ],

          columnDefs: [
            {
              targets: 0,
              render: function (data, type, row, meta) {
                return type === "export" ? meta.row + 1 : data;
              },
            },
          ],
        });
      }, 1000);
    });
  }
};
