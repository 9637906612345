import React from "react";
import Loader from "../../components/Loader";
import { axiosget } from "../../helpers/httprequest";
import swal from "../../helpers/swal";
import moment from "../../helpers/moment";
import BtnChangeOfProgramme from "../../Buttons/BtnChangeOfProgramme";

const TblChangeOfProgramme = () => {
  const [loading, setloading] = React.useState(true);
  const [data, setdata] = React.useState(null);
  React.useEffect(() => {
    (async () => {
      try {
        const req = await axiosget("ms_course_change/hod");
        setdata(req.data);
        setloading(false);
      } catch ({ message }) {
        swal("error", message, setloading);
      }
    })();
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <table
          id="example"
          className="table table-hover table-striped display expandable-table w-100"
        >
          <thead>
            <tr>
              <th>#</th>
              <th>Student ID</th>
              <th>Names</th>
              <th>From</th>
              <th>To</th>
              <th>Level</th>
              <th>Semester</th>
              <th>View</th>
              <th>Created At</th>
              <th>Updated At</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((v, i) => (
              <tr key={i}>
                <th>{i + 1}</th>
                <td>{String(v.stdinfo.studentid).toLocaleUpperCase()}</td>
                <td>
                  {v.stdinfo.firstname} {v.stdinfo.middlename}{" "}
                  {v.stdinfo.lastname}
                </td>
                <td>{v.frominfo.name}</td>
                <td>{v.toinfo.name}</td>
                <td>{v.fromlevel}</td>
                <td>{v.fromsemester}</td>
                <td>
                  <BtnChangeOfProgramme modules={v} key={i} />
                </td>
                <td>{moment(v.createdAt)}</td>
                <td>{moment(v.updatedAt)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </>
  );
};

export default TblChangeOfProgramme;
