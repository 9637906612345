import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "../components/Navbar";
import { contextapi } from "./contextapi";
// import MainNavBar from "../components/MainNavBar";
// import SubMainMenu from "../components/SubMainMenu";
import Sidebar from "../components/Sidebar";
import is_null from "./is_null";
import { axiosget } from "./httprequest";

const AdminLayout = () => {
  const context = React.useContext(contextapi);
  React.useEffect(() => {
    (async () => {
      const { main_menu } = context.values;
      if (is_null(main_menu)) {
        const req = await axiosget(`ms_user_permissions/my`);
        if (req.data) {
          const { result } = req.data;
          if (!is_null(result)) {
            context.setvalues({ ...context.values, main_menu: result });
          }
        }
      }
    })();
  }, []);

  return (
    <div className="container-scroller">
      <Navbar />
      <div className="container-fluid page-body-wrapper">
        {/* <Sidebar /> */}
        <div className="main-panel">
          <div className="content-wrapper">
            <div className="row">
              <div className="col-md-12 grid-margin stretch-card">
                <div className="card" style={{ borderRadius: "0px" }}>
                  {/* <MainNavBar />
                  <SubMainMenu /> */}

                  <div className="card-body">
                    <div className="row">
                      <div
                        className="col-12 text-uppercase my-3"
                        style={{ fontSize: 24, fontWeight: "bolder" }}
                      >
                        {context.values?.selectedsubmenutitle}
                      </div>
                      <div className="col-12">
                        <Outlet />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer className="footer">
        <div className="d-sm-flex justify-content-center justify-content-sm-between">
          <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">
            Copyright © 2021.
            <a href="https://www.samtec.ng/" target="_blank">
              Samtec & Associate Nigeria Limited
            </a>{" "}
          </span>
          <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">
            <i className="ti-heart text-danger ml-1"></i>
          </span>
        </div>
        {/* <div className="d-sm-flex justify-content-center justify-content-sm-between">
          <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">
            Owned
            <a href="https://www.themewagon.com/" target="_blank">
              Themewagon
            </a>
          </span>
        </div> */}
      </footer>
    </div>
  );
};

export default AdminLayout;
