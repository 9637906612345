import React from "react";
import swal from "../../helpers/swal";
import { axiospost } from "../../helpers/httprequest";

const RegisterModule = ({ modules, student }) => {
  const ref = React.useRef();
  const [loading, setloading] = React.useState(false);
  const [isregistered, setisregistered] = React.useState(modules?.isregistered);
  const _selected = async () => {
    try {
      setloading(true);
      if (student.status !== 1) {
        throw new Error(
          `Sorry the student <b>${student.studentid} | ${student.firstname} ${student.middlename} ${student.lastname}</b> is not active`
        );
      }
      if (ref.current.checked) {
        const req = await axiospost(`ms_course_registration/add`, {
          modules,
          student,
        });
        if (req.status !== 200) {
          throw new Error("Sorry, something wrong happpened, Please try again");
        }
        const { type, message } = req.data;
        if (type === "success") {
          swal(type, message, setloading, false);
          setisregistered(true);
          return;
        }
        throw new Error(message);
      } else {
        const req = await axiospost(`ms_course_registration/drop`, {
          modules,
          student,
        });
        if (req.status !== 200) {
          throw new Error("Sorry, something wrong happpened, Please try again");
        }
        const { type, message } = req.data;
        if (type === "success") {
          setisregistered(false);
          swal(type, message, setloading, false);
          return;
        }
        throw new Error(message);
      }
    } catch ({ message }) {
      swal("error", message, setloading, false);
    }
  };
  return (
    <div className="form-check text-center form-check-flat form-check-primary">
      {loading ? (
        <>...</>
      ) : (
        <label className="form-check-label">
          <input
            type="checkbox"
            className="form-check-input"
            ref={ref}
            checked={isregistered}
            onChange={_selected}
          />
          <i className="input-helper"></i>
        </label>
      )}
    </div>
  );
};

export default RegisterModule;
