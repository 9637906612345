const is_grade = (score) => {
  let grade;
  if (score >= 70) {
    grade = "A";
  } else if (score < 70 && score >= 60) {
    grade = "B";
  } else if (score < 60 && score >= 50) {
    grade = "C";
  } else if (score < 50 && score >= 45) {
    grade = "D";
  } else if (score < 45 && score >= 40) {
    grade = "E";
  } else {
    grade = "F";
  }

  return grade;
};

export default is_grade;
