import React from "react";
import { axiospost, axiosput } from "../../helpers/httprequest";
import is_null from "../../helpers/is_null";
import swal from "../../helpers/swal";
import Modal from "react-modal";
import SelectStatus from "../../Select/SelectStatus";

const BtnModules = ({ modules, deptlist }) => {
  const [showmodal, setshowmodal] = React.useState(false);
  const [code, setcode] = React.useState(modules ? modules.code : "");
  const [name, setname] = React.useState(modules ? modules.name : "");
  const [status, setstatus] = React.useState("");
  const [loading, setloading] = React.useState(false);
  const [programme, setprogramme] = React.useState(
    modules ? modules.programme : ""
  );

  const [credit, setcredit] = React.useState(modules ? modules.credit : "");
  const [level, setlevel] = React.useState(null);
  const [semester, setsemester] = React.useState(null);
  const [moduletype, setmoduletype] = React.useState("");
  const [general, setgeneral] = React.useState("");

  const _save = async () => {
    try {
      setloading(true);

      if (
        is_null(code) ||
        is_null(name) ||
        is_null(status) ||
        is_null(programme) ||
        is_null(credit) ||
        is_null(level) ||
        is_null(semester) ||
        is_null(general) ||
        is_null(moduletype)
      ) {
        swal("error", "Please fill the form properly", setloading);
        return;
      }
      let req;
      if (modules !== undefined) {
        const { _id } = modules;
        req = await axiosput(`ms_course/${_id}`, {
          code,
          name,
          status,
          general,
          programme,
          credit,
          level,
          semester,
          moduletype,
        });
      } else {
        req = await axiospost("ms_course", {
          code,
          name,
          status,
          general,
          programme,
          credit,
          level,
          semester,
          moduletype,
        });
      }

      if (req.status !== 200)
        throw new Error("Sorry something went wrong, please try again");
      const { type, message } = req.data;
      swal(type, message, setloading, true);
      if (type === "success") {
        setshowmodal(false);
      }
    } catch ({ message }) {
      swal("error", message, setloading);
    }
  };

  return (
    <div>
      <Modal
        isOpen={showmodal}
        onRequestClose={() => setshowmodal(false)}
        className="ReactModal"
      >
        <div className="modal-dialog " role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-center">
                {modules ? `Update ${modules.name}` : "Create New Module"}
              </h5>
              <span
                type="button"
                className="text-danger"
                onClick={() => setshowmodal(false)}
              >
                &times;
              </span>
            </div>
            <div className="modal-body">
              {deptlist && (
                <SelectStatus
                  setpagegroup={setprogramme}
                  options={deptlist}
                  label="Select Module's Programme"
                  active={modules?.programme}
                />
              )}
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail2">Module Code</label>
                    <input
                      type="email"
                      autoFocus
                      className="form-control form-control-lg"
                      id="exampleInputEmail2"
                      onChange={(e) => setcode(e.target.value)}
                      placeholder="Programme Code"
                      value={code}
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Module Credit</label>
                    <input
                      type="number"
                      onChange={(e) => setcredit(e.target.value)}
                      className="form-control form-control-lg"
                      id="exampleInputEmail1"
                      max={8}
                      placeholder="Module Credit"
                      value={Number(credit)}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Module Title</label>
                    <input
                      onChange={(e) => setname(e.target.value)}
                      className="form-control form-control-lg"
                      id="exampleInputEmail1"
                      placeholder="Module Title"
                      value={name}
                    />
                  </div>
                </div>
                <div className="col-6">
                  <SelectStatus
                    setpagegroup={setgeneral}
                    options={[
                      { name: "Yes", value: true },
                      { name: "No", value: false },
                    ]}
                    label="Is Module College Wide?"
                    active={modules?.general}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <SelectStatus
                    setpagegroup={setstatus}
                    options={[
                      { name: "Active", value: true },
                      { name: "Inactive", value: false },
                    ]}
                    label="Select Module Status"
                    active={modules?.status}
                  />
                </div>
                <div className="col-6">
                  <SelectStatus
                    setpagegroup={setmoduletype}
                    options={[
                      { name: "Core", value: "Core" },
                      { name: "Elective", value: "Elective" },
                    ]}
                    label="Select Module Type"
                    active={modules?.moduletype}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <SelectStatus
                    setpagegroup={setlevel}
                    options={[
                      { name: "100 Level", value: "100 Level" },
                      { name: "200 Level", value: "200 Level" },
                      { name: "300 Level", value: "300 Level" },
                      { name: "400 Level", value: "400 Level" },
                      { name: "500 Level", value: "500 Level" },
                    ]}
                    label="Select Module Level"
                    active={modules?.level}
                  />
                </div>
                <div className="col-6">
                  <SelectStatus
                    setpagegroup={setsemester}
                    options={[
                      { name: "First Semester", value: "First Semester" },
                      { name: "Second Semester", value: "Second Semester" },
                    ]}
                    label="Select Module Semester"
                    active={modules?.semester}
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              {loading ? (
                <button type="button" className="btn btn-primary">
                  Please wait...
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={_save}
                >
                  Save changes
                </button>
              )}
            </div>
          </div>
        </div>
      </Modal>

      <div>
        {!modules && (
          <div
            style={{
              position: "fixed",
              right: "50px",
              bottom: "50px",
            }}
          >
            <button
              className="btn btn-icon btn-primary btn-rounded"
              onClick={() => setshowmodal(true)}
            >
              <i className="ti-plus"></i>
            </button>
          </div>
        )}
      </div>
      <div>
        {modules && (
          <button
            className="btn btn-icon btn-primary btn-rounded"
            onClick={() => setshowmodal(true)}
          >
            <i className="ti-pencil"></i>
          </button>
        )}
      </div>
    </div>
  );
};

export default BtnModules;
