import React from "react";
import is_money from "../../helpers/is_money";
import moment from "../../helpers/moment";
import BtnTuitionFee from "../../Buttons/BtnTuitionFee";
import DelTuition from "../../Buttons/DelTuition";

const TblTuitionFee = ({ dataset, programme }) => {
  return (
    <table
      id="example"
      className="table table-hover table-striped display expandable-table w-100"
    >
      <thead>
        <tr>
          <th>#</th>
          <th>Name</th>
          <th>Amount</th>
          <th>Is Active</th>
          <th>Is New Student Fee?</th>
          <th>Edit</th>
          <th>Delete</th>
          <th>Created At</th>
          <th>Updated At</th>
        </tr>
      </thead>
      <tbody>
        {dataset?.map((v, i) => (
          <tr key={i}>
            <th>{i + 1}</th>
            <td>{v.label}</td>
            <td>{is_money(v.amount)}</td>
            <td>{v.status ? "Yes" : "No"}</td>
            <td>{v.isnewstudent ? "Yes" : "No"}</td>
            <td>
              <BtnTuitionFee modules={v} programme={programme} key={i} />
            </td>
            <td>
              <DelTuition modules={v} key={i} />
            </td>
            <td>{moment(v.createdAt)}</td>
            <td>{moment(v.updatedAt)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default TblTuitionFee;
