import React from "react";
import moment from "../../helpers/moment";
import BtnProgression from "../../Buttons/BtnProgression";
import is_student_status from "../../helpers/is_student_status";
import { contextapi } from "../../helpers/contextapi";
import { axiosget } from "../../helpers/httprequest";
import Loader from "../../components/Loader";

const TblStudentProgression = ({ bio }) => {
  const context = React.useContext(contextapi);
  const [loading, setloading] = React.useState(true);
  React.useEffect(() => {
    (async () => {
      try {
        const req = await axiosget("ms_session");
        if (req.data?.type) {
          throw new Error(req.data?.message);
        }
        const tri = req?.data?.map((v) => ({
          name: `${v.code} ${v.name}`,
          value: v._id,
        }));
        context.setvalues({ ...context.values, trimester: tri });
      } catch ({ message }) {
        console.warn(message);
      }
      setloading(false);
    })();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {loading ? (
        <Loader message="Please wait" />
      ) : (
        <table
          id="example"
          className="table table-hover table-striped display expandable-table w-100"
        >
          <thead>
            <tr>
              <th>#</th>
              <th>Photo</th>
              <th>Student Id</th>
              <th>Name</th>
              <th>Status</th>
              <th>Level</th>
              <th>Semester</th>
              <th>Progress</th>
              <th>Created At</th>
              <th>Updated At</th>
            </tr>
          </thead>
          <tbody>
            {bio?.map((v, i) => (
              <tr key={i}>
                <th>{i + 1}</th>
                <td>
                  <img src={v?.photo?.fulllink} alt="Student " />
                </td>
                <td>{v.studentid}</td>
                <td>
                  {v.firstname} {v.middlename} {v.lastname}
                </td>
                <td>{v.level}</td>
                <td>{v.semester}</td>
                <td>{is_student_status(v.status)}</td>
                <td>
                  <BtnProgression modules={v} key={i} />
                </td>
                <td>{moment(v.createdAt)}</td>
                <td>{moment(v.updatedAt)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </>
  );
};

export default TblStudentProgression;
