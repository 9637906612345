import React from "react";
import is_null from "../../helpers/is_null";
import { axiosget } from "../../helpers/httprequest";
import SelectStatus from "../../Select/SelectStatus";
import TblProgAdmissionRequirement from "../../tables/TblProgAdmissionRequirement";
import BtnRequirement from "../../Buttons/BtnRequirement";

const Requirement = () => {
  const [programme, setprogramme] = React.useState();
  const [selected, setselected] = React.useState();
  const [dataset, setdataset] = React.useState();
  const [tableloading, settableloading] = React.useState(false);
  React.useEffect(() => {
    (async () => {
      try {
        const req = await axiosget("ms_programme/active");
        if (is_null(req.data?.error) && req.data) {
          const { data } = req;
          const res = data?.map((v) => ({
            name: `${v.code} | ${v.name}`,
            value: v._id,
          }));
          setprogramme(res);
        }
      } catch ({ message }) {
        console.warn(message);
      }
    })();
  }, []);
  React.useEffect(() => {
    (async () => {
      if (!is_null(selected)) {
        settableloading(true);
        const req = await axiosget(
          `ms_programme_admission_requirement/${selected}`
        );
        if (is_null(req.data?.error) && req.data) {
          setdataset(req.data);
        }
        settableloading(false);
      }
    })();
  }, [selected]);

  return (
    <>
      <div className="row">
        <div className="col-12">
          <SelectStatus
            label={"Select Programme"}
            setpagegroup={setselected}
            options={programme}
          />
        </div>
        <div className="col-12">
          <TblProgAdmissionRequirement
            dataset={dataset}
            tableloading={tableloading}
          />
        </div>
      </div>
      <BtnRequirement dataset={programme} />
    </>
  );
};

export default Requirement;
