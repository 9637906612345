import React from "react";
import ReactModal from "react-modal";
import { axiosdelete } from "../../helpers/httprequest";
import is_null from "../../helpers/is_null";
import swal from "../../helpers/swal";

const DelResultModeration = ({ modules }) => {
  const [showmodal, setshowmodal] = React.useState(false);
  const [name, setname] = React.useState(null);
  const [loading, setloading] = React.useState(false);

  const _save = async () => {
    try {
      setloading(true);
      if (is_null(name) || name !== "Moderation") {
        swal("error", "Please fill the form properly", setloading);
        return;
      }
      const req = await axiosdelete(
        `ms_course_result_moderation/${modules._id}`
      );
      if (req.status !== 200)
        throw new Error("Sorry something went wrong, please try again");
      const { type, message } = req.data;
      if (type === "success") {
        swal(type, message, setloading, true);
        return;
      }
      swal(type, message, setloading, false);
    } catch ({ message }) {
      swal("error", message, setloading);
    }
  };
  const _close = () => {
    setshowmodal(false);
    setname("");
  };
  const _show = () => {
    setshowmodal(true);
    setname(modules !== undefined ? modules.name : null);
  };

  return (
    <div>
      <ReactModal
        isOpen={showmodal}
        className="ReactModal"
        onRequestClose={_close}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-center text-danger text-uppercase">
                Reverse {modules.name} moderation
              </h5>
              <span
                type="button"
                className="pointer text-danger"
                onClick={_close}
              >
                &times;
              </span>
            </div>
            <div className="modal-body">
              <div className="text-wrap my-4 text-md">
                To reverse moderation type the word{" "}
                <b className="text-danger">Moderation</b> in the text box,
                please note that when you reverse the trimester moderation below
                are list of what it might affect:
                <ol>
                  <li>All course registration</li>
                  <li>All results</li>
                </ol>
              </div>
              <div className="text-wrap my-4 text-md">
                Make sure you know this is what you want, Even the system
                developer cannot recover the data if moderated. kindly trade
                with caution!!!
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control form-control-lg"
                  id="exampleInputEmail2"
                  placeholder="Moderation"
                  onChange={(e) => setname(e.target.value)}
                />
              </div>
              <div className="modal-footer">
                {loading ? (
                  <button type="button" className="btn btn-primary">
                    Please wait...
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={_save}
                  >
                    Reverse Academic Result Moderation
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </ReactModal>
      <div>
        <button className="btn btn-icon btn-danger btn-rounded" onClick={_show}>
          <i className="ti-direction-alt"></i>
        </button>
      </div>
    </div>
  );
};

export default DelResultModeration;
