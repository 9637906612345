import React from "react";
import ClassroomNavbar from "../../components/ClassroomNavbar";
import "./index.css";
import { axiosget, axiospost } from "../../helpers/httprequest";
import swal from "../../helpers/swal";
import is_null from "../../helpers/is_null";
import { contextapi } from "../../helpers/contextapi";
import Loader from "../../components/Loader";
import moment from "../../helpers/moment";

const ClassDashboard = () => {
  const [loading, setloading] = React.useState(true);
  const [data, setdata] = React.useState(undefined);
  const [post, setpost] = React.useState(undefined);
  const context = React.useContext(contextapi);
  const { classroomCourse } = context.values;
  React.useEffect(() => {
    (async () => {
      try {
        const req = await axiosget(`ms_notification/${classroomCourse}`);
        if (req.data?.type) {
          throw new Error(req.data?.message);
        }
        setdata(req.data);
      } catch ({ message }) {
        console.warn(message);
      }
      setloading(false);
    })();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const _store = async () => {
    try {
      if (is_null(post)) {
        swal("warning", "You cannot post an empty field", setloading, false);
        return;
      }

      const req = await axiospost("ms_notification", {
        course: classroomCourse,
        post,
      });
      if (req.status !== 200) {
        throw new Error("Sorry, Something went wrong, please try again later");
      }
      const { message, type } = req.data;
      if (type === "success") {
        setpost("");
        const { post: pos } = req.data;
        swal(type, message, setloading, undefined);
        if (data === undefined) {
          setdata([pos]);
          return;
        } else {
          setdata([pos, ...data]);
        }
      }
      swal(type, message, setloading, undefined);
    } catch ({ message }) {
      swal("error", message, setloading, undefined);
    }
  };

  return (
    <div>
      <ClassroomNavbar />
      <div className="main-panel classroom-content w-100">
        <div className="container">
          <div className="row">
            <div className="col-md-12 pt-3 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <div className="classroomCourseDetails">
                    <h1 className="p1">CHEW2012</h1>
                    <h2>Introduction to community health</h2>
                    <h2>23A - 2023 First Semester</h2>
                  </div>

                  <div className="form-group mt-5 mb-5">
                    <div className="input-group mt-3">
                      <textarea
                        type="text"
                        className="form-control rounded"
                        placeholder="Share a post with class"
                        aria-label="Share a post with class"
                        onChange={(e) => setpost(e.target.value)}
                        value={post}
                      />{" "}
                      &nbsp;
                      <button
                        onClick={_store}
                        className="btn btn-sm btn-primary"
                        type="button"
                      >
                        Share Post
                      </button>
                    </div>
                  </div>
                  {loading ? (
                    <Loader message="Please wait system loading" />
                  ) : (
                    <div className="col-md-12 stretch-card grid-margin">
                      <ul className="icon-data-list w-100">
                        {data?.map((v, i) => (
                          <li key={i}>
                            <div className="d-flex">
                              <img src={v.staff.photo} alt="user" />
                              <div>
                                <p className="text-info mb-1">
                                  {v.staff.firstname} {v.staff.middlename}{" "}
                                  {v.staff.lastname}
                                </p>
                                <p className="mb-0">{v.post}</p>
                                <small>{moment(v.createdAt)}</small>
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClassDashboard;
