import React from "react";
import TblSystemModules from "../../tables/TblSystemModules";
import BtnSystemModules from "../../Buttons/BtnSystemModules";

const SystemModules = () => {
  return (
    <>
      <TblSystemModules />
      <BtnSystemModules />
    </>
  );
};

export default SystemModules;
