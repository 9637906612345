import React from "react";
import { contextapi } from "../../helpers/contextapi";
import is_null from "../../helpers/is_null";

const PickChangeOfCourseResultItem = ({ item }) => {
  const ref = React.useRef();
  const context = React.useContext(contextapi);
  const { changeofCourseResult } = context.values;

  const change = () => {
    if (ref.current.checked) {
      if (is_null(changeofCourseResult)) {
        context.setvalues({
          ...context.values,
          changeofCourseResult: [item._id],
        });
      } else {
        const rec = [...changeofCourseResult, item._id];
        context.setvalues({
          ...context.values,
          changeofCourseResult: rec,
        });
      }
    } else {
      const splice = changeofCourseResult?.filter((i) => i !== item._id);
      context.setvalues({
        ...context.values,
        changeofCourseResult: splice,
      });
    }
  };
  return (
    <div className="form-group">
      <div className="form-check">
        <label className="form-check-label">
          <input
            className="form-check-input"
            type="checkbox"
            onChange={change}
            ref={ref}
          />
          <i className="input-helper"></i>
        </label>
      </div>
    </div>
  );
};

export default PickChangeOfCourseResultItem;
