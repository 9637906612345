import React from "react";
import "./index.css";
import { appHost } from "../../helpers/uri";

const PrintHeader = () => {
  return (
    <div className="printHeaderContainer">
      <img
        src={`${appHost}/images/logo.jpg`}
        className="printHeaderLogo"
        alt="logo"
      />
      <h1>ADAMU TAFAWA BALEWA COLLEGE OF EDUCATION, KANGERE</h1>
      <h4>Main Campus Location Along Bauchi-Gombe Road, Bauchi State</h4>
      <h4>
        info@coekangere.edu.ng | finance@coekangere.edu.ng |
        support@coekangere.edu.ng
      </h4>
      <h4>0805-012-1234</h4>
    </div>
  );
};

export default PrintHeader;
