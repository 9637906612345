import React from "react";
import ReactModal from "react-modal";
import StudentCurrentCarryover from "../../components/StudentCurrentCarryover";
import StudentProgressionHistory from "../../components/StudentProgressionHistory";
import { contextapi } from "../../helpers/contextapi";
import { axiospost } from "../../helpers/httprequest";
import is_null from "../../helpers/is_null";
import swal from "../../helpers/swal";
import SelectStatus from "../../Select/SelectStatus";

const BtnProgression = ({ modules }) => {
  const [showmodal, setshowmodal] = React.useState(false);
  const [tosemester, settosemester] = React.useState(
    modules ? modules.code : ""
  );
  const [tolevel, settolevel] = React.useState(undefined);
  const [tosession, settosession] = React.useState(undefined);
  const [fromsession, setfromsession] = React.useState(undefined);
  const [loading, setloading] = React.useState(false);
  const context = React.useContext(contextapi);
  const _save = async () => {
    try {
      setloading(true);
      if (
        is_null(tolevel) ||
        is_null(tosemester) ||
        is_null(tosession) ||
        is_null(fromsession)
      ) {
        swal("error", "Please fill the form properly", setloading);
        return;
      }
      const req = await axiospost("ms_student_progression/single", {
        student: modules._id,
        tolevel,
        fromlevel: modules.level,
        fromsemester: modules.semester,
        tosemester,
        tosession,
        fromsession,
      });

      if (req.status !== 200)
        throw new Error("Sorry something went wrong, please try again");
      const { type, message } = req.data;
      if (type === "success") {
        swal(type, message, setloading, true);
        return;
      }
      swal(type, message, setloading, false);
    } catch ({ message }) {
      swal("error", message, setloading);
    }
  };

  const _close = () => {
    setshowmodal(false);
  };
  const _show = () => {
    setshowmodal(true);
  };

  const { trimester } = context.values;

  return (
    <div>
      <ReactModal
        isOpen={showmodal}
        className="ReactModal"
        onRequestClose={_close}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-center text-uppercase">
                {modules.studentid} | {modules.firstname} {modules.middlename}{" "}
                {modules.lastname} Manual Progression
              </h5>
            </div>
            <div className="modal-body">
              <StudentCurrentCarryover id={modules._id} />
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">From Level</label>
                    <input
                      type="email"
                      autoFocus
                      readOnly
                      className="form-control form-control-lg"
                      id="exampleInputEmail1"
                      placeholder="Trimester Name"
                      value={modules.level}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail2">From Semester</label>
                    <input
                      type="email"
                      className="form-control form-control-lg"
                      id="exampleInputEmail2"
                      readOnly
                      placeholder="Trimester Code"
                      value={modules.semester}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <SelectStatus
                    setpagegroup={settolevel}
                    options={[
                      { name: "100 Level", value: "100 Level" },
                      { name: "200 Level", value: "200 Level" },
                      { name: "300 Level", value: "300 Level" },
                      { name: "400 Level", value: "400 Level" },
                      { name: "500 Level", value: "500 Level" },
                    ]}
                    label="Select To Level"
                  />
                </div>
                <div className="col-md-6">
                  <SelectStatus
                    setpagegroup={settosemester}
                    options={[
                      { name: "First Semester", value: "First Semester" },
                      { name: "Second Semester", value: "Second Semester" },
                    ]}
                    label="Select To Semester"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <SelectStatus
                    setpagegroup={setfromsession}
                    options={trimester}
                    label="Select From Session"
                  />
                </div>
                <div className="col-md-6">
                  <SelectStatus
                    setpagegroup={settosession}
                    options={trimester}
                    label="Select To Session"
                  />
                </div>
              </div>
              <StudentProgressionHistory modules={modules} />
            </div>
            <div className="modal-footer">
              {loading ? (
                <button type="button" className="btn btn-primary">
                  Please wait...
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={_save}
                >
                  Save changes
                </button>
              )}
              <button type="button" className="btn btn-danger" onClick={_close}>
                Close
              </button>
            </div>
          </div>
        </div>
      </ReactModal>

      <div>
        {!modules && (
          <div
            style={{
              position: "absolute",
              right: "50px",
              bottom: "50px",
            }}
          >
            <button
              className="btn btn-icon btn-primary btn-rounded"
              onClick={_show}
            >
              <i className="ti-share"></i>
            </button>
          </div>
        )}
      </div>
      <div>
        {modules && (
          <button
            className="btn btn-icon btn-primary btn-rounded"
            onClick={_show}
          >
            <i className="ti-share"></i>
          </button>
        )}
      </div>
    </div>
  );
};

export default BtnProgression;
