import React from "react";
import { axiosput } from "../../helpers/httprequest";
import is_null from "../../helpers/is_null";
import swal from "../../helpers/swal";
import SelectStatus from "../../Select/SelectStatus";

const BtnEditApplicationFee = ({ modules }) => {
  const [showmodal, setshowmodal] = React.useState(false);
  const [amount, setamount] = React.useState(modules.amount);
  const [name, setname] = React.useState(modules.name);
  const [status, setstatus] = React.useState("");
  const [loading, setloading] = React.useState(false);
  const [required, setrequired] = React.useState(false);

  const _save = async () => {
    try {
      setloading(true);
      const { programme } = modules;
      if (
        is_null(programme) ||
        is_null(name) ||
        is_null(amount) ||
        is_null(status)
      ) {
        swal("error", "Please fill the form properly", setloading);
        return;
      }
      let req = await axiosput(
        `ms_programme_application_fee_items/${modules._id}`,
        {
          programme: programme,
          name: name,
          status,
          amount,
        }
      );

      if (req.status !== 200)
        throw new Error("Sorry something went wrong, please try again");
      const { type, message } = req.data;
      swal(type, message, setloading, true);
      if (type === "success") {
        _close();
      }
    } catch ({ message }) {
      swal("error", message, setloading);
    }
  };

  const _close = () => {
    setshowmodal("none");
  };
  const _show = () => {
    setshowmodal("block");
  };

  return (
    <div>
      <div
        className="modal fade show"
        id="defaultModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        style={{ display: `${showmodal}` }}
        aria-hidden="true"
        role="dialog"
      >
        <div className="modal-dialog " role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-center">Update {modules.name}</h5>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Pay Item Name</label>
                <input
                  type="email"
                  autoFocus
                  value={name}
                  onChange={(e) => setname(e.target.value)}
                  className="form-control form-control-lg"
                  id="exampleInputEmail1"
                  placeholder="Pay Item Name"
                />
              </div>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Pay Item Amount</label>
                <input
                  type="email"
                  autoFocus
                  value={amount}
                  onChange={(e) => setamount(e.target.value)}
                  className="form-control form-control-lg"
                  id="exampleInputEmail1"
                  placeholder="Pay Item Amount"
                />
              </div>
              <SelectStatus
                setpagegroup={setstatus}
                options={[
                  { name: "Active", value: true },
                  { name: "Inactive", value: false },
                ]}
                label="Select Status"
              />
            </div>
            <div className="modal-footer">
              {loading ? (
                <button type="button" className="btn btn-primary">
                  Please wait...
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={_save}
                >
                  Save changes
                </button>
              )}
              <button type="button" className="btn btn-danger" onClick={_close}>
                Close
              </button>
            </div>
          </div>
        </div>
      </div>

      <div>
        <button
          className="btn btn-icon btn-primary btn-rounded"
          onClick={_show}
        >
          <i className="ti-pencil"></i>
        </button>
      </div>
    </div>
  );
};

export default BtnEditApplicationFee;
