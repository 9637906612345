import React from "react";
import { axiosput } from "../../helpers/httprequest";
import swal from "../../helpers/swal";
import Swal from "sweetalert2";

const BtnApplicationProfile = ({ modules }) => {
  const [loading, setloading] = React.useState(false);
  const _approve_application = async () => {
    try {
      setloading(true);
      Swal.fire({
        title: "<strong>CONFIRM ACCOUNT </strong>",
        icon: "warning",
        html: `
          Are you sure you want to confirm this application's application
          <p>[WARNING, Use of this should higly be discouraged]</p>
        `,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: `
          <i class="fa fa-thumbs-up"></i> Confirm!
        `,
        confirmButtonAriaLabel: "Yes, Confirm!",
        cancelButtonText: `
          <i class="fa fa-thumbs-down"></i> Cancel
        `,
        cancelButtonAriaLabel: "Cancel",
      }).then(async (r) => {
        if (r.isConfirmed) {
          const { _id: id } = modules;

          let req = await axiosput(`ms_applicant/approve`, {
            id,
          });

          if (req.status !== 200)
            throw new Error("Sorry something went wrong, please try again");
          const { type, message } = req.data;

          if (type === "success") {
            swal(type, message, undefined, false);
          } else {
            swal(type, message, undefined, false);
          }
        }
        setloading(false);
      });
    } catch ({ message }) {
      swal("error", message, undefined, false);
    }
    setloading(false);
  };

  return (
    <button
      className="btn btn-icon btn-primary btn-rounded"
      onClick={_approve_application}
    >
      {loading ? (
        <i className="fa fa-refresh fa-spin" />
      ) : (
        <i className="fa fa-check" />
      )}
    </button>
  );
};

export default BtnApplicationProfile;
