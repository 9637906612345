import React from "react";
import ReactModal from "react-modal";
import { axiosget, axiospost } from "../../helpers/httprequest";
import is_null from "../../helpers/is_null";
import swal from "../../helpers/swal";
import SelectStatus from "../../Select/SelectStatus";
import Swal from "sweetalert2";

const BtnResultModeration = () => {
  const [showmodal, setshowmodal] = React.useState(false);
  const [from, setfrom] = React.useState(undefined);
  const [to, setto] = React.useState(undefined);
  const [trimester, settrimester] = React.useState(undefined);
  const [session, setsession] = React.useState(undefined);
  const [loading, setloading] = React.useState(false);
  const _save = async () => {
    try {
      setloading(true);
      if (is_null(from) || is_null(to) || is_null(trimester)) {
        swal("error", "Please fill the form properly", setloading);
        return;
      }
      const confirm = await Swal.fire({
        title: "Result Moderation",
        html: `Are you sure you want to moderate student's result in the selected semester from <b>${from}</b> to <b>${to}</b>?
        <br/>Please note that you are about to change student results and that moderation can only be done once in a semester. To remoderate, you will have to reverse this moderation for the selected semester`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, Moderate!",
      });
      if (!confirm.isConfirmed) {
        setloading(false);
        return;
      }
      const req = await axiospost("ms_course_result_moderation", {
        from,
        to,
        trimester,
      });

      if (req.status !== 200)
        throw new Error("Sorry something went wrong, please try again");
      const { type, message } = req.data;
      if (type === "success") {
        swal(type, message, setloading, true);
        return;
      }
      swal(type, message, setloading, false);
    } catch ({ message }) {
      swal("error", message, setloading);
    }
  };

  const _close = () => {
    setshowmodal(false);
  };
  const _show = () => {
    setshowmodal(true);
  };
  React.useEffect(() => {
    (async () => {
      try {
        const req = await axiosget("ms_session");
        if (req.data?.type) {
          throw new Error(req.data?.message);
        }
        const tri = req.data?.map((v) => ({
          name: `${v.code} ${v.name}`,
          value: v._id,
        }));
        setsession(tri);
      } catch ({ message }) {
        console.warn(message);
      }
      setloading(false);
    })();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <ReactModal
        isOpen={showmodal}
        className="ReactModal"
        onRequestClose={_close}
      >
        <div className="modal-dialog " role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-center">
                Academic Result Moderation
              </h5>
              <span className="pointer text-danger" onClick={_close}>
                &times;
              </span>
            </div>
            <div className="modal-body">
              <SelectStatus
                setpagegroup={settrimester}
                options={session}
                label="Select Academic Trimester"
              />
              <div className="form-group">
                <label htmlFor="exampleInputEmail2">From Score</label>
                <input
                  type="number"
                  className="form-control form-control-lg"
                  id="exampleInputEmail2"
                  maxLength={2}
                  onChange={(e) => setfrom(e.target.value)}
                  placeholder="From Score"
                />
              </div>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">To Score</label>
                <input
                  type="number"
                  maxLength={2}
                  onChange={(e) => setto(e.target.value)}
                  className="form-control form-control-lg"
                  id="exampleInputEmail1"
                  placeholder="To Score"
                />
              </div>
            </div>
            <div className="modal-footer">
              {loading ? (
                <button type="button" className="btn btn-primary">
                  Please wait...
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={_save}
                >
                  Moderate Academic Result(s)
                </button>
              )}
            </div>
          </div>
        </div>
      </ReactModal>

      <div>
        <div
          style={{
            position: "fixed",
            right: "50px",
            bottom: "50px",
          }}
        >
          <button
            className="btn btn-icon btn-primary btn-rounded"
            onClick={_show}
          >
            <i className="ti-plus"></i>
          </button>
        </div>
      </div>
    </div>
  );
};

export default BtnResultModeration;
