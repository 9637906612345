import swal from "./swal";

export default (e, callback, width, height, type) => {
  try {
    if (e === undefined) throw new Error("Select a background image");
    console.log(e.type);
    if (!e.type.toString().includes(type))
      throw new Error("Please provide a file that match the format");
    let img = new Image();
    img.src = window.URL.createObjectURL(e);
    img.onload = () => {
      try {
        if (img.width < width || img.height < height)
          throw new Error(
            `Image dimension is not equal to ${width}x${height}px`
          );
        callback(e);
      } catch ({ message }) {
        swal("error", message, undefined, false);
      }
    };
  } catch ({ message }) {
    swal("error", message, undefined, false);
  }
};
