import React from "react";
import Loader from "../Loader";
import { axiosget } from "../../helpers/httprequest";

const ApplicantOLevelResult = ({ applicant }) => {
  const [loading, setloading] = React.useState(true);
  const [sitting, setsitting] = React.useState(null);
  const [result, setresult] = React.useState(null);

  React.useEffect(() => {
    if (applicant !== null && applicant !== undefined) {
      const _id = applicant?.applicant;
      (async () => {
        const req = await axiosget(`ms_applicant_sitting/${_id}`);
        if (req.data) {
          const { sittings, results } = req.data;
          setsitting(sittings);
          setresult(results);
        }
        setloading(false);
      })();
    }
  }, [applicant]);
  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <b>EXAMINATION SITTING(S)</b>
          <hr />
          <div className="row">
            {sitting &&
              sitting?.map((r, i) => (
                <div key={`Examination_Sitting_${i}`} className="col-md-6">
                  <div className="row my-2 bordered_bottom">
                    <div className="col-md-6 text-muted">
                      Examination Sitting
                    </div>

                    <div className="col-md-6"> {r.sit}</div>
                  </div>
                  <div className="row my-2 bordered_bottom">
                    <div className="col-md-6 text-muted">Examination Type</div>
                    <div className="col-md-6"> {r.type}</div>
                  </div>
                  <div className="row my-2 bordered_bottom">
                    <div className="col-md-6 text-muted">
                      Examination Center
                    </div>
                    <div className="col-md-6"> {r.center}</div>
                  </div>
                  <div className="row my-2 bordered_bottom">
                    <div className="col-md-6 text-muted">Examination Year</div>
                    <div className="col-md-6"> {r.year}</div>
                  </div>
                  <div className="row my-2 bordered_bottom">
                    <div className="col-md-6 text-muted">
                      Examination Number
                    </div>
                    <div className="col-md-6"> {r.examnumber}</div>
                  </div>
                </div>
              ))}
          </div>
          <b>EXAMINATION SITTING(S)</b>
          <hr />
          <div className="row">
            {result &&
              result?.map((r, i) => (
                <div key={`${new Date.now()}${i}`} className="col-md-12">
                  <div className="row my-2 bordered_bottom">
                    <div className="col-md-1">
                      <span className="text-muted">SN:</span> {r.sn}
                    </div>
                    <div className="col-md-2 text-muted">Examination</div>
                    <div className="col-md-2"> {r.type}</div>
                    <div className="col-md-2 text-muted">Subject</div>
                    <div className="col-md-2"> {r.subject}</div>
                    <div className="col-md-2 text-muted">Grade Scored</div>
                    <div className="col-md-1"> {r.score}</div>
                  </div>
                </div>
              ))}
          </div>
        </>
      )}
    </div>
  );
};

export default ApplicantOLevelResult;
