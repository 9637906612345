import React from "react";
import Loader from "../../components/Loader";
import { axiosget } from "../../helpers/httprequest";
import swal from "../../helpers/swal";
import moment from "../../helpers/moment";
import BtnStaff from "../../Buttons/BtnStaff";
import is_table from "../../helpers/is_table";
import BtnStaffConf from "../../Buttons/BtnStaffConf";
import LoadDepartment from "../../components/LoadDepartment";

const TblSession = () => {
  const [loading, setloading] = React.useState(true);
  const [data, setdata] = React.useState(null);
  const [department, setdepartment] = React.useState(null);
  React.useEffect(() => {
    (async () => {
      try {
        const req = await axiosget("ms_staff/bio");
        const dept = await axiosget("ms_department/active");
        setdata(req.data);
        setdepartment(dept.data);
        setloading(false);
      } catch ({ message }) {
        swal("error", message, setloading);
      }
    })();
    is_table();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <table
          id="example"
          className="table table-hover table-striped display expandable-table w-100"
        >
          <thead>
            <tr>
              <th>#</th>
              <th>Code</th>
              <th>Full Name</th>
              <th>Status</th>
              <th>Department</th>
              <th>Records</th>
              <th>Edit</th>
              <th>Created At</th>
              <th>Updated At</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((v, i) => (
              <tr key={i}>
                <th>{i + 1}</th>
                <td>{String(v.foldernumber).toLocaleUpperCase()}</td>
                <td>
                  {v.firstname} {v.middlename} {v.lastname}
                </td>
                <td className="text-capitalize">{v.status}</td>
                <td>
                  <LoadDepartment id={v.department} />
                </td>
                <td>
                  <BtnStaffConf department={department} modules={v} key={i} />
                </td>
                <td>
                  <BtnStaff modules={v} key={i} />
                </td>
                <td>{moment(v.createdAt)}</td>
                <td>{moment(v.updatedAt)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </>
  );
};

export default TblSession;
