import React from "react";
import Swal from "sweetalert2";
import { axiospost, axiosput } from "../../helpers/httprequest";
import is_null from "../../helpers/is_null";
import swal from "../../helpers/swal";
import ReactModal from "react-modal";

const BtnEditSystemModules = ({ modules }) => {
  const [showmodal, setshowmodal] = React.useState(false);
  const [icon, seticon] = React.useState(
    modules !== undefined ? modules.icon : ""
  );
  const [name, setname] = React.useState(
    modules !== undefined ? modules.name : ""
  );
  const [loading, setloading] = React.useState(false);

  const _save = async () => {
    try {
      setloading(true);
      if (is_null(icon) || is_null(name)) {
        swal("error", "Please fill the form properly", setloading);
        return;
      }
      let req;
      if (modules !== undefined) {
        const { _id } = modules;
        req = await axiosput(`ms_page_group/${_id}`, { icon, name });
      } else {
        req = await axiospost("ms_page_group", { icon, name });
      }

      if (req.status !== 200)
        throw new Error("Sorry something went wrong, please try again");
      const { type, message } = req.data;

      if (type === "success") {
        swal(type, message, setloading, true);
        return;
      }
      swal(type, message, setloading);
    } catch ({ message }) {
      swal("error", message, setloading);
    }
  };
  const _close = () => {
    setshowmodal(false);
    seticon("");
    setname("");
  };
  const _show = () => {
    setshowmodal(true);
    seticon(modules !== undefined ? modules.icon : "");
    setname(modules !== undefined ? modules.name : "");
  };

  return (
    <div>
      <ReactModal
        isOpen={showmodal}
        onRequestClose={() => setshowmodal(false)}
        className="ReactModal"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-center">
                {modules === undefined ? "Create" : "Update"} System Module
              </h5>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">System Module Icon</label>
                <input
                  type="email"
                  autoFocus
                  className="form-control form-control-lg"
                  id="exampleInputEmail1"
                  placeholder="System Module Icon"
                  onChange={(e) => seticon(e.target.value)}
                  value={icon}
                />
              </div>
              <div className="form-group">
                <label htmlFor="exampleInputEmail2">System Module Name</label>
                <input
                  type="email"
                  className="form-control form-control-lg"
                  id="exampleInputEmail2"
                  placeholder="System Module Name"
                  onChange={(e) => setname(e.target.value)}
                  value={name}
                />
              </div>
            </div>
            <div className="modal-footer">
              {loading ? (
                <button type="button" className="btn btn-primary">
                  Please wait...
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={_save}
                >
                  Save changes
                </button>
              )}

              <button type="button" className="btn btn-danger" onClick={_close}>
                Close
              </button>
            </div>
          </div>
        </div>
      </ReactModal>
      <div>
        {!modules && (
          <div
            style={{
              position: "fixed",
              right: "50px",
              bottom: "50px",
            }}
          >
            <button
              className="btn btn-icon btn-primary btn-rounded"
              onClick={_show}
            >
              <i className="ti-plus"></i>
            </button>
          </div>
        )}
      </div>
      <div>
        {modules && (
          <button
            className="btn btn-icon btn-primary btn-rounded"
            onClick={_show}
          >
            <i className="ti-pencil"></i>
          </button>
        )}
      </div>
    </div>
  );
};

export default BtnEditSystemModules;
