import React from "react";
import ReactModal from "react-modal";
import { contextapi } from "../../helpers/contextapi";
import { axiosget } from "../../helpers/httprequest";
import is_null from "../../helpers/is_null";

const ResultChanges = ({ info }) => {
  const context = React.useContext(contextapi);
  const { classroomCourse } = context.values;
  const [showmodal, setshowmodal] = React.useState(false);
  const [data, setdata] = React.useState(undefined);
  React.useEffect(() => {
    (async () => {
      try {
        const req = await axiosget(
          `ms_course_registration/student/${info.regno}/${classroomCourse}`
        );
        if (req.data?.type) {
          throw new Error(req.data?.message);
        }
        if (req?.data !== null) {
          const { history } = req.data;
          setdata(history);
        }
      } catch ({ message }) {
        console.warn(message);
      }
    })();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  console.log(data);
  return (
    <div>
      <ReactModal
        isOpen={showmodal}
        onRequestClose={() => setshowmodal(false)}
        className="ReactModal"
      >
        <div className="modal-dialog " role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-center text-uppercase">
                {info.name} RESULt changes
              </h5>
            </div>
            <div className="modal-body">
              <div className="table-responsive">
                <table className="table table-hover table-bordered">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>CA</th>
                      <th>Exams</th>
                      <th>Total</th>
                      <th>Grade</th>
                      <th>Point</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.map((v, i) => (
                      <tr>
                        <th>{i + 1}</th>
                        <td>{!is_null(v.cascore) ? v.cascore : 0}</td>
                        <td>{!is_null(v.examscore) ? v.examscore : 0}</td>
                        <td>{!is_null(v.total) ? v.total : 0}</td>
                        <td>{!is_null(v.grade) ? v.grade : "-"}</td>
                        <td>{!is_null(v.point) ? v.total : 0}</td>
                        <td>{v.updatedAt}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => setshowmodal(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </ReactModal>
      <div className="pointer text-primary" onClick={() => setshowmodal(true)}>
        {info.studentid}
      </div>
    </div>
  );
};

export default ResultChanges;
