const status = "production";
// const status = "development";
export const localRequest =
  status === "development"
    ? "http://localhost:3005/"
    : "https://coekangere-ug-ffb8872e9bc4.herokuapp.com/";
export const appHost =
  status === "development"
    ? "http://localhost:3000/"
    : "https://ugstaff.coekangere.edu.ng/";

export const UploadLink =
  status === "development" ? "https://doc.samtec.ng" : "https://doc.samtec.ng";
