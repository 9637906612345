import React from "react";
import { axiosget } from "../../helpers/httprequest";

const LoadDepartment = ({ id }) => {
  const [loading, setloading] = React.useState(true);
  const [dept, setdept] = React.useState();
  React.useEffect(() => {
    (async () => {
      try {
        const req = await axiosget(`ms_department/${id}`);
        if (req.data?.type) {
          throw new Error(req.data?.message);
        }
        setdept(req.data?.name);
        setloading(false);
      } catch ({ message }) {
        console.warn(message);
        setloading(false);
      }
      setloading(false);
    })();
  }, []);
  return <div>{loading ? "Please wait" : dept}</div>;
};

export default LoadDepartment;
