import React from "react";
import Modal from "react-modal";
import { axiosput } from "../../helpers/httprequest";
import is_null from "../../helpers/is_null";
import swal from "../../helpers/swal";
import SelectStatus from "../../Select/SelectStatus";

const BtnStaffConf = ({ modules, department }) => {
  const [showmodal, setshowmodal] = React.useState(false);
  const [loading, setloading] = React.useState(false);
  //-----seperator ------//
  const [foldernumber, setfoldernumber] = React.useState(
    modules ? modules?.foldernumber : ""
  );

  const [title, settitle] = React.useState(modules ? modules?.title : "");
  const [status, setstatus] = React.useState(modules ? modules?.status : "");
  const [depts, setdepts] = React.useState(modules ? modules?.department : "");

  const _save = async () => {
    try {
      setloading(true);
      if (is_null(foldernumber) || is_null(title) || is_null(depts)) {
        swal("warning", "Please fill the form properly", setloading, false);
        return;
      }
      const {
        photo,
        firstname,
        middlename,
        lastname,
        gender,
        religion,
        dateofbirth,
        qualification,
        maritalstatus,
        emailaddress,
        phonenumber,
        homeaddress,
        stateoforigin,
        stateofresidence,
        lgaoforigin,
        lgaofresidence,
      } = modules;

      let req;

      req = await axiosput(`ms_staff/bio/${modules._id}`, {
        fileno: foldernumber,
        title,
        department: depts,
        photo,
        status,
        firstname,
        middlename,
        lastname,
        gender,
        religion,
        dateofbirth: String(dateofbirth).split("T")[0],
        qualification,
        maritalstatus,
        emailaddress,
        phonenumber,
        homeaddress,
        stateoforigin,
        stateofresidence,
        lgaoforigin,
        lgaofresidence,
      });

      if (req.status !== 200) {
        throw new Error(
          "Sorry, system could not finish processing your request"
        );
      }
      const { data } = req;
      if (data && data.type === "success") {
        const { message, type } = data;
        swal(type, message, setloading, true);
      } else {
        throw new Error(data.message);
      }
    } catch ({ message }) {
      swal("error", message, setloading);
    }
  };

  const _close = () => {
    setshowmodal(false);
  };
  const _show = () => {
    setshowmodal(true);
  };
  const lgas = department
    ?.map((v) => ({ name: `${v.code} ${v.name}`, value: v?._id }))
    .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
  const stats = [
    { name: "Active", value: "Active" },
    { name: "Deceased", value: "Deceased" },
    { name: "Suspended", value: "Suspended" },
    { name: "Terminated", value: "Terminated" },
    { name: "Resigned", value: "Resigned" },
  ];
  return (
    <div>
      <Modal
        isOpen={showmodal}
        onRequestClose={() => setshowmodal(false)}
        className="ReactModal"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-center">
                {modules?.firstname}'s' more information
              </h5>
            </div>
            <div className="modal-body">
              <div className="pt-1 mb-3">
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="title">
                        Title <b className="text-danger">*</b>
                      </label>
                      <input
                        className="form-control form-control-lg"
                        id="title"
                        required
                        placeholder="Title*"
                        onChange={(e) => settitle(e.target.value)}
                        value={title}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="foldernumber">Folder Number</label>
                      <input
                        className="form-control form-control-lg"
                        id="foldernumber"
                        placeholder="Folder Number"
                        onChange={(e) => setfoldernumber(e.target.value)}
                        value={foldernumber}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <SelectStatus
                      options={lgas}
                      label="Select Department"
                      setpagegroup={setdepts}
                      active={modules.department}
                    />
                  </div>
                  <div className="col-md-12">
                    <SelectStatus
                      options={stats}
                      label="Select Status"
                      setpagegroup={setstatus}
                      active={status}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              {loading ? (
                <button type="button" className="btn btn-primary">
                  Please wait...
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={_save}
                >
                  Save changes
                </button>
              )}
              <button type="button" className="btn btn-danger" onClick={_close}>
                Close
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <div>
        {!modules && (
          <div
            style={{
              position: "absolute",
              right: "50px",
              bottom: "50px",
            }}
          >
            <button
              className="btn btn-icon btn-primary btn-rounded"
              onClick={_show}
            >
              <i className="ti-plus"></i>
            </button>
          </div>
        )}
      </div>
      <div>
        {modules && (
          <button className="btn btn-icon btn-info btn-rounded" onClick={_show}>
            <i className="icon-cog"></i>
          </button>
        )}
      </div>
    </div>
  );
};

export default BtnStaffConf;
