import React from "react";
import { axiosget, axiospost, axiosput } from "../../helpers/httprequest";
import is_null from "../../helpers/is_null";
import swal from "../../helpers/swal";
import SelectStatus from "../../Select/SelectStatus";
import ReactModal from "react-modal";

const BtnProgramme = ({ modules }) => {
  const [showmodal, setshowmodal] = React.useState(false);
  const [code, setcode] = React.useState(modules ? modules.code : "");
  const [name, setname] = React.useState(modules ? modules.name : "");
  const [minunit, setminunit] = React.useState(modules ? modules.minunit : "");
  const [maxunit, setmaxunit] = React.useState(modules ? modules.maxunit : "");
  const [status, setstatus] = React.useState("");
  const [loading, setloading] = React.useState(false);
  const [department, setdepartment] = React.useState(null);
  const [deptlist, setdeptlist] = React.useState(null);

  React.useEffect(() => {
    (async () => {
      try {
        const req = await axiosget("ms_department/academic");
        if (req.data) {
          const list = await Promise.all(
            req.data?.map((v) => ({ name: v.name, value: v._id }))
          );
          setdeptlist(list);
        }
      } catch ({ message }) {
        console.warn(message);
      }
    })();
  }, []);

  const _save = async () => {
    try {
      setloading(true);
      if (
        is_null(code) ||
        is_null(name) ||
        is_null(status) ||
        is_null(minunit) ||
        is_null(maxunit) ||
        is_null(department)
      ) {
        swal("error", "Please fill the form properly", setloading);
        return;
      }
      let req;
      if (modules !== undefined) {
        const { _id } = modules;
        req = await axiosput(`ms_programme/${_id}`, {
          name,
          code,
          status,
          minunit,
          maxunit,
          department,
        });
      } else {
        req = await axiospost("ms_programme", {
          name,
          code,
          minunit,
          maxunit,
          status,
          department,
        });
      }

      if (req.status !== 200)
        throw new Error("Sorry something went wrong, please try again");
      const { type, message } = req.data;
      swal(type, message, setloading, true);
      if (type === "success") {
        _close();
      }
    } catch ({ message }) {
      swal("error", message, setloading);
    }
  };

  const _close = () => {
    setshowmodal(false);
  };
  const _show = () => {
    setshowmodal(true);
  };

  return (
    <div>
      <ReactModal
        isOpen={showmodal}
        onRequestClose={_close}
        className="ReactModal"
      >
        <div className="modal-dialog " role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-center">
                {modules ? `Update ${modules.name}` : "Create New Programme"}
              </h5>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail2">Programme Code</label>
                    <input
                      type="email"
                      className="form-control form-control-lg"
                      id="exampleInputEmail2"
                      maxLength={4}
                      onChange={(e) => setcode(e.target.value)}
                      placeholder="Programme Code"
                      value={code}
                    />
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Programme Name</label>
                    <input
                      type="email"
                      autoFocus
                      onChange={(e) => setname(e.target.value)}
                      className="form-control form-control-lg"
                      id="exampleInputEmail1"
                      placeholder="Programme Name"
                      value={name}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail2">
                      Minimum Registration Credit
                    </label>
                    <input
                      type="number"
                      className="form-control form-control-lg"
                      id="exampleInputEmail2"
                      maxLength={4}
                      min="1"
                      onChange={(e) => setminunit(e.target.value)}
                      placeholder="Minimum Registration Credit"
                      value={minunit}
                    />
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">
                      Maximum Registration Credit
                    </label>
                    <input
                      type="number"
                      autoFocus
                      max={100}
                      onChange={(e) => setmaxunit(e.target.value)}
                      className="form-control form-control-lg"
                      id="exampleInputEmail1"
                      placeholder="Maximum Registration Credit"
                      value={maxunit}
                    />
                  </div>
                </div>
              </div>
              {deptlist && (
                <SelectStatus
                  setpagegroup={setdepartment}
                  options={deptlist}
                  label="Select Department"
                />
              )}

              <SelectStatus
                setpagegroup={setstatus}
                options={[
                  { name: "Active", value: true },
                  { name: "Inactive", value: false },
                ]}
                label="Select Status"
              />
            </div>

            <div className="modal-footer">
              {loading ? (
                <button type="button" className="btn btn-primary">
                  Please wait...
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={_save}
                >
                  Save changes
                </button>
              )}
              <button type="button" className="btn btn-danger" onClick={_close}>
                Close
              </button>
            </div>
          </div>
        </div>
      </ReactModal>

      <div>
        {!modules && (
          <div
            style={{
              position: "fixed",
              right: "50px",
              bottom: "50px",
            }}
          >
            <button
              className="btn btn-icon btn-primary btn-rounded"
              onClick={_show}
            >
              <i className="ti-plus"></i>
            </button>
          </div>
        )}
      </div>
      <div>
        {modules && (
          <button
            className="btn btn-icon btn-primary btn-rounded"
            onClick={_show}
          >
            <i className="ti-pencil"></i>
          </button>
        )}
      </div>
    </div>
  );
};

export default BtnProgramme;
