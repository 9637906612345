import Swal from "sweetalert2";

export default async (icon, text, callback, is_reload) => {
  await Swal.fire({
    html: text,
    icon,
    confirmButtonText: "Ok, got it",
    allowOutsideClick: false,
  }).then((r) => {
    if (callback === false) {
      callback();
    } else {
      if (callback !== undefined) {
        callback();
      }
    }
    if (is_reload) {
      window.location.reload();
    }
  });
};
