import React from "react";
import Loader from "../../components/Loader";
import { axiosget } from "../../helpers/httprequest";
import swal from "../../helpers/swal";
import DelCourseAllocation from "../../Buttons/DelCourseAllocation";

const TblCourseAllocation = ({ data }) => {
  return (
    <div className="table-responsive">
      <table
        id="example"
        className="table table-hover table-striped display expandable-table w-100"
      >
        <thead>
          <tr>
            <th>#</th>
            <th>Lecturer</th>
            <th>Phone Number</th>
            <th>Semester</th>
            <th>Module</th>
            <th>Level</th>
            <th>Semester</th>
            <th>Credit</th>
            <th>Type</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((v, i) => (
            <tr key={i}>
              <th>{i + 1}</th>
              <td>
                {v.firstname} {v.middlename} {v.lastname}
              </td>
              <td>{v.phonenumber}</td>
              <td>
                {String(v.semcode).toLocaleUpperCase()} {v.semname}
              </td>
              <td>
                {String(v.coursecode).toLocaleUpperCase()} {v.coursename}
              </td>
              <td>{v.level}</td>
              <td>{v.semester}</td>
              <td>{v.credit}</td>
              <td>{v.type}</td>
              <td>
                <DelCourseAllocation modules={v} key={i} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TblCourseAllocation;
