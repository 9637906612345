import React from "react";
import ReactModal from "react-modal";
import { axiospost, axiosput } from "../../helpers/httprequest";
import is_null from "../../helpers/is_null";
import swal from "../../helpers/swal";
import { contextapi } from "../../helpers/contextapi";
import SelectStatus from "../../Select/SelectStatus";

const BtnClassTopic = ({ modules }) => {
  const [showmodal, setshowmodal] = React.useState(false);
  const [name, setname] = React.useState(modules ? modules.label : "");
  const [status, setstatus] = React.useState(modules ? modules.status : "");
  const [loading, setloading] = React.useState(false);
  const context = React.useContext(contextapi);
  const { classroomCourse } = context.values;
  const _save = async () => {
    try {
      setloading(true);
      if (is_null(classroomCourse) || is_null(name) || is_null(status)) {
        swal("error", "Please fill the form properly", setloading);
        return;
      }
      let req;
      if (modules !== undefined) {
        const { _id } = modules;
        req = await axiosput(`ms_course_topic/${_id}`, {
          label: name,
          course: classroomCourse,
          status,
        });
      } else {
        req = await axiospost("ms_course_topic", {
          label: name,
          course: classroomCourse,
          status,
        });
      }

      if (req.status !== 200)
        throw new Error("Sorry something went wrong, please try again");
      const { type, message } = req.data;

      if (type === "success") {
        swal(type, message, setloading, true);
        return;
      }
      swal(type, message, setloading, false);
    } catch ({ message }) {
      swal("error", message, setloading);
    }
  };

  const _close = () => {
    setshowmodal(false);
  };
  const _show = () => {
    setshowmodal(true);
  };

  return (
    <div>
      <ReactModal
        isOpen={showmodal}
        onRequestClose={() => setshowmodal(false)}
        className="ReactModal"
      >
        <div className="modal-dialog " role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-center">
                {modules ? `Update ${modules?.label}` : "Create Topic"}
              </h5>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <label htmlFor="exampleInputEmail2">
                  Topic Title (Chapter 1 or Week 1 etc)
                </label>
                <input
                  type="email"
                  className="form-control form-control-lg"
                  id="exampleInputEmail2"
                  onChange={(e) => setname(e.target.value)}
                  placeholder="Topic Title (Chapter 1 or Week 1 etc)"
                  value={name}
                />
              </div>
              <SelectStatus
                setpagegroup={setstatus}
                options={[
                  { name: "Active", value: true },
                  { name: "Inactive", value: false },
                ]}
                label="Select Topic Status"
                active={status}
              />
            </div>
            <div className="modal-footer">
              {loading ? (
                <button type="button" className="btn btn-primary">
                  Please wait...
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={_save}
                >
                  Save changes
                </button>
              )}
              <button type="button" className="btn btn-danger" onClick={_close}>
                Close
              </button>
            </div>
          </div>
        </div>
      </ReactModal>

      {!modules && (
        <div className="dropdown-item" onClick={_show}>
          <span className="icon-book"></span> Create Topic
        </div>
      )}

      {modules && <span className="ti-pencil" onClick={_show}></span>}
    </div>
  );
};

export default BtnClassTopic;
