import React from "react";
import ReactModal from "react-modal";

const AddFilterButton = ({ setfilters, filters }) => {
  const [showmodal, setshowmodal] = React.useState(false);
  const [subject, setsubject] = React.useState(null);
  const [score, setscore] = React.useState(null);
  const _close = () => {
    setshowmodal(false);
  };
  const _add_to_filter = () => {
    if (subject === null || score === null) return;
    let data = [...filters, { subject: subject, score: score }];
    if (filters?.length) {
      const findIn = filters?.filter((a) => a.subject === subject);
      if (findIn?.length) {
        const x = findIn.shift();
        if (x.score === score) return;
        data = filters?.map((r) => (r.subject = subject ? { ...r, score } : r));
      }
      setfilters(data);
      return;
    }
    setfilters(data);
  };
  const _remove_from_list = () => {
    let data = filters.filter((a) => (a.subject === subject ? false : a));
    setfilters(data);
  };
  return (
    <div className="mt-4">
      <ReactModal
        isOpen={showmodal}
        className="ReactModal"
        onRequestClose={_close}
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-center text-uppercase">
                Select Filter Parameter [Close when you finish selecting the
                filters]
              </h5>
              <span
                type="button"
                className="pointer text-danger"
                onClick={_close}
              >
                &times;
              </span>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="result_subject">
                      Select Subject <span className="text-danger">*</span>
                    </label>
                    <select
                      id="result_subject"
                      onChange={(e) => setsubject(e.target.value)}
                      data="0"
                      className="form-control"
                    >
                      <option value="">--</option>
                      <option value="Additional General Science">
                        Additional General Science
                      </option>
                      <option value="Additional Mathematics">
                        Additional Mathematics
                      </option>
                      <option value="Agricultural Science">
                        Agricultural Science
                      </option>
                      <option value="Animal Husbandry">Animal Husbandry</option>
                      <option value="Applied Mathematics">
                        Applied Mathematics
                      </option>
                      <option value="Arabic Language">Arabic Language</option>
                      <option value="Biology">Biology</option>
                      <option value="Botany">Botany</option>
                      <option value="Chemistry">Chemistry</option>
                      <option value="CRS/IRS">CRS/IRS</option>
                      <option value="Commerce">Commerce</option>
                      <option value="Civic Education">Civic Education</option>
                      <option value="Computer Studies">Computer Studies</option>
                      <option value="Data Processing">Data Processing</option>
                      <option value="English Language">English Language</option>
                      <option value="Economics">Economics</option>
                      <option value="Food Economics">Food Economics</option>
                      <option value="Food Management">Food Management</option>
                      <option value="Food Science">Food Science</option>
                      <option value="Food and Nutrition">
                        Food and Nutrition
                      </option>
                      <option value="Further Mathematics">
                        Further Mathematics
                      </option>
                      <option value="Fine and Applied Arts">
                        Fine and Applied Arts
                      </option>
                      <option value="Financial Accounting">
                        Financial Accounting
                      </option>
                      <option value="French">French</option>
                      <option value="General Biology">General Biology</option>
                      <option value="General Education">
                        General Education
                      </option>
                      <option value="General Science">General Science</option>
                      <option value="Government">Government</option>
                      <option value="Geography">Geography</option>
                      <option value="Geology">Geology</option>
                      <option value="Geography">Geography</option>
                      <option value="Hausa Language">Hausa Language</option>
                      <option value="Health Science">Health Science</option>
                      <option value="Human Biology">Human Biology</option>
                      <option value="Hygiene">Hygiene</option>
                      <option value="History">History</option>
                      <option value="Integrated Science">
                        Integrated Science
                      </option>
                      <option value="Igbo">Igbo</option>
                      <option value="Islamic History">Islamic History</option>
                      <option value="Literature in English">
                        Literature in English
                      </option>
                      <option value="Mathematics">Mathematics</option>
                      <option value="Music">Music</option>
                      <option value="Marketing">Marketing</option>
                      <option value="Other Nigerian Languages">
                        Other Nigerian Languages
                      </option>
                      <option value="Physics">Physics</option>
                      <option value="Pure and Applied Mathematics">
                        Pure and Applied Mathematics
                      </option>
                      <option value="Principles of Accounts">
                        Principles of Accounts
                      </option>
                      <option value="Qua'anic Studies">Qua'anic Studies</option>
                      <option value="Rural Biology">Rural Biology</option>
                      <option value="Rural Science">Rural Science</option>
                      <option value="Social Studies">Social Studies</option>
                      <option value="Statistics">Statistics</option>
                      <option value="Tahfeezul Qur'an">Tahfeezul Qur'an</option>
                      <option value="Teaching Practice">
                        Teaching Practice
                      </option>
                      <option value="Ulumul Qur'an">Ulumul Qur'an</option>
                      <option value="Zoology">Zoology</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="result_score">
                      Select Minimum Grade{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <select
                      id="result_score"
                      onChange={(e) => setscore(e.target.value)}
                      data="0"
                      className="form-control"
                    >
                      <option value="">--</option>
                      <option value="A1">A1</option>
                      <option value="B2">B2</option>
                      <option value="B3">B3</option>
                      <option value="C4">C4</option>
                      <option value="C5">C5</option>
                      <option value="C6">C6</option>
                      <option value="D7">D7</option>
                      <option value="E8">E8</option>
                      <option value="F9">F9</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-2 mt-4">
                  <button
                    className="btn btn-primary btn-block btn-lg"
                    onClick={_add_to_filter}
                  >
                    <i className="fa fa-plus"></i>
                  </button>
                </div>
                <div className="col-md-2 mt-4">
                  <button
                    className="btn btn-danger btn-block btn-lg"
                    onClick={_remove_from_list}
                  >
                    <i className="fa fa-minus"></i>
                  </button>
                </div>
              </div>
              <hr />
              <br />
              <div className="row">
                <div className="col-md-12 mb-5">
                  {filters &&
                    filters?.map((r, i) => (
                      <span key={`filter_key_${i}`} className="m-2 border p-3">
                        {`${r.subject} | ${r.score} `}
                      </span>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </ReactModal>
      <button
        className="btn btn-primary btn-lg"
        onClick={() => setshowmodal(true)}
      >
        <i className="fa fa-plus"></i>
      </button>
    </div>
  );
};

export default AddFilterButton;
