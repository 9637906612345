import React from "react";

const Dashboard = () => {
  return (
    <div className="main-panel">
      <div className="content-wrapper" style={{ background: "white" }}>
        <div className="row">
          <div className="col-md-12 grid-margin">
            <div className="row">
              <div className="col-12 col-xl-8 mb-4 mb-xl-0">
                <h3 className="font-weight-bold">Welcome Back</h3>
                <h6 className="font-weight-normal mb-0">
                  Dashboard coming soon
                </h6>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 grid-margin stretch-card">
            <div className="card tale-bg">
              <div className="card-people mt-auto">
                <img src="./images/dashboard/people.svg" alt="people" />
                <div className="weather-info">
                  <div className="d-flex">
                    <div>
                      <h2 className="mb-0 font-weight-normal">
                        <i className="icon-sun mr-2"></i>25<sup>C</sup>
                      </h2>
                    </div>
                    <div className="ml-2">
                      <h4 className="location font-weight-normal">Bauchi</h4>
                      <h6 className="font-weight-normal">Nigeria</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 grid-margin transparent">
            <div className="row">
              <div className="col-md-6 mb-4 stretch-card transparent">
                <div className="card card-tale">
                  <div className="card-body">
                    <p className="mb-4">Total Application</p>
                    <p className="fs-30 mb-2">4006</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-4 stretch-card transparent">
                <div className="card card-dark-blue">
                  <div className="card-body">
                    <p className="mb-4">Pending Applications</p>
                    <p className="fs-30 mb-2">61344</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mb-4 mb-lg-0 stretch-card transparent">
                <div className="card card-light-blue">
                  <div className="card-body">
                    <p className="mb-4">Approved Applications</p>
                    <p className="fs-30 mb-2">34040</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 stretch-card transparent">
                <div className="card card-light-danger">
                  <div className="card-body">
                    <p className="mb-4">Rejected Application</p>
                    <p className="fs-30 mb-2">47033</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row my-4">
              <div className="col-md-6 mb-4 stretch-card transparent">
                <div className="card card-tale">
                  <div className="card-body">
                    <p className="mb-4">My Students</p>
                    <p className="fs-30 mb-2">4006</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-4 stretch-card transparent">
                <div className="card card-dark-blue">
                  <div className="card-body">
                    <p className="mb-4">Total Materials</p>
                    <p className="fs-30 mb-2">61344</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mb-4 mb-lg-0 stretch-card transparent">
                <div className="card card-light-blue">
                  <div className="card-body">
                    <p className="mb-4">Total Notifications</p>
                    <p className="fs-30 mb-2">34040</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 stretch-card transparent">
                <div className="card card-light-danger">
                  <div className="card-body">
                    <p className="mb-4">Number of Logins</p>
                    <p className="fs-30 mb-2">47033</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
