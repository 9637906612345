import React from "react";
import Loader from "../../components/Loader";
import moment from "../../helpers/moment";
import BtnApplicationProfile from "../../Buttons/BtnApplicationProfile";

const TblApplication = ({ dataset, tableloading }) => {
  const _status = (status) => {
    let res;
    switch (status) {
      case 0:
        res = "Pending";
        break;
      case 1:
        res = "Provisional Admission";
        break;
      case 2:
        res = "Conditional Admission";
        break;
      case 3:
        res = "Rejected";
        break;
    }
    return res;
  };
  return (
    <>
      {tableloading ? (
        <Loader />
      ) : (
        <table
          id="example"
          className="table table-hover table-striped display expandable-table w-100"
        >
          <thead>
            <tr>
              <th>#</th>
              <th>Code</th>
              <th>Name</th>
              <th>Email Address</th>
              <th>Phone Number</th>
              <th>Status</th>
              <th>Confirm</th>
              <th>Applied On</th>
            </tr>
          </thead>
          <tbody>
            {dataset?.map((v, i) => (
              <tr key={i}>
                <th>{i + 1}</th>
                <td>{v.code}</td>
                <td>
                  {v.firstname} {v.middlename} {v.lastname}
                </td>
                <td>{v.emailaddress}</td>
                <td>{v.phonenumber}</td>
                <td>{v.status ? "Confirmed" : "Pending"}</td>
                <td>
                  <BtnApplicationProfile modules={v} key={i} />
                </td>

                <td>{moment(v.updatedAt)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </>
  );
};

export default TblApplication;
