import React from "react";
import Loader from "../../components/Loader";
import { axiosget } from "../../helpers/httprequest";
import moment from "../../helpers/moment";
import BtnEditSystemModules from "../../Buttons/BtnEditSystemModules";
import DelSystemModules from "../../Buttons/DelSystemModules";
import is_table from "../../helpers/is_table";
// import is_table from "../../helpers/is_table";

const TblSystemModules = () => {
  const [loading, setloading] = React.useState(true);
  const [data, setdata] = React.useState(null);
  React.useEffect(() => {
    (async () => {
      try {
        const req = await axiosget("ms_page_group");
        setdata(req.data);
        setloading(false);
      } catch ({ message }) {
        console.error(message);
        setloading(false);
      }
    })();
    is_table();
  }, []);
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <table id="example" className="table table-hover table-striped">
          <thead>
            <tr>
              <th>#</th>
              <th>Icon</th>
              <th>Name</th>
              <th>Edit</th>
              <th>Delete</th>
              <th>Created At</th>
              <th>Updated At</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((v, i) => (
              <tr key={i}>
                <th>{i + 1}</th>
                <td>
                  <i className={`${v.icon} menu-icon`}></i>
                </td>
                <td>{v.name}</td>
                <td>
                  <BtnEditSystemModules modules={v} key={i} />
                </td>
                <td>
                  <DelSystemModules modules={v} key={i} />
                </td>
                <td>{moment(v.createdAt)}</td>
                <td>{moment(v.updatedAt)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </>
  );
};

export default TblSystemModules;
