import React from "react";
import { axiospost, axiosget } from "../../helpers/httprequest";
import is_null from "../../helpers/is_null";
import swal from "../../helpers/swal";
import SelectStatus from "../../Select/SelectStatus";
import Loader from "../../components/Loader";
import TblApplicationProfile from "../../tables/TblApplicationProfile";
import is_money from "../../helpers/is_money";

const ApplicationReport = () => {
  const [dataset, setdataset] = React.useState();
  const [loading, setloading] = React.useState(false);
  const cardStyle = {
    background: "green",
    borderRadius: "15px",
    padding: 15,
    color: "white",
  };
  React.useEffect(() => {
    (async () => {
      try {
        setloading(true);
        const req = await axiosget("ms_applications/aes_report");
        const { data } = req;
        setdataset(data);
      } catch ({ message }) {
        console.warn(message);
      }
      setloading(false);
    })();
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <h1 className="text-uppercase">
            APPLICATION REPORT [Executive Summary]
          </h1>
          <hr />
          <div className="row">
            <div className="col-md-4">
              <div className="card" style={cardStyle}>
                <div className="card-content text-center">
                  <h1>{dataset?.paid_completed}</h1>
                  <hr />
                  <h2>Paid & Completed</h2>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card" style={cardStyle}>
                <div className="card-content text-center">
                  <h1>{dataset?.paid_ncompleted}</h1>
                  <hr />
                  <h2>Paid Not Completed</h2>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card" style={cardStyle}>
                <div className="card-content text-center">
                  <h1>{dataset?.npaid_completed}</h1>
                  <hr />
                  <h2>Not Paid</h2>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-md-6">
              <div className="card" style={cardStyle}>
                <div className="card-content text-center">
                  <h1>{dataset?.ntotal}</h1>
                  <hr />
                  <h2>Total Applications</h2>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="card" style={cardStyle}>
                <div className="card-content text-center">
                  <h1>{dataset && is_money(dataset?.totalpaid)}</h1>
                  <hr />
                  <h2>Paid Amount</h2>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ApplicationReport;
