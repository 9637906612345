import React from "react";
import ReactModal from "react-modal";
import { axiospost } from "../../helpers/httprequest";
import is_null from "../../helpers/is_null";
import swal from "../../helpers/swal";

const BtnCreditWallet = ({ modules }) => {
  const [showmodal, setshowmodal] = React.useState(false);
  const [trxid, settrxid] = React.useState(undefined);
  const [amount, setamount] = React.useState(undefined);
  const [loading, setloading] = React.useState(false);
  const _save = async () => {
    try {
      setloading(true);
      if (is_null(trxid) || is_null(amount)) {
        swal("error", "Please fill the form properly", setloading);
        return;
      }
      const req = await axiospost("ms_student_wallet_transactions/credit", {
        student: modules._id,
        trxid,
        amount,
      });

      if (req.status !== 200)
        throw new Error("Sorry something went wrong, please try again");
      const { type, message } = req.data;
      if (type === "success") {
        swal(type, message, setloading, true);
        return;
      }
      swal(type, message, setloading, false);
    } catch ({ message }) {
      swal("error", message, setloading);
    }
  };

  const _close = () => {
    setshowmodal(false);
  };
  const _show = () => {
    setshowmodal(true);
  };

  return (
    <div>
      <ReactModal
        isOpen={showmodal}
        className="ReactModal"
        onRequestClose={_close}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-center text-uppercase">
                {modules.studentid} | Credit {modules.firstname}'s wallet
              </h5>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <label htmlFor="exampleInputEmail2">
                  Unique Transaction Code
                </label>
                <input
                  type="email"
                  className="form-control form-control-lg"
                  id="exampleInputEmail2"
                  placeholder="Unique Transaction Code"
                  onChange={(e) => settrxid(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="exampleInputEmail2">Credit Amount</label>
                <input
                  type="number"
                  className="form-control form-control-lg"
                  id="exampleInputEmail2"
                  placeholder="Credit Amount"
                  onChange={(e) => setamount(e.target.value)}
                />
              </div>
            </div>
            <div className="modal-footer">
              {loading ? (
                <button type="button" className="btn btn-primary">
                  Please wait...
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={_save}
                >
                  Save changes
                </button>
              )}
              <button type="button" className="btn btn-danger" onClick={_close}>
                Close
              </button>
            </div>
          </div>
        </div>
      </ReactModal>
      <div>
        <button
          className="btn btn-icon btn-primary btn-rounded"
          onClick={_show}
        >
          <i className="ti-money"></i>
        </button>
      </div>
    </div>
  );
};

export default BtnCreditWallet;
