import React from "react";
import { contextapi } from "../../helpers/contextapi";
import { axiosget, axiospost } from "../../helpers/httprequest";
import is_grade from "../../helpers/is_grade";
import is_null from "../../helpers/is_null";
import swal from "../../helpers/swal";
import ResultChanges from "../ResultChanges";

const EnterAcademicResult = ({ info, sn }) => {
  console.log(info);
  const context = React.useContext(contextapi);
  const { classroomCourse } = context.values;
  const [showexams, setshowexams] = React.useState(null);
  const [showca, setshowca] = React.useState(null);
  const [ca, setca] = React.useState(0);
  const [total, settotal] = React.useState(0);
  const [exams, setexams] = React.useState(0);
  const [_id, setid] = React.useState();
  React.useEffect(() => {
    (async () => {
      try {
        const req = await axiosget(
          `ms_course_registration/student/${info.regno}/${classroomCourse}`
        );
        if (req.data?.type) {
          throw new Error(req.data?.message);
        }
        if (req?.data !== null) {
          const { examscore, cascore, _id: id } = req.data;
          console.log(examscore, cascore, id);
          setca(!is_null(cascore) ? cascore : 0);
          setexams(!is_null(examscore) ? examscore : 0);
          setid(id);
        } else {
          setca(0);
          setexams(0);
        }
      } catch ({ message }) {
        console.warn(message);
      }
    })();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (ca !== 0) {
      settotal(Number(ca) + Number(exams));
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ca]);
  React.useEffect(() => {
    if (exams !== 0) {
      settotal(Number(ca) + Number(exams));
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exams]);
  const _storeexams = async (e) => {
    try {
      if (e.key === "Enter") {
        if (exams > 70) {
          swal(
            "info",
            "Sorry, Exams score cannot be above 70",
            undefined,
            false
          );
          return;
        }
        const req = await axiospost(`ms_course_registration/exams`, {
          registration: _id,
          examscore: exams,
        });
        if (req.status !== 200) {
          setshowexams("is-invalid");
        }
        if (req.data.type === "success") {
          setshowexams("is-valid");
          return;
        }
        setshowexams("is-invalid");
      }
    } catch ({ message }) {
      swal("error", message, undefined, false);
    }
  };

  const _storeca = async (e) => {
    if (e.key === "Enter") {
      if (ca > 30) {
        swal("info", "Sorry, C. A. score cannot be above 30", undefined, false);
        return;
      }

      const req = await axiospost(`ms_course_registration/ca`, {
        registration: _id,
        cascore: ca,
      });
      if (req.status !== 200) {
        setshowca("is-invalid");
      }
      if (req.data.type === "success") {
        setshowca("is-valid");
        return;
      }
      setshowca("is-invalid");
    }
  };

  return (
    <tr>
      <th>{sn}</th>
      <td>
        <img src={info.photo} alt="Student" />
      </td>
      <td>
        <ResultChanges info={info} />
      </td>
      <td>{info.name}</td>
      <td className="form-group mw-20 text-center">
        <input
          step={2}
          type="number"
          max={30}
          onChange={(e) => setca(e.target.value)}
          value={ca}
          onKeyUp={_storeca}
          className={`form-control ${showca}`}
          placeholder="C. A. "
        />
      </td>
      <td className="form-group mw-20 text-center ">
        <input
          step={2}
          type="number"
          value={exams}
          max={70}
          onChange={(e) => setexams(e.target.value)}
          onKeyUp={_storeexams}
          className={`form-control ${showexams}`}
          placeholder="Exams "
        />
      </td>
      <td>{total}</td>
      <td>{is_grade(total)}</td>
    </tr>
  );
};

export default EnterAcademicResult;
