import React from "react";
import ReactModal from "react-modal";
import { contextapi } from "../../helpers/contextapi";
import { axiospost, axiosput } from "../../helpers/httprequest";
import is_null from "../../helpers/is_null";
import swal from "../../helpers/swal";

const BtnCourseContact = ({ modules }) => {
  const [showmodal, setshowmodal] = React.useState(false);
  const [date, setdate] = React.useState(
    modules ? String(modules.date).split("T")[0] : ""
  );
  const [name, setname] = React.useState(modules ? modules.label : "");
  const context = React.useContext(contextapi);
  const [loading, setloading] = React.useState(false);
  const { classroomCourse } = context.values;
  const _save = async () => {
    try {
      setloading(true);
      if (is_null(date) || is_null(name)) {
        swal("error", "Please fill the form properly", setloading);
        return;
      }
      let req;
      if (modules !== undefined) {
        const { _id } = modules;
        req = await axiosput(`ms_course_contact/${_id}`, {
          name,
          date,
          course: classroomCourse,
        });
      } else {
        req = await axiospost("ms_course_contact", {
          name,
          date,
          course: classroomCourse,
        });
      }

      if (req.status !== 200)
        throw new Error("Sorry something went wrong, please try again");
      const { type, message } = req.data;

      if (type === "success") {
        swal(type, message, setloading, true);
        return;
      }
      swal(type, message, setloading, false);
    } catch ({ message }) {
      swal("error", message, setloading);
    }
  };

  const _close = () => {
    setshowmodal(false);
  };
  const _show = () => {
    setshowmodal(true);
  };

  return (
    <div>
      <ReactModal
        isOpen={showmodal}
        onRequestClose={() => setshowmodal(false)}
        className="ReactModal"
      >
        <div className="modal-dialog " role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-center">
                {modules
                  ? `Update ${modules.name}`
                  : "Create New Lecture Contact"}
              </h5>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <label htmlFor="exampleInputEmail2">
                  Contact Name (eg Contact 1, Week 1, Lecture 1... etc)
                </label>
                <input
                  type="email"
                  className="form-control form-control-lg"
                  id="exampleInputEmail2"
                  onChange={(e) => setname(e.target.value)}
                  placeholder="Contact Name (eg Contact 1, Week 1, Lecture 1... etc)"
                  value={name}
                />
              </div>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Pick Contact Date</label>
                <input
                  type="date"
                  autoFocus
                  onChange={(e) => setdate(e.target.value)}
                  className="form-control form-control-lg"
                  id="exampleInputEmail1"
                  placeholder="Pick Contact Date"
                  value={date}
                />
              </div>
            </div>
            <div className="modal-footer">
              {loading ? (
                <button type="button" className="btn btn-primary">
                  Please wait...
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={_save}
                >
                  Save changes
                </button>
              )}
              <button type="button" className="btn btn-danger" onClick={_close}>
                Close
              </button>
            </div>
          </div>
        </div>
      </ReactModal>

      <div>
        {!modules && (
          <div className="dropdown static_add_button">
            <button
              type="button"
              className="btn btn-icon btn-primary btn-rounded "
              onClick={_show}
            >
              <i className="ti-plus"></i>
            </button>
          </div>
        )}
      </div>
      <div>
        {modules && (
          <i onClick={_show} className="ti-pencil pointer text-primary"></i>
        )}
      </div>
    </div>
  );
};

export default BtnCourseContact;
