import React from "react";
import TblIndividualPermission from "../../tables/TblIndividualPermission";

const BtnIndividualPermission = ({ staff }) => {
  const [showmodal, setshowmodal] = React.useState("none");

  const _close = () => {
    setshowmodal("none");
  };
  const _show = () => {
    setshowmodal("block");
  };

  return (
    <div>
      <div
        className="modal fade show"
        id="defaultModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        style={{ display: `${showmodal}` }}
        aria-hidden="true"
        role="dialog"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-center">
                Manage {staff.firstname}'s Permission
              </h5>
            </div>
            <div className="modal-body">
              <TblIndividualPermission staff={staff} />
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-danger" onClick={_close}>
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      <div>
        <button
          className="btn btn-icon btn-primary btn-rounded"
          onClick={_show}
        >
          <i className="ti-eye"></i>
        </button>
      </div>
    </div>
  );
};

export default BtnIndividualPermission;
