import React from "react";
import { axiospost, axiosget } from "../../helpers/httprequest";
import is_null from "../../helpers/is_null";
import swal from "../../helpers/swal";
import SelectStatus from "../../Select/SelectStatus";
import Loader from "../../components/Loader";
import TblApplicationRRR from "../../tables/TblApplicationRRR";

const ApplicantPaymentRRR = () => {
  const [session, setsession] = React.useState();
  const [selected, setselected] = React.useState();
  const type = React.useRef();
  const [dataset, setdataset] = React.useState();
  const [loading, setloading] = React.useState(false);

  React.useEffect(() => {
    (async () => {
      try {
        const req = await axiosget("ms_session");
        if (is_null(req.data?.error) && req.data) {
          const { data } = req;
          const res = data?.map((v) => {
            const active = v.status ? " (Active Semester)" : "";
            return {
              name: `${v.code} | ${v.name} ${active}`,
              value: v._id,
            };
          });
          setsession(res);
        }
      } catch ({ message }) {
        console.warn(message);
      }
    })();
  }, []);

  const _load = async () => {
    try {
      setloading(true);
      const search = type.current.value;
      if (is_null(selected) || is_null(search)) {
        swal("warning", "Please fill the form properly", setloading);
        return;
      }
      let req = await axiospost(`ms_applicant/search`, {
        session: selected,
        search,
      });
      if (req.status !== 200)
        throw new Error("Sorry something went wrong, please try again");
      if (req.data) {
        const { data } = req;
        setdataset(data);
        setloading(false);
      } else {
        swal("error", "Sorry something went wrong, please try again");
      }
      setloading(false);
    } catch ({ message }) {
      swal("error", message, setloading);
    }
  };

  return (
    <>
      <h1 className="text-uppercase">Applicant Payment RRR</h1>
      <hr />
      <div className="row">
        <div className="col-md-5">
          <SelectStatus
            label={"Select Academic Trimester"}
            setpagegroup={setselected}
            options={session}
          />
        </div>
        <div className="col-md-5">
          <div className="form-group">
            <label>
              Enter applicant First Name, Email Address, Phone Number or
              Applicant ID
            </label>
            <input
              type="text"
              className="form-control input-lg"
              ref={type}
              placeholder="Enter applicant First Name, Email Address, Phone Number or Applicant ID"
            />
          </div>
        </div>
        <div className="col-md-2 mt-4">
          <button
            type="button"
            className="btn btn-block  btn-lg btn-primary "
            onClick={_load}
          >
            Fetch
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          {!dataset && loading && <Loader />}
          {(dataset || !loading) && (
            <>
              <TblApplicationRRR dataset={dataset} tableloading={loading} />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ApplicantPaymentRRR;
