import React from "react";
import { axiosget, axiospost, axiosput } from "../../helpers/httprequest";
import is_null from "../../helpers/is_null";
import swal from "../../helpers/swal";
import Modal from "react-modal";
import SelectStatus from "../../Select/SelectStatus";

const BtnModulesPrerequisite = ({ modules, current }) => {
  const [showmodal, setshowmodal] = React.useState(false);
  const [loading, setloading] = React.useState(false);
  const [programme, setprogramme] = React.useState();
  const [prereqs, setprereqs] = React.useState();

  React.useEffect(() => {
    const { prerequisite } = current;
    (async () => {
      if (!is_null(prerequisite)) {
        console.log("current", prerequisite, current);
        const req = await axiosget(`ms_course/prereq/${prerequisite}`);
        console.log("prerequisite", req.data);
        if (req.data) {
          console.log("Prerequisite", req.data);
          setprereqs(req.data);
        }
      }
    })();
  }, []);

  const _save = async () => {
    try {
      setloading(true);

      if (is_null(programme)) {
        swal("error", "Please fill the form properly", setloading);
        return;
      }
      if (programme === current._id) {
        swal(
          "error",
          "A module cannot be a prerequisite to itself",
          setloading
        );
        return;
      }
      const req = await axiospost(`ms_course/prerequisite`, {
        id: current._id,
        course: programme,
      });

      if (req.status !== 200)
        throw new Error("Sorry something went wrong, please try again");
      const { type, message } = req.data;

      if (type === "success") {
        swal(type, message, setloading, true);
        setshowmodal(false);
      } else {
        swal(type, message, setloading);
      }
    } catch ({ message }) {
      swal("error", message, setloading);
    }
  };

  const data = modules.map((r) => ({
    name: `${r.code} ${r.name}`,
    value: r._id,
  }));

  return (
    <div title={prereqs && `${prereqs.code} ${prereqs.name}`}>
      <Modal
        isOpen={showmodal}
        onRequestClose={() => setshowmodal(false)}
        className="ReactModal"
      >
        <div className="modal-dialog " role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-center text-uppercase">
                Assign{" "}
                <b className="text-success">
                  {current && `${current.code} ${current.name}`}
                </b>{" "}
                A Prerequisite
              </h5>
            </div>
            <div className="modal-body">
              {data && (
                <SelectStatus
                  setpagegroup={setprogramme}
                  options={data}
                  label="Select Module's Prerequite Programme"
                  active={undefined}
                />
              )}
            </div>
            <div className="modal-footer">
              {loading ? (
                <button type="button" className="btn btn-primary">
                  Please wait...
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={_save}
                >
                  Save changes
                </button>
              )}
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => setshowmodal(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <div>
        {!modules && (
          <div
            style={{
              position: "absolute",
              right: "50px",
              bottom: "50px",
            }}
          >
            <button
              className="btn btn-icon btn-primary btn-rounded"
              onClick={() => setshowmodal(true)}
            >
              <i className="ti-plus"></i>
            </button>
          </div>
        )}
      </div>
      <div>
        {modules && (
          <button
            className="btn btn-icon btn-primary btn-rounded"
            onClick={() => setshowmodal(true)}
          >
            <i className="ti-check-box"></i>
          </button>
        )}
      </div>
    </div>
  );
};

export default BtnModulesPrerequisite;
