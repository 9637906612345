import React from "react";
import ReactModal from "react-modal";
import { axiosget, axiospost, axiosput } from "../../helpers/httprequest";
import is_null from "../../helpers/is_null";
import swal from "../../helpers/swal";
import SelectStatus from "../../Select/SelectStatus";
import { contextapi } from "../../helpers/contextapi";
import axios from "axios";

const BtnClassPostFile = ({ modules }) => {
  const [showmodal, setshowmodal] = React.useState(false);
  const [code, setcode] = React.useState(modules ? modules.code : "");
  const [name, setname] = React.useState(modules ? modules.label : "");
  const [topic, settopic] = React.useState(modules ? modules.topic : "");
  const [post, setpost] = React.useState(modules ? modules.description : "");
  const [doc, setdoc] = React.useState("");
  const [status, setstatus] = React.useState(modules ? modules.status : "");
  const [loading, setloading] = React.useState(false);

  const context = React.useContext(contextapi);
  const { classroomCourse } = context.values;

  const _save = async () => {
    try {
      setloading(true);
      if (is_null(topic) || is_null(status) || is_null(name) || is_null(post)) {
        swal("error", "Please fill the form properly", setloading);
        return;
      }
      if (is_null(doc) && is_null(modules)) {
        throw new Error("Sorry, you need to upload a file");
      }
      let file;
      if (!is_null(doc)) {
        const fd = new FormData();
        fd.append("photo", doc);
        const upload = await axios.post(
          "http://localhost/fileuploads/doc.php",
          fd
        );

        if (!is_null(upload?.data)) {
          if (upload.data?.type === "error") {
            throw new Error(upload.data?.message);
          }
        }
        file = upload.data;
      } else {
        file = modules.file;
      }
      let req;
      if (modules !== undefined) {
        const { _id } = modules;
        req = await axiosput(`ms_course_materials/${_id}`, {
          label: name,
          topic,
          post,
          file: file,
          type: "book",
          status,
        });
      } else {
        req = await axiospost("ms_course_materials", {
          label: name,
          topic,
          file: file,
          type: "book",
          post,
          status,
        });
      }

      if (req.status !== 200)
        throw new Error("Sorry something went wrong, please try again");
      const { type, message } = req.data;

      if (type === "success") {
        swal(type, message, setloading, true);
        return;
      }
      swal(type, message, setloading, false);
    } catch ({ message }) {
      swal("error", message, setloading);
    }
  };

  React.useEffect(() => {
    (async () => {
      try {
        const req = await axiosget(`ms_course_topic/${classroomCourse}`);
        if (req.data?.type) {
          throw new Error(req.data?.message);
        }
        const data = req?.data
          ?.map((v) => ({ name: v.label, value: v._id }))
          .sort((a, b) => a - b);
        setcode(data);
      } catch ({ message }) {
        console.warn(message);
      }
      setloading(false);
    })();
  }, []);
  const _close = () => {
    setshowmodal(false);
  };
  const _show = () => {
    setshowmodal(true);
  };

  return (
    <div>
      <ReactModal
        isOpen={showmodal}
        onRequestClose={() => setshowmodal(false)}
        className="ReactModal"
      >
        <div className="modal-dialog " role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-center">
                {modules ? `Update ${modules?.label}` : "Upload Lecture Note"}
              </h5>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-6">
                  <SelectStatus
                    setpagegroup={settopic}
                    options={code}
                    label="Select Post Topic"
                    active={topic}
                  />
                </div>
                <div className="col-md-6">
                  <SelectStatus
                    setpagegroup={setstatus}
                    options={[
                      {
                        name: "Active (means students can see the post)",
                        value: true,
                      },
                      {
                        name: "Inactive (means students cannot see the post)",
                        value: false,
                      },
                    ]}
                    label="Select Post Status"
                    active={status}
                  />
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="exampleInputEmail2">Post Title</label>
                <input
                  type="email"
                  className="form-control form-control-lg"
                  id="exampleInputEmail2"
                  onChange={(e) => setname(e.target.value)}
                  placeholder="Post Title "
                  value={name}
                />
              </div>
              <div className="form-group">
                <label htmlFor="exampleInputEmail2">
                  Select File to Upload
                </label>
                <input
                  type="file"
                  className="form-control form-control-lg"
                  id="exampleInputEmail2"
                  onChange={(e) => setdoc(e.target.files[0])}
                />
              </div>

              <div className="form-group">
                <label htmlFor="exampleInputEmail2">Class Post</label>
                <textarea
                  type="email"
                  className="form-control form-control-lg"
                  id="exampleInputEmail2"
                  rows={5}
                  onChange={(e) => setpost(e.target.value)}
                  placeholder="Class Post"
                  value={post}
                />
              </div>
            </div>
            <div className="modal-footer">
              {loading ? (
                <button type="button" className="btn btn-primary">
                  Please wait...
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={_save}
                >
                  Save changes
                </button>
              )}
              <button type="button" className="btn btn-danger" onClick={_close}>
                Close
              </button>
            </div>
          </div>
        </div>
      </ReactModal>

      {!modules && (
        <div className="dropdown-item" onClick={_show}>
          <span className="icon-file"></span> Upload Document
        </div>
      )}
      <div>
        {modules && <span onClick={_show} className="ti-pencil "></span>}
      </div>
    </div>
  );
};

export default BtnClassPostFile;
