import React from "react";

const FetchStudentForm = ({ loading, setstudentid, _fetch }) => {
  return (
    <div className="row">
      <div className="col-md-8">
        <div className="form-group">
          <label htmlFor="studentid">
            Student ID <b className="text-danger">*</b>
          </label>
          <input
            className="form-control form-control-lg"
            id="studentid"
            required
            placeholder="Student ID*"
            onChange={(e) => setstudentid(e.target.value)}
          />
        </div>
      </div>
      <div className="col-md-4 pt-4">
        {loading ? (
          <button type="button" className="btn btn-primary btn-block btn-lg">
            Please wait...
          </button>
        ) : (
          <button
            type="button"
            className="btn btn-primary btn-block btn-lg"
            onClick={_fetch}
          >
            Fetch Student
          </button>
        )}
      </div>
    </div>
  );
};

export default FetchStudentForm;
