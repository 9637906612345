import React from "react";
import ReactModal from "react-modal";

const ExtractPhoneNumber = ({ data }) => {
  const [showmodal, setshowmodal] = React.useState(false);
  const [set, setset] = React.useState(null);
  const [loading, setloading] = React.useState(false);

  React.useEffect(() => {
    setloading(true);
    try {
      if (showmodal) {
        (async () => {
          const emails = await Promise.all(
            data?.map((r) => {
              if (r?.result !== undefined) {
                console.log(r);
                const { phonenumber } = r?.result;
                return phonenumber;
              } else {
                return "";
              }
            })
          );
          setset(emails);
        })();
      }
    } catch ({ message }) {}
    setloading(false);
  }, [showmodal]);
  const _close = () => {
    setshowmodal(false);
  };
  return (
    <div>
      <ReactModal
        isOpen={showmodal}
        className="ReactModal"
        onRequestClose={_close}
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-center text-uppercase">
                Extract Email Addresses
              </h5>
              <span
                type="button"
                className="pointer text-danger"
                onClick={_close}
              >
                &times;
              </span>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  {set && set?.map((r) => <span>{`${r}  `}</span>)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </ReactModal>

      <i
        className="fa fa-phone text-primary"
        onClick={() => setshowmodal(true)}
        style={{
          cursor: "pointer",
          fontSize: "20px",
          paddingLeft: 10,
          paddingRight: 10,
        }}
      ></i>
    </div>
  );
};

export default ExtractPhoneNumber;
