import React from "react";
import FetchStudentForm from "../../components/FetchStudentFOrm";
import Alerts from "../../helpers/Alerts";
import TblStudentProgression from "../../tables/TblStudentProgression";
import is_null from "../../helpers/is_null";
import { axiosget } from "../../helpers/httprequest";
import swal from "../../helpers/swal";

const IndividualProgression = () => {
  const [studentid, setstudentid] = React.useState(null);
  const [loading, setloading] = React.useState(false);
  const [bio, setbio] = React.useState(undefined);
  const [showerror, setshowerror] = React.useState(false);
  const _fetch = async () => {
    try {
      setloading(true);
      if (is_null(studentid)) {
        throw new Error("Please enter Student ID");
      }
      const req = await axiosget(`ms_students/student/${studentid}`);
      if (!is_null(req.data?.type)) {
        throw new Error(req.data?.message);
      }
      if (req.data.bio?.length) {
        const { bio: bios } = req.data;
        setbio(bios);
        setshowerror(false);
      } else {
        setshowerror(true);
      }
      setloading(false);
    } catch ({ message }) {
      swal("error", message, setloading, false);
    }
  };
  return (
    <div className="col-12">
      <FetchStudentForm
        loading={loading}
        _fetch={_fetch}
        setstudentid={setstudentid}
      />
      <div className="row">
        <div className="col-md-12 table-responsive">
          <>
            {showerror && (
              <Alerts
                message="Sorry, no record to show at the moment"
                title="NO RECORD FOUND"
                type="danger"
              />
            )}
          </>
          <>{bio && !showerror && <TblStudentProgression bio={bio} />}</>
        </div>
      </div>
    </div>
  );
};

export default IndividualProgression;
