import React from "react";
import BtnCourseContact from "../../Buttons/BtnCourseContact";
import ClassroomNavbar from "../../components/ClassroomNavbar";
import ContactAttended from "../../components/ContactAttended";
import DeleteContact from "../../components/DeleteContact";
import Loader from "../../components/Loader";
import MarkAttendance from "../../components/MarkAttendance";
import { contextapi } from "../../helpers/contextapi";
import { axiosget } from "../../helpers/httprequest";

const CourseContact = () => {
  const [data, setdata] = React.useState(undefined);
  const [course, setcourse] = React.useState(undefined);
  const [expect, setexpect] = React.useState(0);
  const [loading, setloading] = React.useState(undefined);
  const [registration, setregistration] = React.useState(undefined);
  const context = React.useContext(contextapi);
  const { classroomCourse } = context.values;
  React.useEffect(() => {
    (async () => {
      setloading(true);
      try {
        const req = await axiosget(`ms_course_contact/${classroomCourse}`);
        if (req.data?.type) {
          throw new Error(req.data?.message);
        }
        const { find, course: list, expected, regs } = req.data;
        setdata(find);
        setcourse(list);
        setexpect(expected);
        setregistration(regs);
        setloading(false);
      } catch ({ message }) {
        console.warn(message);
      }
      setloading(false);
    })();
    // eslint-disable-next-line
  }, []);
  return (
    <div>
      <ClassroomNavbar />
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="main-panel classroom-content w-100">
            <div className="container">
              <div className="row">
                <div className="col-md-12 pt-3 grid-margin stretch-card">
                  <div className="card">
                    <div className="card-body">
                      <div className="mb-5">
                        <h1 className="p1">{module?.code}</h1>
                        <h2>Lecturer & Student's Contact</h2>
                        <div className="table-responsive">
                          <table
                            id="example"
                            className="table table-hover table-striped display expandable-table w-100"
                          >
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Code</th>
                                <th>Contact Name</th>
                                <th>Date</th>
                                <th>Attended</th>
                                <th>Expected</th>
                                <th>Mark</th>
                                <th>Edit</th>
                                <th>Delete</th>
                              </tr>
                            </thead>
                            <tbody>
                              {data?.map((v, i) => (
                                <tr key={i}>
                                  <th>{i + 1}</th>
                                  <td>{course.code}</td>
                                  <td>{v.label}</td>
                                  <td>{String(v.date).split("T")[0]}</td>
                                  <td>
                                    <ContactAttended contact={v._id} />
                                  </td>
                                  <td>{expect}</td>
                                  <td>
                                    <MarkAttendance
                                      contact={v._id}
                                      regs={registration}
                                      cname={v.label}
                                    />
                                  </td>
                                  <td>
                                    <BtnCourseContact modules={v} />
                                  </td>
                                  <td>
                                    <DeleteContact
                                      contact={v._id}
                                      modules={v}
                                    />
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <BtnCourseContact />
        </>
      )}
    </div>
  );
};

export default CourseContact;
