import React from "react";
import Loader from "../../components/Loader";
import moment from "../../helpers/moment";
import BtnApplicationRRR from "../../Buttons/BtnApplicationRRR";

const TblApplicationRRR = ({ dataset, tableloading }) => {
  return (
    <>
      {tableloading ? (
        <Loader />
      ) : (
        <table
          id="example"
          className="table table-hover table-striped display expandable-table w-100"
        >
          <thead>
            <tr>
              <th>#</th>
              <th>Code</th>
              <th>Name</th>
              <th>Email Address</th>
              <th>Phone Number</th>
              <th>View RRR</th>
              <th>Applied On</th>
            </tr>
          </thead>
          <tbody>
            {dataset?.map((v, i) => (
              <tr key={i}>
                <th>{i + 1}</th>
                <td>{v.code}</td>
                <td>
                  {v.firstname} {v.middlename} {v.lastname}
                </td>
                <td>{v.emailaddress}</td>
                <td>{v.phonenumber}</td>

                <td>
                  <BtnApplicationRRR modules={v} key={i} />
                </td>

                <td>{moment(v.updatedAt)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </>
  );
};

export default TblApplicationRRR;
