import React from "react";
import Loader from "../../components/Loader";
import moment from "../../helpers/moment";
import BtnApplications from "../../Buttons/BtnApplications";
import BtnEditApplicant from "../../Buttons/BtnEditApplicant";
import is_table from "../../helpers/is_table";

const TblApplications = ({ dataset, tableloading }) => {
  const _status = (status) => {
    let res;
    switch (status) {
      case 0:
        res = "Pending";
        break;
      case 1:
        res = "Provisional Admission";
        break;
      case 2:
        res = "Conditional Admission";
        break;
      case 3:
        res = "Rejected";
        break;
    }
    return res;
  };
  is_table();
  return (
    <>
      {tableloading ? (
        <Loader />
      ) : (
        <table
          id="example"
          className="table table-hover table-striped display expandable-table w-100"
        >
          <thead>
            <tr>
              <th>#</th>
              <th>Code</th>
              <th>Name</th>
              <th>Programme</th>
              <th>Status</th>
              <th className="text-center">Edit </th>
              <th className="text-center">View </th>

              <th>Applied On</th>
            </tr>
          </thead>
          <tbody>
            {dataset?.map((v, i) => (
              <tr key={i}>
                <th>{i + 1}</th>
                <td>{v.code}</td>
                <td className="text-capitalize">
                  {`${v.firstname} ${v.middlename} ${v.lastname}`.toLocaleLowerCase()}
                </td>
                <td>
                  {v.progcode} :: {v.progname}
                </td>
                <td>{_status(v.status)}</td>
                <td className="text-center">
                  <BtnEditApplicant modules={v} key={`editapplicant_${i}`} />
                </td>
                <td className="text-center">
                  <BtnApplications modules={v} key={i} />
                </td>
                <td>{moment(v.updatedAt)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </>
  );
};

export default TblApplications;
