import React from "react";
import { axiosget } from "../../helpers/httprequest";
import is_null from "../../helpers/is_null";
import swal from "../../helpers/swal";
import TblApplications from "../../tables/TblApplications";
import SelectStatus from "../../Select/SelectStatus";

const Applications = () => {
  const [session, setsession] = React.useState();
  const [selected, setselected] = React.useState();
  const [type, settype] = React.useState();
  const [dataset, setdataset] = React.useState();
  const [loading, setloading] = React.useState();

  React.useEffect(() => {
    (async () => {
      try {
        const req = await axiosget("ms_session");
        if (is_null(req.data?.error) && req.data) {
          const { data } = req;
          const res = data?.map((v) => {
            const active = v.status ? " (Active Semester)" : "";
            return {
              name: `${v.code} | ${v.name} ${active}`,
              value: v._id,
            };
          });
          setsession(res);
        }
      } catch ({ message }) {
        console.warn(message);
      }
    })();
  }, []);

  const _load = async () => {
    try {
      setloading(true);
      if (is_null(selected) || is_null(type)) {
        swal("warning", "Please fill the form properly", setloading);
        return;
      }
      let req = await axiosget(`ms_applications/admission/${selected}/${type}`);
      if (req.status !== 200)
        throw new Error("Sorry something went wrong, please try again");
      if (req.data?.response) {
        const { response } = req.data;
        setdataset(response);
        setloading(false);
      } else {
        swal("error", "Sorry something went wrong, please try again");
      }
      setloading(false);
    } catch ({ message }) {
      swal("error", message, setloading);
    }
  };

  return (
    <>
      <h4>Manage Applications</h4>
      <div className="row">
        <div className="col-md-4">
          <SelectStatus
            label={"Select Academic Semester"}
            setpagegroup={setselected}
            options={session}
          />
        </div>
        <div className="col-md-4">
          <SelectStatus
            label={"Select Application Status"}
            setpagegroup={settype}
            options={[
              { name: "All Applications", value: "all" },
              { name: "Approved Applications", value: "approved" },
              { name: "Pending Applications", value: "pending" },
              { name: "Rejected Applications", value: "rejected" },
            ]}
          />
        </div>
        <div className="col-md-4 mt-4">
          <button
            type="button"
            className="btn btn-block  btn-lg btn-primary "
            onClick={_load}
          >
            Fetch Records
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          {(dataset || loading) && (
            <TblApplications dataset={dataset} tableloading={loading} />
          )}
        </div>
      </div>
    </>
  );
};

export default Applications;
