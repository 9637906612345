import React from "react";
import Loader from "../../components/Loader";
import { axiosget } from "../../helpers/httprequest";
import swal from "../../helpers/swal";
import moment from "../../helpers/moment";
import BtnUserGroup from "../../Buttons/BtnUserGroup";
import BtnUserGroupPermission from "../../Buttons/BtnUserGroupPermission";
import BtnGroupMembers from "../../Buttons/BtnGroupMembers";
import DelUserGroup from "../../Buttons/DelUserGroup";

const TblUserGroup = () => {
  const [loading, setloading] = React.useState(true);
  const [data, setdata] = React.useState(null);
  React.useEffect(() => {
    (async () => {
      try {
        const req = await axiosget("ms_user_groups");
        setdata(req.data);
        setloading(false);
      } catch ({ message }) {
        swal("error", message, setloading);
      }
    })();
  }, []);
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <table
          id="example"
          className="table table-hover table-striped display expandable-table w-100"
        >
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Group Members</th>
              <th>Group Permission</th>
              <th>Edit</th>
              <th>Delete</th>
              <th>Created At</th>
              <th>Updated At</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((v, i) => (
              <tr key={i}>
                <th>{i + 1}</th>
                <td>{v.name}</td>
                <td>
                  <BtnGroupMembers modules={v} key={i} />
                </td>
                <td>
                  <BtnUserGroupPermission modules={v} key={i} />
                </td>
                <td>
                  <BtnUserGroup modules={v} key={i} />
                </td>
                <td>
                  <DelUserGroup modules={v} key={i} />
                </td>
                <td>{moment(v.createdAt)}</td>
                <td>{moment(v.updatedAt)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </>
  );
};

export default TblUserGroup;
