import React from "react";
import { axiosget } from "../../helpers/httprequest";

const ContactAttended = ({ contact }) => {
  const [loading, setloading] = React.useState(true);
  const [data, setdata] = React.useState(0);
  React.useEffect(() => {
    (async () => {
      try {
        const req = await axiosget(`ms_course_attendance/contact/${contact}`);
        if (req.data?.type) {
          throw new Error(req.data?.message);
        }
        setdata(req.data.length);
      } catch ({ message }) {
        console.warn(message);
      }
      setloading(false);
    })();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <div>{data}</div>;
};

export default ContactAttended;
