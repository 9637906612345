import React from "react";
import TblDepartmentHead from "../../tables/TblDepartmentHead";
import BtnDepartmentHead from "../../Buttons/BtnDepartmentHead";

const DepartmentHead = () => {
  return (
    <>
      <TblDepartmentHead />

      <BtnDepartmentHead />
    </>
  );
};

export default DepartmentHead;
