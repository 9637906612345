import React from "react";
import Loader from "../../components/Loader";
import moment from "../../helpers/moment";
import BtnEditRequirement from "../../Buttons/BtnEditRequirement";
import DelRequirement from "../../Buttons/DelRequirement";

const TblProgAdmissionRequirement = ({ dataset, tableloading }) => {
  return (
    <>
      {tableloading ? (
        <Loader />
      ) : (
        <table
          id="example"
          className="table table-hover table-striped display expandable-table w-100"
        >
          <thead>
            <tr>
              <th>#</th>
              <th>Label</th>
              <th>Name</th>
              <th>Status</th>
              <th>Required</th>
              <th>Edit</th>
              <th>Delete</th>
              <th>Created At</th>
              <th>Updated At</th>
            </tr>
          </thead>
          <tbody>
            {dataset?.map((v, i) => (
              <tr key={i}>
                <th>{i + 1}</th>
                <td>{v.label}</td>
                <td>{v.name}</td>
                <td>{v.status ? "Active" : "In active"}</td>
                <td>{v.required ? "Yes" : "No"}</td>
                <td>
                  <BtnEditRequirement modules={v} key={i} />
                </td>
                <td>
                  <DelRequirement modules={v} key={i} />
                </td>
                <td>{moment(v.createdAt)}</td>
                <td>{moment(v.updatedAt)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </>
  );
};

export default TblProgAdmissionRequirement;
