import React from "react";
import BtnCourseContact from "../../Buttons/BtnCourseContact";
import ClassroomNavbar from "../../components/ClassroomNavbar";
import Loader from "../../components/Loader";
import EnterAcademicResult from "../../components/EnterAcademicResult";
import { contextapi } from "../../helpers/contextapi";
import { axiosget } from "../../helpers/httprequest";

const ClassResult = () => {
  const [data, setdata] = React.useState(undefined);
  const [course, setcourse] = React.useState(undefined);
  const [expect, setexpect] = React.useState(0);
  const [loading, setloading] = React.useState(undefined);
  const [registration, setregistration] = React.useState(undefined);
  const context = React.useContext(contextapi);
  const { classroomCourse } = context.values;
  React.useEffect(() => {
    (async () => {
      setloading(true);
      try {
        const req = await axiosget(`ms_course_contact/${classroomCourse}`);
        if (req.data?.type) {
          throw new Error(req.data?.message);
        }
        const { find, course: list, expected, regs } = req.data;
        setdata(find);
        setcourse(list);
        setexpect(expected);
        setregistration(regs);
        setloading(false);
      } catch ({ message }) {
        console.warn(message);
      }
      setloading(false);
    })();
    // eslint-disable-next-line
  }, []);
  return (
    <div>
      <ClassroomNavbar />
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="main-panel classroom-content w-100">
            <div className="container">
              <div className="row">
                <div className="col-md-12 pt-3 grid-margin stretch-card">
                  <div className="card">
                    <div className="card-body">
                      <div className="mb-5">
                        <h1 className="p1">{module?.code}</h1>
                        <h2>Course Result</h2>
                        <div className="table-responsive">
                          <table className="table table-hover table-bordered">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Photo</th>
                                <th>Student ID</th>
                                <th>Full Name</th>
                                <th>C. A (30 Marks)</th>
                                <th>Exams (70 Marks)</th>
                                <th>Total</th>
                                <th>Grade</th>
                              </tr>
                            </thead>
                            <tbody>
                              {registration?.map((v, i) => {
                                const sn = i + 1;
                                return <EnterAcademicResult info={v} sn={sn} />;
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ClassResult;
