import React from "react";

const StudentCardProfile = ({ student }) => {
  return (
    <div className="row">
      <div className="col-md-12 transparent">
        <div className="card">
          <div className="card-body">
            <div className="container">
              <div className="row">
                <div className="col-md-2">
                  <div className="pr-5">
                    <img
                      src={student?.photo?.fulllink}
                      className="w-100 br-10"
                      alt="passport"
                    />
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="row ">
                    <div className="col-md-4 text-muted text-uppercase ">
                      Student ID
                    </div>
                    <div className="col-md-8 text-uppercase">
                      {student?.studentid}
                    </div>
                  </div>
                  <div className="row my-2 ">
                    <div className="col-md-4 text-muted text-uppercase">
                      Full Name
                    </div>
                    <div className="col-md-8 text-uppercase">
                      {student?.firstname} {student?.middlename}{" "}
                      {student?.lastname}
                    </div>
                  </div>
                  <div className="row my-2 ">
                    <div className="col-md-4 text-muted text-uppercase">
                      Student Level
                    </div>
                    <div className="col-md-8 text-uppercase">
                      {student?.level}
                    </div>
                  </div>
                  <div className="row my-2 ">
                    <div className="col-md-4 text-muted text-uppercase">
                      Semester
                    </div>
                    <div className="col-md-8 text-uppercase">
                      {student?.semester}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentCardProfile;
