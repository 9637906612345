import React from "react";
import ReactModal from "react-modal";
import { axiosget, axiospost } from "../../helpers/httprequest";
import is_null from "../../helpers/is_null";
import swal from "../../helpers/swal";
import SelectStatus from "../../Select/SelectStatus";

const BtnRegistrationSettings = ({ modules }) => {
  const [showmodal, setshowmodal] = React.useState(false);
  const [start, setstart] = React.useState("");
  const [end, setend] = React.useState("");
  const [late, setlate] = React.useState("");
  const [fee, setfee] = React.useState("");
  const [hodapproval, sethodapproval] = React.useState(undefined);
  const [hodapprovaldata, sethodapprovaldata] = React.useState(undefined);
  const [loading, setloading] = React.useState(false);
  const _save = async () => {
    try {
      setloading(true);
      if (
        is_null(start) ||
        is_null(end) ||
        is_null(late) ||
        is_null(fee) ||
        is_null(hodapproval)
      ) {
        swal("error", "Please fill the form properly", setloading);
        return;
      }
      const req = await axiospost("ms_set_registration", {
        trimester: modules._id,
        start,
        end,
        late,
        fee,
        hodapproval,
      });

      if (req.status !== 200)
        throw new Error("Sorry something went wrong, please try again");
      const { type, message } = req.data;
      if (type === "success") {
        swal(type, message, setloading, true);
        return;
      }
      swal(type, message, setloading, false);
    } catch ({ message }) {
      swal("error", message, setloading);
    }
  };

  const _close = () => {
    setshowmodal(false);
  };
  const _show = () => {
    setshowmodal(true);
  };
  React.useEffect(() => {
    (async () => {
      try {
        const req = await axiosget(`ms_set_registration/${modules._id}`);
        if (req.data?.type) {
          throw new Error(req.data?.message);
        }
        const { start, end, late, charges: fee, hodapproval } = req.data;
        setstart(String(start).split("T")[0]);
        setend(String(end).split("T")[0]);
        setlate(String(late).split("T")[0]);
        setfee(fee);
        sethodapprovaldata(hodapproval);
      } catch ({ message }) {
        console.warn(message);
      }
      setloading(false);
    })();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <ReactModal
        isOpen={showmodal}
        className="ReactModal"
        onRequestClose={_close}
      >
        <div className="modal-dialog " role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-center">
                {modules.name} Registration Settings
              </h5>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail2">
                      Registration Start Date
                    </label>
                    <input
                      type="date"
                      className="form-control form-control-lg"
                      id="exampleInputEmail2"
                      maxLength={3}
                      onChange={(e) => setstart(e.target.value)}
                      placeholder="Registration Start Date"
                      value={start}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">
                      Registration End Date
                    </label>
                    <input
                      type="date"
                      autoFocus
                      onChange={(e) => setend(e.target.value)}
                      className="form-control form-control-lg"
                      id="exampleInputEmail1"
                      placeholder="Registration End Date"
                      value={end}
                    />
                  </div>
                </div>
              </div>{" "}
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">
                      Late Registration End Date
                    </label>
                    <input
                      type="date"
                      autoFocus
                      onChange={(e) => setlate(e.target.value)}
                      className="form-control form-control-lg"
                      id="exampleInputEmail1"
                      placeholder="Late Registration End Date"
                      value={late}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">
                      Late Registration Fee
                    </label>
                    <input
                      type="number"
                      autoFocus
                      onChange={(e) => setfee(e.target.value)}
                      className="form-control form-control-lg"
                      id="exampleInputEmail1"
                      placeholder="Late Registration Fee"
                      value={fee}
                    />
                  </div>
                </div>
              </div>
              <SelectStatus
                setpagegroup={sethodapproval}
                options={[
                  { name: "Yes", value: true },
                  { name: "No", value: false },
                ]}
                active={hodapprovaldata}
                label="Is HOD Approval Required?"
              />
            </div>
            <div className="modal-footer">
              {loading ? (
                <button type="button" className="btn btn-primary">
                  Please wait...
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={_save}
                >
                  Save changes
                </button>
              )}
              <button type="button" className="btn btn-danger" onClick={_close}>
                Close
              </button>
            </div>
          </div>
        </div>
      </ReactModal>

      <div>
        <button
          className="btn btn-icon btn-primary btn-rounded"
          onClick={_show}
        >
          <i className="ti-plus"></i>
        </button>
      </div>
    </div>
  );
};

export default BtnRegistrationSettings;
