import React from "react";
import { axiosdelete } from "../../helpers/httprequest";
import is_null from "../../helpers/is_null";
import swal from "../../helpers/swal";

const DelDepartment = ({ modules }) => {
  const [showmodal, setshowmodal] = React.useState("none");
  const [name, setname] = React.useState(null);
  const [loading, setloading] = React.useState(false);

  const _save = async () => {
    try {
      setloading(true);
      if (is_null(name) || name !== "Delete") {
        swal("error", "Please fill the form properly", setloading);
        return;
      }
      const req = await axiosdelete(`ms_department/${modules._id}`);
      if (req.status !== 200)
        throw new Error("Sorry something went wrong, please try again");
      const { type, message } = req.data;
      swal(type, message, setloading, true);
      if (type === "success") {
        _close();
      }
    } catch ({ message }) {
      swal("error", message, setloading);
    }
  };
  const _close = () => {
    setshowmodal("none");
    setname("");
  };
  const _show = () => {
    setshowmodal("block");
    setname(modules !== undefined ? modules.name : null);
  };

  return (
    <div>
      <div
        className="modal fade show"
        id="defaultModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        style={{ display: `${showmodal}` }}
        aria-hidden="true"
        role="dialog"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-center text-danger">
                Delete {modules.name}
              </h5>
            </div>
            <div className="modal-body">
              <div className="text-wrap my-4 text-md">
                To delete department type the word{" "}
                <b className="text-danger">Delete</b> in the text box, please
                note that when you delete the department it cannot be recovered
                and below are list of what it might affect if records have been
                added:
                <ol>
                  <li>All course registration</li>
                  <li>All results</li>
                  <li>All admissions</li>
                  <li>All payments</li>
                  <li>All attendance</li>
                </ol>
              </div>
              <div className="text-wrap my-4 text-md">
                Due to the strategic importance of department removing the
                record will not be wise as such the record will be disabled!!!
              </div>
              <div className="text-wrap my-4 text-md">
                Make sure you know this is what you want, Even the system
                developer cannot recover the data if deleted. kindly trade with
                caution!!!
              </div>
              <div className="form-group">
                <input
                  type="email"
                  className="form-control form-control-lg"
                  id="exampleInputEmail2"
                  placeholder="Delete"
                  onChange={(e) => setname(e.target.value)}
                />
              </div>
              <div className="modal-footer">
                {loading ? (
                  <button type="button" className="btn btn-primary">
                    Please wait...
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={_save}
                  >
                    Delete Record
                  </button>
                )}

                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={_close}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <button className="btn btn-icon btn-danger btn-rounded" onClick={_show}>
          <i className="ti-trash"></i>
        </button>
      </div>
    </div>
  );
};

export default DelDepartment;
