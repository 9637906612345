import React from "react";
import toastr from "toastr";
import { axiosget } from "../../helpers/httprequest";
import TblModules from "../../tables/TblModules";
import BtnModules from "../../Buttons/BtnModules";

const Modules = () => {
  const [deptlist, setdeptlist] = React.useState(null);
  React.useEffect(() => {
    (async () => {
      try {
        if (deptlist === null) {
          const req = await axiosget("ms_programme/active");
          if (req.data) {
            const list = req.data?.map((v) => ({ name: v.name, value: v._id }));
            setdeptlist(list);
          }
        }
      } catch ({ message }) {
        toastr.warning(message);
      }
    })();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <h4>Manage Academic Courses/Modules</h4>

      <TblModules deptlist={deptlist} />

      <BtnModules deptlist={deptlist} />
    </>
  );
};

export default Modules;
