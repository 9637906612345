import React from "react";
import { axiosget } from "../../helpers/httprequest";
import is_money from "../../helpers/is_money";
import ApplicantOLevelResult from "../ApplicantOLevelResult";
import Loader from "../Loader";

const MainFinal = ({ id, code }) => {
  const [payment, setpayment] = React.useState(undefined);
  const [bioinfo, setbioinfo] = React.useState(undefined);
  const [loading, setloading] = React.useState(true);
  const [documents, setdocuments] = React.useState(undefined);
  const [refereeinfo, setrefereeinfo] = React.useState(undefined);
  const [proginfo, setproginfo] = React.useState(undefined);
  const [seminfo, setseminfo] = React.useState(undefined);

  React.useEffect(() => {
    (async () => {
      try {
        const req = await axiosget(`ms_applications/finaladmin/${id}`);
        if (req.data) {
          console.log(req.data);
          const { bio, referee, paid, docs, prog, sem } = req.data;
          setbioinfo(bio);
          setrefereeinfo(referee);
          setpayment(paid);
          setdocuments(docs);
          setproginfo(prog);
          setseminfo(sem);
        }
      } catch ({ message }) {
        console.log(message);
      }
      setloading(false);
    })();
  }, []);

  return (
    <div className="row">
      <div className="col-md-12 grid-margin transparent">
        {loading ? (
          <Loader />
        ) : (
          <div className="card">
            <div className="card-body">
              <div className="container">
                <div className="fs-60">BIO INFORMATION</div>
                <div className="row">
                  <div className="col-md-4">
                    <div className="pr-5">
                      <img
                        src={bioinfo?.photo.link}
                        className="w-100 br-10"
                        alt="passport"
                      />
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="row bordered_bottom">
                      <div className="col-md-4 text-muted ">Application ID</div>
                      <div className="col-md-8 ">{code}</div>
                    </div>
                    <div className="row my-2 bordered_bottom">
                      <div className="col-md-4 text-muted ">
                        Programme Details
                      </div>
                      <div className="col-md-8 ">
                        {proginfo?.code} :: {proginfo?.name}
                      </div>
                    </div>
                    <div className="row my-2 bordered_bottom">
                      <div className="col-md-4 text-muted">School Semester</div>
                      <div className="col-md-8">
                        {seminfo?.code} :: {seminfo?.name}
                      </div>
                    </div>
                    <div className="row my-2 bordered_bottom">
                      <div className="col-md-4 text-muted">Full Name</div>
                      <div className="col-md-8">
                        {bioinfo?.firstname} {bioinfo?.middlename}{" "}
                        {bioinfo?.lastname}
                      </div>
                    </div>
                    <div className="row my-2 bordered_bottom">
                      <div className="col-md-4 text-muted">Date of Birth</div>
                      <div className="col-md-8">
                        {bioinfo &&
                          new Date(bioinfo?.dateofbirth).toLocaleDateString()}
                      </div>
                    </div>

                    <div className="row my-2 bordered_bottom">
                      <div className="col-md-4 text-muted">Phont Number</div>
                      <div className="col-md-8">{bioinfo?.phonenumber}</div>
                    </div>
                    <div className="row my-2 bordered_bottom">
                      <div className="col-md-4 text-muted">Email Address</div>
                      <div className="col-md-8">{bioinfo?.emailaddress}</div>
                    </div>
                    <div className="row my-2 bordered_bottom">
                      <div className="col-md-4 text-muted">Religion</div>
                      <div className="col-md-8">{bioinfo?.religion}</div>
                    </div>
                    <div className="row my-2 bordered_bottom">
                      <div className="col-md-4 text-muted">Gender</div>
                      <div className="col-md-8">{bioinfo?.gender}</div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="row my-2 bordered_bottom">
                      <div className="col-md-4 text-muted">Marital Status</div>
                      <div className="col-md-8">{bioinfo?.maritalstatus}</div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row my-2 bordered_bottom">
                      <div className="col-md-4 text-muted">Qualification</div>
                      <div className="col-md-8">{bioinfo?.qualification}</div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="row my-2 bordered_bottom">
                      <div className="col-md-4 text-muted">State of Origin</div>
                      <div className="col-md-8">{bioinfo?.stateoforigin}</div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row my-2 bordered_bottom">
                      <div className="col-md-4 text-muted">LGA of Origin</div>
                      <div className="col-md-8">{bioinfo?.lgaoforigin}</div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="row my-2 bordered_bottom">
                      <div className="col-md-4 text-muted">
                        State of Residence
                      </div>
                      <div className="col-md-8">
                        {bioinfo?.stateofresidence}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row my-2 bordered_bottom">
                      <div className="col-md-4 text-muted">
                        LGA of Residence
                      </div>
                      <div className="col-md-8">{bioinfo?.lgaofresidence}</div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="row my-2 bordered_bottom">
                      <div className="col-md-2 text-muted">Home Address</div>
                      <div className="col-md-10">{bioinfo?.homeaddress}</div>
                    </div>
                  </div>
                </div>
                <div className="fs-60">APPLICANT 0-LEVEL RESULT</div>
                <ApplicantOLevelResult applicant={bioinfo} />
                <div className="fs-60">GUARDIAN INFORMATION</div>

                <div className="row">
                  {refereeinfo &&
                    refereeinfo?.map((v, i) => (
                      <div key={`student_full_name${i}`} className="col-md-6 ">
                        <div className="row my-2 bordered_bottom">
                          <div className="col-md-4 text-muted">Full Name</div>
                          <div className="col-md-8 text-capitalize">
                            {`${v.firstname} ${v.middlename} ${v.lastname}`.toLowerCase()}
                          </div>
                        </div>
                        <div className="row my-2 bordered_bottom">
                          <div className="col-md-4 text-muted">
                            Phont Number
                          </div>
                          <div className="col-md-8">{v.phonenumber}</div>
                        </div>
                        <div className="row my-2 bordered_bottom">
                          <div className="col-md-4 text-muted">
                            Email Address
                          </div>
                          <div className="col-md-8">{v.emailaddress}</div>
                        </div>
                        <div className="row my-2 bordered_bottom">
                          <div className="col-md-4 text-muted">
                            Home address
                          </div>
                          <div className="col-md-8">{v.homeaddress}</div>
                        </div>
                      </div>
                    ))}
                </div>

                <div className="fs-60">PAYMENT INFORMATION</div>
                <div className="row">
                  <div className="col-md-6 my-2 bordered_bottom">
                    <div className="row">
                      <div className="col-md-4 text-muted">
                        Reference Number
                      </div>
                      <div className="col-md-8">{payment?.trxcode}</div>
                    </div>
                  </div>
                  <div className="col-md-6 my-2 bordered_bottom">
                    <div className="row">
                      <div className="col-md-4 text-muted">Amount Paid</div>
                      <div className="col-md-8">
                        {is_money(payment?.amount)}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="fs-60">DOCUMENT(S) UPLOADED</div>
                <div className="row">
                  {documents &&
                    documents.map((v, i) => (
                      <div
                        key={`applicant_profile_details_${i}`}
                        className="col-md-12 my-2 bordered_bottom"
                      >
                        <div className="text-muted text-uppercase ">
                          {v.label}
                        </div>
                        <img
                          src={v.uri.link}
                          alt={v.label}
                          className="uploadedDocument"
                        />
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MainFinal;
