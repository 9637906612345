import React from "react";
import ReactModal from "react-modal";
import { axiosdelete } from "../../helpers/httprequest";
import is_null from "../../helpers/is_null";
import swal from "../../helpers/swal";

const DelUserGroup = ({ modules }) => {
  const [showmodal, setshowmodal] = React.useState(false);
  const [name, setname] = React.useState("");
  const [loading, setloading] = React.useState(false);

  const _save = async () => {
    try {
      setloading(true);
      if (is_null(name) || name !== "Delete") {
        swal("error", "Please fill the form properly", setloading);
        return;
      }
      const req = await axiosdelete(`ms_user_groups/${modules._id}`);
      if (req.status !== 200)
        throw new Error("Sorry something went wrong, please try again");
      const { type, message } = req.data;

      if (type === "success") {
        swal(
          type,
          message,
          () => {
            setloading();
            _close();
          },
          true
        );
      } else {
        swal(type, message, setloading, false);
      }
    } catch ({ message }) {
      swal("error", message, setloading);
    }
  };
  const _close = () => {
    setshowmodal(false);
    setname("");
  };
  const _show = () => {
    setshowmodal(true);
    setname(modules !== undefined ? modules.name : "");
  };

  return (
    <div>
      <ReactModal
        isOpen={showmodal}
        onRequestClose={() => setshowmodal(false)}
        className="ReactModal"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-center text-danger">
                Delete {modules.name}
              </h5>
              <span
                type="button"
                className="pointer text-danger"
                onClick={_close}
              >
                &times;
              </span>
            </div>
            <div className="modal-body">
              <div className="text-wrap my-4 text-md">
                To delete this item type the word{" "}
                <b className="text-danger">Delete</b> in the text box, please
                note that when you delete this module it cannot be recovered and
                all the users will loose their permission to this module.
              </div>
              <div className="text-wrap my-4 text-md">
                Make sure you know this is what you want, Even the system
                developer cannot recover the data if deleted. kindly trade with
                caution!!!
              </div>
              <div className="form-group">
                <input
                  type="email"
                  className="form-control form-control-lg"
                  id="exampleInputEmail2"
                  placeholder="Delete"
                  onChange={(e) => setname(e.target.value)}
                />
              </div>
              <div className="modal-footer">
                {loading ? (
                  <button type="button" className="btn btn-primary">
                    Please wait...
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={_save}
                  >
                    Delete Record
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </ReactModal>
      <div>
        <button className="btn btn-icon btn-danger btn-rounded" onClick={_show}>
          <i className="ti-trash"></i>
        </button>
      </div>
    </div>
  );
};

export default DelUserGroup;
