import React from "react";
import ReactModal from "react-modal";
import MarkClassAttendance from "../MarkClassAttendance";

const MarkAttendance = ({ contact, regs, cname }) => {
  const [showmodal, setshowmodal] = React.useState(false);
  return (
    <div>
      <ReactModal
        isOpen={showmodal}
        onRequestClose={() => setshowmodal(false)}
        className="ReactModal"
      >
        <div className="modal-dialog " role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-center text-uppercase ">
                {`Mark Student Attendance for ${cname}`}
              </h5>
            </div>
            <div className="modal-body table-responsive">
              <table className="table table-hover table-bordered">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Photo</th>
                    <th>Student ID</th>
                    <th>Full Name</th>
                    <th>Mark Attendance</th>
                  </tr>
                </thead>
                <tbody>
                  {regs?.map((v, i) => (
                    <tr key={i}>
                      <th>{i + 1}</th>
                      <td>
                        <img src={v.photo} alt="Student" />
                      </td>
                      <td>{v.studentid}</td>
                      <td>{v.name}</td>
                      <td>
                        <MarkClassAttendance
                          student={v.regno}
                          contact={contact}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => setshowmodal(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </ReactModal>
      <span
        onClick={() => setshowmodal(true)}
        className="icon-box pointer"
      ></span>
    </div>
  );
};

export default MarkAttendance;
