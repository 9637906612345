import React from "react";
import ReactModal from "react-modal";
import { axiosdelete, axiosget } from "../../helpers/httprequest";
import is_null from "../../helpers/is_null";
import swal from "../../helpers/swal";

const DeleteContact = ({ contact, modules }) => {
  const [loading, setloading] = React.useState(true);
  const [mloading, setmloading] = React.useState(false);
  const [data, setdata] = React.useState(undefined);
  const [name, setname] = React.useState(undefined);
  const [showmodal, setshowmodal] = React.useState(undefined);
  React.useEffect(() => {
    (async () => {
      try {
        setloading(true);
        const req = await axiosget(`ms_course_attendance/contact/${contact}`);
        if (req.data?.type) {
          throw new Error(req.data?.message);
        }
        setdata(req.data.length);
        setloading(false);
      } catch ({ message }) {
        console.warn(message);
      }
      setloading(false);
    })();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const _show = () => setshowmodal(true);
  const _close = () => setshowmodal(false);
  const _save = async () => {
    try {
      setmloading(true);
      if (is_null(name) || name !== "Delete") {
        swal("error", "Please fill the form properly", setmloading, false);
        return;
      }
      const req = await axiosdelete(`ms_course_contact/${contact}`);
      if (req.status !== 200)
        throw new Error("Sorry something went wrong, please try again");
      const { type, message } = req.data;
      if (type === "success") {
        swal(type, message, setmloading, true);
        return;
      }
      swal(type, message, setmloading, false);
    } catch ({ message }) {
      swal("error", message, setmloading);
    }
  };

  return (
    <div>
      <ReactModal
        isOpen={showmodal}
        className="ReactModal"
        onRequestClose={_close}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-center text-danger">
                Delete {modules.label}
              </h5>
            </div>
            <div className="modal-body">
              <div className="text-wrap my-4 text-md">
                To delete this contact type the word{" "}
                <b className="text-danger">Delete</b> in the text box
              </div>
              <div className="text-wrap my-4 text-md">
                Make sure you know this is what you want, Even the system
                developer cannot recover the data if deleted. kindly trade with
                caution!!!
              </div>
              <div className="form-group">
                <input
                  type="email"
                  className="form-control form-control-lg"
                  id="exampleInputEmail2"
                  placeholder="Delete"
                  onChange={(e) => setname(e.target.value)}
                />
              </div>
              <div className="modal-footer">
                {mloading ? (
                  <button type="button" className="btn btn-primary">
                    Please wait...
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={_save}
                  >
                    Delete Record
                  </button>
                )}

                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={_close}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </ReactModal>

      {loading ? (
        "-"
      ) : (
        <>
          {data <= 0 ? (
            <span
              className="ti-trash pointer text-danger"
              onClick={_show}
            ></span>
          ) : (
            "-"
          )}
        </>
      )}
    </div>
  );
};

export default DeleteContact;
