import React from "react";
import { appHost } from "../../helpers/uri";

const Loader = ({ message }) => {
  return (
    <div
      style={{
        display: "flex",
        alignContent: "center",
        justifyContent: "center",
        marginTop: 40,
      }}
    >
      <img src={`${appHost}images/loading.gif`} alt="Loader" />
      {message && <p>{message}</p>}
    </div>
  );
};

export default Loader;
