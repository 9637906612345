import React from "react";
import { appHost } from "../../helpers/uri";

const BtnModuleRegistration = ({ modules, uri }) => {
  const _show = async () => {
    window.open(
      `${appHost}${uri}/${modules._id}`,
      `Student Module Registration Window`,
      `_parent`,
      `width=450,height=650`
    );
  };

  return (
    <div>
      <div>
        <button
          className="btn btn-icon btn-primary btn-rounded"
          onClick={_show}
        >
          <i className="ti-layout-list-thumb"></i>
        </button>
      </div>
    </div>
  );
};

export default BtnModuleRegistration;
