import axios from "axios";
import React from "react";
import Modal from "react-modal";
import { axiospost, axiosput } from "../../helpers/httprequest";
import image_processor from "../../helpers/image_processor";
import is_null from "../../helpers/is_null";
import swal from "../../helpers/swal";
import SelectStatus from "../../Select/SelectStatus";
import states_lga from "./state_lga.json";
import quallist from "./qualifications.json";
import { UploadLink } from "../../helpers/uri";

const BtnStaff = ({ modules }) => {
  const [showmodal, setshowmodal] = React.useState(false);
  const [loading, setloading] = React.useState(false);
  const [photo, setphoto] = React.useState(undefined);
  const [firstname, setfirstname] = React.useState(
    modules ? modules?.firstname : ""
  );
  const [middlename, setmiddlename] = React.useState(
    modules ? modules?.middlename : ""
  );
  const [lastname, setlastname] = React.useState(
    modules ? modules?.lastname : ""
  );
  const [gender, setgender] = React.useState(modules ? modules?.gender : "");
  const [religion, setreligion] = React.useState(
    modules ? modules?.religion : ""
  );
  const [display, setdisplay] = React.useState(
    modules ? modules?.photo?.link : ""
  );
  const [qualification, setqualification] = React.useState(
    modules ? modules?.qualification : ""
  );
  const [maritalstatus, setmaritalstatus] = React.useState(
    modules ? modules?.maritalstatus : ""
  );
  const [emailaddress, setemailaddress] = React.useState(
    modules ? modules?.emailaddress : ""
  );
  const [phonenumber, setphonenumber] = React.useState(
    modules ? modules?.phonenumber : ""
  );
  const [homeaddress, sethomeaddress] = React.useState(
    modules ? modules?.homeaddress : ""
  );
  const [stateoforigin, setstateoforigin] = React.useState(
    modules ? modules?.stateoforigin : undefined
  );
  const [stateofresidence, setstateofresidence] = React.useState(
    modules ? modules?.stateofresidence : undefined
  );
  const [lgaoforigin, setlgaoforigin] = React.useState(
    modules ? modules?.lgaoforigin : ""
  );
  const [dateofbirth, setdateofbirth] = React.useState(
    modules ? String(modules?.dateofbirth).split("T")[0] : ""
  );
  const [lgaofresidence, setlgaofresidence] = React.useState(
    modules ? modules?.lgaofresidence : ""
  );
  const [lgaoriginlist, setlgaoriginlist] = React.useState(
    modules ? modules?.lgaoriginlist : ""
  );
  const [lgaresidencelist, setlgaresidencelist] = React.useState(
    modules ? modules?.lgaresidencelist : ""
  );

  React.useEffect(() => {
    if (!is_null(stateoforigin)) {
      const selectedstate = states_lga.find((o) => o.state === stateoforigin);
      const lgas = selectedstate?.lgas
        ?.map((v) => ({ name: v, value: v }))
        .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
      setlgaoriginlist(lgas);
    }
  }, [stateoforigin]);

  React.useEffect(() => {
    if (photo !== undefined) {
      setdisplay(URL.createObjectURL(photo));
    }
  }, [photo]);
  React.useEffect(() => {
    if (!is_null(stateofresidence)) {
      const selectedstate = states_lga.find(
        (o) => o.state === stateofresidence
      );
      const lgas = selectedstate?.lgas
        ?.map((v) => ({ name: v, value: v }))
        .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
      setlgaresidencelist(lgas);
    }
  }, [stateofresidence]);

  const _save = async () => {
    try {
      setloading(true);
      if (
        is_null(firstname) ||
        is_null(lastname) ||
        is_null(gender) ||
        is_null(religion) ||
        is_null(qualification) ||
        is_null(maritalstatus) ||
        is_null(emailaddress) ||
        is_null(phonenumber) ||
        is_null(homeaddress) ||
        is_null(dateofbirth) ||
        is_null(stateoforigin) ||
        is_null(stateofresidence) ||
        is_null(lgaoforigin) ||
        is_null(lgaofresidence)
      ) {
        swal("warning", "Please fill the form properly", setloading, false);
        return;
      }
      let pix = null;
      if (!is_null(photo)) {
        const fd = new FormData();
        fd.append("photo", photo);
        const upload = await axios.post(UploadLink, fd);
        if (!is_null(upload?.data)) {
          if (upload.data?.type === "error") {
            throw new Error(upload.data?.message);
          }
        }
        pix = upload.data;
      } else {
        if (!is_null(modules)) {
          pix = modules.photo;
        }
      }
      if (is_null(pix)) {
        swal(
          "warning",
          "Sorry, system could not detect your picture",
          setloading,
          false
        );
        return;
      }
      let req;
      if (!is_null(modules)) {
        req = await axiosput(`ms_staff/bio/${modules._id}`, {
          photo: pix,
          firstname,
          middlename,
          lastname,
          gender,
          religion,
          dateofbirth,
          qualification,
          maritalstatus,
          emailaddress,
          phonenumber,
          homeaddress,
          stateoforigin,
          stateofresidence,
          lgaoforigin,
          lgaofresidence,
        });
      } else {
        req = await axiospost("ms_staff/bio", {
          photo: pix,
          firstname,
          middlename,
          lastname,
          gender,
          religion,
          dateofbirth,
          qualification,
          maritalstatus,
          emailaddress,
          phonenumber,
          homeaddress,
          stateoforigin,
          stateofresidence,
          lgaoforigin,
          lgaofresidence,
        });
      }

      if (req.status !== 200) {
        throw new Error(
          "Sorry, system could not finish processing your request"
        );
      }
      const { data } = req;
      if (data && data.type === "success") {
        const { message, type } = data;
        swal(type, message, setloading, true);
      } else {
        throw new Error(data.message);
      }
    } catch ({ message }) {
      swal("error", message, setloading);
    }
  };

  const _close = () => {
    setshowmodal(false);
  };
  const _show = () => {
    setshowmodal(true);
  };
  const states = states_lga
    .map((v) => ({ name: v.state, value: v.state }))
    .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));

  return (
    <div>
      <Modal
        isOpen={showmodal}
        onRequestClose={() => setshowmodal(false)}
        className="ReactModal"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-center">New Staff Bio Data</h5>
            </div>
            <div className="modal-body">
              <div className="pt-1 mb-3">
                <div className="row">
                  <div className="col-md-9">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="firstname">
                            First Name <b className="text-danger">*</b>
                          </label>
                          <input
                            className="form-control form-control-lg"
                            id="firstname"
                            required
                            placeholder="First Name*"
                            onChange={(e) => setfirstname(e.target.value)}
                            value={firstname}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="middlename">Middle Name</label>
                          <input
                            className="form-control form-control-lg"
                            id="middlename"
                            placeholder="Middle Name"
                            onChange={(e) => setmiddlename(e.target.value)}
                            value={middlename}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="lastname">
                            Last Name <b className="text-danger">*</b>
                          </label>
                          <input
                            className="form-control form-control-lg"
                            id="exampleInputEmail1"
                            placeholder="Last Name*"
                            onChange={(e) => setlastname(e.target.value)}
                            value={lastname}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        {states && (
                          <SelectStatus
                            options={states}
                            label="Select State of Origin"
                            setpagegroup={setstateoforigin}
                            active={stateoforigin}
                          />
                        )}
                      </div>
                      <div className="col-md-4">
                        {lgaoriginlist && (
                          <SelectStatus
                            options={lgaoriginlist}
                            label="Select Local Gov. Area of Origin"
                            setpagegroup={setlgaoforigin}
                            active={lgaoforigin}
                          />
                        )}
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="dateofbirth">
                            Date of Birth <b className="text-danger">*</b>
                          </label>
                          <input
                            className="form-control form-control-lg"
                            id="exampleInputEmail1"
                            placeholder="Date of Birth*"
                            type="date"
                            onChange={(e) => setdateofbirth(e.target.value)}
                            value={dateofbirth}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        {states && (
                          <SelectStatus
                            options={states}
                            label="Select State of Residence"
                            setpagegroup={setstateofresidence}
                            active={stateofresidence}
                          />
                        )}
                      </div>
                      <div className="col-md-4">
                        {lgaresidencelist && (
                          <SelectStatus
                            options={lgaresidencelist}
                            label="Select Local Gov. Area of Residence"
                            setpagegroup={setlgaofresidence}
                            active={lgaofresidence}
                          />
                        )}
                      </div>
                      <div className="col-md-4">
                        {states && (
                          <SelectStatus
                            options={quallist}
                            label="Select Highest Qualification"
                            setpagegroup={setqualification}
                            active={qualification}
                          />
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        {states && (
                          <SelectStatus
                            options={[
                              { name: "Male", value: "Male" },
                              { name: "Female", value: "Female" },
                            ]}
                            label="Select Gender"
                            setpagegroup={setgender}
                            active={gender}
                          />
                        )}
                      </div>
                      <div className="col-md-4">
                        <SelectStatus
                          options={[
                            { name: "Muslim", value: "Muslim" },
                            { name: "Christain", value: "Christain" },
                            { name: "Others", value: "Others" },
                          ]}
                          label="Select Religion"
                          setpagegroup={setreligion}
                          active={religion}
                        />
                      </div>
                      <div className="col-md-4">
                        <SelectStatus
                          options={[
                            { name: "Single", value: "Single" },
                            { name: "Married", value: "Married" },
                            { name: "Divorced", value: "Divorced" },
                            { name: "Widowed", value: "Widowed" },
                          ]}
                          label="Select Marital Status"
                          setpagegroup={setmaritalstatus}
                          active={maritalstatus}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="lastname">Email Address</label>
                          <input
                            type="email"
                            className="form-control form-control-lg"
                            id="exampleInputEmail1"
                            placeholder="Email Address"
                            onChange={(e) => setemailaddress(e.target.value)}
                            value={emailaddress}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="lastname">
                            Phone Number <b className="text-danger">*</b>
                          </label>
                          <input
                            type="email"
                            className="form-control form-control-lg"
                            id="exampleInputEmail1"
                            placeholder="Phone Number*"
                            onChange={(e) => setphonenumber(e.target.value)}
                            value={phonenumber}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="lastname">
                            Home Address <b className="text-danger">*</b>
                          </label>
                          <input
                            type="email"
                            className="form-control form-control-lg"
                            id="exampleInputEmail1"
                            placeholder="Home Address*"
                            onChange={(e) => sethomeaddress(e.target.value)}
                            value={homeaddress}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <div className="text-center">
                        <img
                          className="id-template"
                          src={!is_null(display) ? display : `./images/id.jpg`}
                          alt="ID Template"
                        />
                      </div>
                      <label htmlFor="passport" className="text-center">
                        Clear Passport Photograph (white background color,
                        min-width:200px, min-height:300px)
                        <b className="text-danger">*</b>
                      </label>
                      <input
                        className="form-control form-control-lg"
                        id="passport"
                        required
                        type="file"
                        onChange={(e) =>
                          image_processor(
                            e.target.files[0],
                            setphoto,
                            200,
                            300,
                            "image"
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              {loading ? (
                <button type="button" className="btn btn-primary">
                  Please wait...
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={_save}
                >
                  Save changes
                </button>
              )}
              <button type="button" className="btn btn-danger" onClick={_close}>
                Close
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <div>
        {!modules && (
          <div
            style={{
              position: "fixed",
              right: "50px",
              bottom: "50px",
            }}
          >
            <button
              className="btn btn-icon btn-primary btn-rounded"
              onClick={_show}
            >
              <i className="ti-plus"></i>
            </button>
          </div>
        )}
      </div>
      <div>
        {modules && (
          <button
            className="btn btn-icon btn-primary btn-rounded"
            onClick={_show}
          >
            <i className="ti-pencil"></i>
          </button>
        )}
      </div>
    </div>
  );
};

export default BtnStaff;
