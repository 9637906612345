import React from "react";
import ReactModal from "react-modal";
import Swal from "sweetalert2";
import StudentProfile from "../../components/StudentProfile";
import StudentResult from "../../components/StudentResult";
import { contextapi } from "../../helpers/contextapi";
import { axiospost } from "../../helpers/httprequest";
import is_null from "../../helpers/is_null";
import swal from "../../helpers/swal";

const BtnChangeOfProgrammeMgt = ({ modules }) => {
  const [showmodal, setshowmodal] = React.useState(false);
  const [loading, setloading] = React.useState(false);
  const [loadingreject, setloadingreject] = React.useState(false);
  const [stage, setstage] = React.useState(0);
  const context = React.useContext(contextapi);
  const { selectedChangeofCourse } = context.values;
  React.useEffect(() => {
    context.setvalues({ ...context.values, selectedChangeofCourse: modules });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedChangeofCourse]);
  const _close = () => {
    setstage(0);
    setshowmodal(false);
  };
  const _show = () => {
    setshowmodal(true);
  };
  // const _stage = (num) => {
  //   if (num === 0) {
  //     return "Continue to Select Relevant Result";
  //   }
  //   if (num === 1) {
  //     return "Continue to Select Level and Semester";
  //   }
  // };
  const _save = async () => {
    try {
      setloading(true);
      const {
        changeofCourseResult,
        changeofCourseSemester,
        changeofCourseLevel,
      } = context.values;

      if (is_null(changeofCourseLevel) || is_null(changeofCourseSemester)) {
        swal(
          "info",
          "Sorry you need to selected level and semester",
          setloading,
          false
        );
        return;
      }

      if (
        is_null(changeofCourseResult) &&
        (changeofCourseLevel !== "100 Level" ||
          changeofCourseSemester !== "First Semester")
      ) {
        const confirm = await Swal.fire({
          title: "ACCEPT STUDENT CONFIRMATION",
          html: `You did not select any result for this student. 
        <br>Are you sure you want to accept this student in ${changeofCourseLevel} ${changeofCourseSemester}`,
          icon: "warning",
          padding: "10px",
          confirmButtonColor: "#4b49ac",
          showCancelButton: true,
          confirmButtonText: "Yes Accept",
        });
        if (!confirm.isConfirmed) {
          setloading(false);
          return;
        }
      }
      const req = await axiospost("ms_course_change/hod", {
        tolevel: changeofCourseLevel,
        tosemester: changeofCourseSemester,
        result: changeofCourseResult,
        change: selectedChangeofCourse._id,
      });
      if (req.data?.type === "error") {
        throw new Error(req.data?.message);
      }
      const { type, message } = req.data;
      swal(
        type,
        message,
        () => {
          setloading(false);
          setshowmodal(false);
        },
        false
      );
      return;
    } catch ({ message }) {
      swal("error", message, setloading, false);
    }
    return;
  };
  const _next = () => {
    setstage(stage + 1);
  };

  const _reject = async () => {
    try {
      setloadingreject(true);
      const rez = await Swal.fire({
        title: "What is your reason for rejecting this application?",
        input: "text",
        inputAttributes: {
          autocapitalize: "off",
        },
        showCancelButton: true,
        confirmButtonText: "Look up",
        showLoaderOnConfirm: true,
      });
      const reason = rez.value;
      if (is_null(reason)) {
        swal(
          "error",
          "Sorry you need to fill the form properly",
          setloadingreject,
          false
        );
        return;
      }

      const req = await axiospost("ms_course_change/delete/mgt", {
        application: selectedChangeofCourse._id,
        reason,
      });
      if (req.data?.type === "error") {
        throw new Error(req.data?.message);
      }
      const { type, message } = req.data;
      if (type === "success") {
        swal(type, message, setloadingreject, true);
        return;
      }
      swal(type, message, setloadingreject, false);
    } catch ({ message }) {
      swal("error", message, setloadingreject, false);
    }
  };

  return (
    <div>
      <ReactModal
        isOpen={showmodal}
        className="ReactModal"
        onRequestClose={_close}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-center">{`${modules?.stdinfo.firstname}'s Profile`}</h5>
              <span className="text-danger pointer" onClick={_close}>
                &times;
              </span>
            </div>

            <div className="modal-body">
              <StudentProfile id={modules?.student} />
              <StudentResult id={modules?.student} />
            </div>
            <div className="modal-footer">
              {loading ? (
                <button type="button" className="btn btn-primary">
                  Please wait...
                </button>
              ) : (
                <>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={_save}
                  >
                    Confirm Application
                  </button>
                </>
              )}
              <button
                type="button"
                className="btn btn-danger"
                onClick={_reject}
              >
                Reject Application
              </button>
            </div>
          </div>
        </div>
      </ReactModal>

      <div>
        {!modules && (
          <div
            style={{
              position: "absolute",
              right: "50px",
              bottom: "50px",
            }}
          >
            <button
              className="btn btn-icon btn-primary btn-rounded"
              onClick={_show}
            >
              <i className="ti-plus"></i>
            </button>
          </div>
        )}
      </div>
      <div>
        {modules && (
          <button
            className="btn btn-icon btn-primary btn-rounded"
            onClick={_show}
          >
            <i className="ti-eye"></i>
          </button>
        )}
      </div>
    </div>
  );
};

export default BtnChangeOfProgrammeMgt;
