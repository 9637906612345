import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import Dashboard from "./pages/Dashboard";
import UserGroup from "./pages/UserGroup";
import Login from "./pages/Login";
import NotFound from "./pages/NotFound";
import SystemModules from "./pages/SystemModules";
import Recover from "./pages/Recover";
import SystemPages from "./pages/SystemPages";
import UserPermission from "./pages/UserPermission";
import { contextapi } from "./helpers/contextapi";
import React from "react";
import ProtectedRoute from "./helpers/ProtectedRoute";
import Session from "./pages/Session";
import Department from "./pages/Department";
import Programme from "./pages/Programme";
import Requirement from "./pages/Requirements";
import ApplicationFee from "./pages/ApplicationFee";
import Modules from "./pages/Modules";
import Modal from "react-modal";
import Applications from "./pages/Applications";
import TuitionFee from "./pages/TuitionFee";
import Staff from "./pages/Staff";
import DepartmentHead from "./pages/DepartmentHead";
import CourseAllocation from "./pages/CourseAllocation";
import ClassDashboard from "./Classroom/ClassDashboard";
import MyModules from "./pages/MyModules";
import ClassMaterials from "./Classroom/ClassMaterials";
import CourseContact from "./Classroom/CourseContacts";
import ClassResult from "./Classroom/ClassResult";
import ClassStudentList from "./Classroom/ClassStudentList";
import ChangeOfProgramme from "./pages/ChangeOfProgramme";
import ChangeOfProgrammeMgt from "./pages/ChangeOfProgrammeMgt";
import PublishResult from "./pages/PublishResult";
import AcademicProgression from "./pages/AcademicProgression";
import IndividualProgression from "./pages/IndividualProgression";
import ChangeStudentStatus from "./pages/ChangeStudentStatus";
import CreditWallet from "./pages/CreditWallet";
import RegistrationSettings from "./pages/RegistrationSettings";
import ModuleRegistration from "./pages/ModuleRegistration";
import AddDropModules from "./pages/AddDropModules";
import PrintRegistration from "./pages/PrintRegistration";
import PrintResultStatement from "./pages/PrintResultStatement";
import ResultStatement from "./pages/ResultStatement";
import ResultModeration from "./pages/ResultModeration";
import AdminLayout from "./helpers/AdminLayout";
import College from "./pages/College";
import CorrectApplicants from "./pages/CorrectApplicants";
import ApplicantPaymentRRR from "./pages/ApplicantPaymentRRR";
import ApplicationReport from "./pages/ApplicationReport";
import ApplicationFilters from "./pages/ApplicationFilters";
const App = () => {
  const [values, setvalues] = React.useState({
    smm: false,
  });
  Modal.setAppElement("#root");

  return (
    <React.StrictMode>
      <contextapi.Provider value={{ values, setvalues }}>
        <Router>
          <Routes>
            <Route exact path="/" element={<Login />} />
            <Route path="/account" element={<Recover />} />

            <Route path="/app/" element={<AdminLayout />}>
              <Route
                path="dashboard"
                element={
                  // <ProtectedRoute>
                  <Dashboard />
                  // </ProtectedRoute>
                }
              />
            </Route>

            {/* Result menu */}
            <Route path="/result/" element={<AdminLayout />}>
              <Route
                path="moderation"
                element={
                  <ProtectedRoute>
                    <ResultModeration />
                  </ProtectedRoute>
                }
              />
              <Route
                path="statement"
                element={
                  <ProtectedRoute>
                    <ResultStatement />
                  </ProtectedRoute>
                }
              />
              <Route
                path="publish"
                element={
                  <ProtectedRoute>
                    <PublishResult />
                  </ProtectedRoute>
                }
              />
              <Route
                path="statement/:id"
                element={
                  <ProtectedRoute>
                    <PrintResultStatement />
                  </ProtectedRoute>
                }
              />
            </Route>
            {/* Academic menu */}
            <Route path="/academic/" element={<AdminLayout />}>
              <Route
                path="assign-hod"
                element={
                  <ProtectedRoute>
                    <DepartmentHead />
                  </ProtectedRoute>
                }
              />

              <Route
                path="semester"
                element={
                  <ProtectedRoute>
                    <Session />
                  </ProtectedRoute>
                }
              />
              <Route
                path="department"
                element={
                  <ProtectedRoute>
                    <Department />
                  </ProtectedRoute>
                }
              />
              <Route
                path="college"
                element={
                  <ProtectedRoute>
                    <College />
                  </ProtectedRoute>
                }
              />
              <Route
                path="modules"
                element={
                  <ProtectedRoute>
                    <Modules />
                  </ProtectedRoute>
                }
              />
              <Route
                path="programme"
                element={
                  <ProtectedRoute>
                    <Programme />
                  </ProtectedRoute>
                }
              />
            </Route>
            {/* Permission menu */}
            <Route path="/permission/" element={<AdminLayout />}>
              <Route
                path="page-groups"
                element={
                  <ProtectedRoute>
                    <SystemModules />
                  </ProtectedRoute>
                }
              />
              <Route
                path="tuition"
                element={
                  <ProtectedRoute>
                    <TuitionFee />
                  </ProtectedRoute>
                }
              />
              <Route
                path="manage-pages"
                element={
                  <ProtectedRoute>
                    <SystemPages />
                  </ProtectedRoute>
                }
              />
              <Route
                path="user-groups"
                element={
                  <ProtectedRoute>
                    <UserGroup />
                  </ProtectedRoute>
                }
              />
              <Route
                path="user-permission"
                element={
                  <ProtectedRoute>
                    <UserPermission />
                  </ProtectedRoute>
                }
              />
            </Route>
            {/* Admission application menu*/}
            <Route path="/application/" element={<AdminLayout />}>
              <Route
                path="all"
                element={
                  <ProtectedRoute>
                    <Applications />
                  </ProtectedRoute>
                }
              />
              <Route
                path="applicant"
                element={
                  <ProtectedRoute>
                    <CorrectApplicants />
                  </ProtectedRoute>
                }
              />
              <Route
                path="filters"
                element={
                  <ProtectedRoute>
                    <ApplicationFilters />
                  </ProtectedRoute>
                }
              />
              <Route
                path="aes_report"
                element={
                  <ProtectedRoute>
                    <ApplicationReport />
                  </ProtectedRoute>
                }
              />
              <Route
                path="applicant_rrr"
                element={
                  <ProtectedRoute>
                    <ApplicantPaymentRRR />
                  </ProtectedRoute>
                }
              />
              <Route
                path="fee"
                element={
                  <ProtectedRoute>
                    <ApplicationFee />
                  </ProtectedRoute>
                }
              />
              <Route
                path="requirement"
                element={
                  <ProtectedRoute>
                    <Requirement />
                  </ProtectedRoute>
                }
              />
            </Route>
            {/* Student menu */}
            <Route path="/student/" element={<AdminLayout />}>
              <Route
                path="add-drop"
                element={
                  <ProtectedRoute>
                    <AddDropModules />
                  </ProtectedRoute>
                }
              />
              <Route
                path="registration-settings"
                element={
                  <ProtectedRoute>
                    <RegistrationSettings />
                  </ProtectedRoute>
                }
              />
              <Route
                path="change-programme-hod"
                element={
                  <ProtectedRoute>
                    <ChangeOfProgramme />
                  </ProtectedRoute>
                }
              />
              <Route
                path="change-programme-mgt"
                element={
                  <ProtectedRoute>
                    <ChangeOfProgrammeMgt />
                  </ProtectedRoute>
                }
              />
              <Route
                path="module-registration"
                element={
                  <ProtectedRoute>
                    <ModuleRegistration />
                  </ProtectedRoute>
                }
              />
              <Route
                path="progression"
                element={
                  <ProtectedRoute>
                    <AcademicProgression />
                  </ProtectedRoute>
                }
              />
              <Route
                path="individual-progression"
                element={
                  <ProtectedRoute>
                    <IndividualProgression />
                  </ProtectedRoute>
                }
              />
              <Route
                path="change-status"
                element={
                  <ProtectedRoute>
                    <ChangeStudentStatus />
                  </ProtectedRoute>
                }
              />
              <Route
                path="wallet"
                element={
                  <ProtectedRoute>
                    <CreditWallet />
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route
              path="/print-registration/:id"
              element={<PrintRegistration />}
            />
            <Route path="/staff/" element={<AdminLayout />}>
              <Route
                path="module-allocation"
                element={
                  <ProtectedRoute>
                    <CourseAllocation />
                  </ProtectedRoute>
                }
              />

              <Route
                path="my-modules"
                element={
                  <ProtectedRoute>
                    <MyModules />
                  </ProtectedRoute>
                }
              />
              <Route
                path="my-module-profile"
                element={
                  <ProtectedRoute>
                    <ClassDashboard />
                  </ProtectedRoute>
                }
              />
              <Route
                path="my-class-result"
                element={
                  <ProtectedRoute>
                    <ClassResult />
                  </ProtectedRoute>
                }
              />
              <Route
                path="my-class-list"
                element={
                  <ProtectedRoute>
                    <ClassStudentList />
                  </ProtectedRoute>
                }
              />

              <Route
                path="my-module-contacts"
                element={
                  <ProtectedRoute>
                    <CourseContact />
                  </ProtectedRoute>
                }
              />
              <Route
                path="class-materials"
                element={
                  <ProtectedRoute>
                    <ClassMaterials />
                  </ProtectedRoute>
                }
              />

              <Route
                path="staff"
                element={
                  <ProtectedRoute>
                    <Staff />
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Router>
      </contextapi.Provider>
    </React.StrictMode>
  );
};

export default App;
