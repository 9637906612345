import React from "react";
import Loader from "../../components/Loader";
import { axiosget } from "../../helpers/httprequest";
import swal from "../../helpers/swal";

const SelectPageGroup = ({ setpagegroup, active }) => {
  const [data, setdata] = React.useState(null);
  const [loading, setloading] = React.useState(true);
  React.useEffect(() => {
    (async () => {
      try {
        const req = await axiosget("ms_page_group");
        setdata(req.data);
        setloading(false);
      } catch ({ message }) {
        swal("error", message, setloading);
      }
    })();
  }, []);
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="form-group">
          <label htmlFor="js-example-basic-single">Select Page Group</label>
          <select
            id="js-example-basic-single"
            className="form-control form-control-lg js-example-basic-single w-100"
            onChange={(e) => setpagegroup(e.target.value)}
          >
            <option value={null}> Select Page Group</option>
            {data?.map((v, i) => {
              let show = null;
              if (active !== undefined && active === v._id) {
                show = "(Current Selection)";
              }
              return (
                <option key={i} value={v._id}>
                  {v.name} {show}
                </option>
              );
            })}
          </select>
        </div>
      )}
    </>
  );
};

export default SelectPageGroup;
