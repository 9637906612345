import React from "react";
import ReactModal from "react-modal";
import { axiospost, axiosput } from "../../helpers/httprequest";
import is_null from "../../helpers/is_null";
import swal from "../../helpers/swal";

const BtnUserGroup = ({ modules }) => {
  const [showmodal, setshowmodal] = React.useState(false);

  const [name, setname] = React.useState(
    modules !== undefined ? modules.name : ""
  );
  const [loading, setloading] = React.useState(false);

  const _save = async () => {
    try {
      setloading(true);
      if (is_null(name)) {
        swal("error", "Please fill the form properly", setloading);
        return;
      }
      let req;
      if (modules !== undefined) {
        const { _id } = modules;
        req = await axiosput(`ms_user_groups/${_id}`, { name });
      } else {
        req = await axiospost("ms_user_groups", { name });
      }

      if (req.status !== 200)
        throw new Error("Sorry something went wrong, please try again");
      const { type, message } = req.data;

      if (type === "success") {
        swal(
          type,
          message,
          () => {
            setloading(false);
            _close();
          },
          true
        );
      } else {
        swal(type, message, setloading(false), false);
      }
    } catch ({ message }) {
      swal("error", message, setloading);
    }
  };
  const _close = () => {
    setshowmodal(false);
    setname("");
  };
  const _show = () => {
    setshowmodal(true);
    setname(modules !== undefined ? modules.name : "");
  };

  return (
    <div>
      <ReactModal
        isOpen={showmodal}
        onRequestClose={() => setshowmodal(false)}
        className="ReactModal"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-center">
                {modules === undefined ? "Create" : "Update"} User Group
              </h5>
              <span
                type="button"
                className="pointer text-danger"
                onClick={_close}
              >
                &times;
              </span>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <label htmlFor="exampleInputEmail2">User Group Name</label>
                <input
                  type="email"
                  className="form-control form-control-lg"
                  id="exampleInputEmail2"
                  placeholder="User Group Name"
                  onChange={(e) => setname(e.target.value)}
                  value={name}
                />
              </div>
            </div>
            <div className="modal-footer">
              {loading ? (
                <button type="button" className="btn btn-primary">
                  Please wait...
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={_save}
                >
                  Save changes
                </button>
              )}
            </div>
          </div>
        </div>
      </ReactModal>
      <div>
        {!modules && (
          <div
            style={{
              position: "fixed",
              right: "50px",
              bottom: "50px",
            }}
          >
            <button
              className="btn btn-icon btn-primary btn-rounded"
              onClick={_show}
            >
              <i className="ti-plus"></i>
            </button>
          </div>
        )}
      </div>
      <div>
        {modules && (
          <button
            className="btn btn-icon btn-primary btn-rounded"
            onClick={_show}
          >
            <i className="ti-pencil"></i>
          </button>
        )}
      </div>
    </div>
  );
};

export default BtnUserGroup;
