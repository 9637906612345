import React from "react";
import ReactModal from "react-modal";
import TblUserGroupPermission from "../../tables/TblUserGroupPermission";

const BtnUserGroupPermission = ({ modules }) => {
  const [showmodal, setshowmodal] = React.useState(false);

  const _close = () => {
    setshowmodal(false);
  };
  const _show = () => {
    setshowmodal(true);
  };

  return (
    <div>
      <ReactModal
        isOpen={showmodal}
        onRequestClose={() => setshowmodal(false)}
        className="ReactModal"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-center">
                Manage {modules.name} Group Permission
              </h5>
              <span
                type="button"
                className="pointer text-danger"
                onClick={_close}
              >
                &times;
              </span>
            </div>
            <div className="modal-body">
              <TblUserGroupPermission dataset={modules} />
            </div>
            <div className="modal-footer"></div>
          </div>
        </div>
      </ReactModal>

      <div>
        {modules && (
          <button
            className="btn btn-icon btn-primary btn-rounded"
            onClick={_show}
          >
            <i className="ti-lock"></i>
          </button>
        )}
      </div>
    </div>
  );
};

export default BtnUserGroupPermission;
