import React from "react";
import BtnClassPost from "../../Buttons/BtnClassPost";
import BtnClassPostFile from "../../Buttons/BtnClassPostFile";
import BtnClassTopic from "../../Buttons/BtnClassTopic";
import ClassroomNavbar from "../../components/ClassroomNavbar";
import FullTopicView from "../../components/FullTopicView";
import Loader from "../../components/Loader";
import { contextapi } from "../../helpers/contextapi";
import { axiosget } from "../../helpers/httprequest";

const ClassMaterials = () => {
  const [module, setmodule] = React.useState(undefined);
  const [materials, setmaterials] = React.useState(undefined);
  const [loading, setloading] = React.useState(undefined);
  const context = React.useContext(contextapi);
  const { classroomCourse } = context.values;
  React.useEffect(() => {
    (async () => {
      setloading(true);
      try {
        const req = await axiosget(`ms_course_topic/my/${classroomCourse}`);
        if (req.data?.type) {
          throw new Error(req.data?.message);
        }
        const { topics, course: module } = req.data;
        setmodule(module);
        setmaterials(topics);
        setloading(false);
      } catch ({ message }) {
        console.warn(message);
      }
      setloading(false);
    })();
  }, []);
  return (
    <div>
      <ClassroomNavbar />
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="main-panel classroom-content w-100">
            <div className="container">
              <div className="row">
                <div className="col-md-12 pt-3 grid-margin stretch-card">
                  <div className="card">
                    <div className="card-body">
                      <div className="mb-5">
                        <h1 className="p1">{module?.code}</h1>
                        <h2>Student Reading Materials</h2>
                      </div>
                      {materials?.map((v, i) => (
                        <FullTopicView key={i} data={v} />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="dropdown static_add_button">
            <button
              type="button"
              className="btn btn-icon btn-dark btn-rounded "
              id="dropdownMenuIconButton7"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="ti-plus"></i>
            </button>
            <div
              className="dropdown-menu"
              aria-labelledby="dropdownMenuIconButton7"
            >
              <h6 className="dropdown-header">
                <span className="icon-menu"></span> CREATE MENU
              </h6>
              <div className="dropdown-divider"></div>
              <BtnClassTopic />
              <div className="dropdown-divider"></div>
              <BtnClassPost />
              <div className="dropdown-divider"></div>
              <BtnClassPostFile />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ClassMaterials;
