import React from "react";
import Swal from "sweetalert2";
import { axiosdelete, axiosget, axiospost } from "../../helpers/httprequest";
import swal from "../../helpers/swal";

const CheckGroupPermission = ({ pagedetails, usergroup }) => {
  const checkbox = React.useRef();
  const [loading, setloading] = React.useState(false);
  const [checked, setchecked] = React.useState(false);

  React.useEffect(() => {
    const groupid = usergroup._id;
    const pageid = pagedetails._id;
    (async () => {
      setloading(true);
      try {
        const req = await axiosget(`ms_group_permissions/${groupid}/${pageid}`);
        if (req?.data?.permit === true) {
          setchecked(true);
        }
      } catch ({ message }) {
        console.warn(message);
      }
      setloading(false);
    })();
  }, []);

  const _permission = async () => {
    try {
      setloading(true);
      const groupid = usergroup._id;
      const pageid = pagedetails._id;
      if (checkbox.current.checked) {
        const req = await axiospost("ms_group_permissions", {
          group: groupid,
          page: pageid,
        });
        if (req.status !== 200)
          throw new Error("Sorry something went wrong, please try again");
        const { type, message } = req.data;
        swal(type, message);
        if (type === "success") {
          setchecked(true);
        } else {
          setchecked(false);
        }
        setloading(false);
      } else {
        const check = await Swal.fire({
          title: "REMOVE PERMISSION",
          text: "Are you sure you want to remove this permission from the select user group?",
          showDenyButton: true,
          icon: "warning",
          confirmButtonText: "Yes",
          denyButtonText: "No",
        });
        if (check.isConfirmed) {
          const req = await axiosdelete(
            `ms_group_permissions/${groupid}/${pageid}`
          );
          if (req.status !== 200)
            throw new Error("Sorry something went wrong, please try again");
          const { type, message } = req.data;
          swal(type, message);
          if (type === "success") {
            setchecked(false);
          } else {
            setchecked(true);
          }
          setloading(false);
        } else {
          setloading(false);
        }
      }
    } catch ({ message }) {
      swal("error", message, setloading);
    }
  };

  return (
    <div className="form-group">
      {loading ? (
        "Please wait..."
      ) : (
        <div className="form-check form-check-primary">
          <label className="form-check-label">
            <input
              type="checkbox"
              className="form-check-input"
              onChange={_permission}
              ref={checkbox}
              checked={checked}
            />
            Permit
            <i className="input-helper"></i>
          </label>
        </div>
      )}
    </div>
  );
};

export default CheckGroupPermission;
