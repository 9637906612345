import React from "react";
import { axiosget } from "../../helpers/httprequest";
import swal from "../../helpers/swal";
import Modal from "react-modal";

const BtnApplicationRRR = ({ modules }) => {
  const [showmodal, setshowmodal] = React.useState(false);
  const [loading, setloading] = React.useState(false);
  const [rrr, setrrr] = React.useState(undefined);

  const _approve_application = async () => {
    try {
      setloading(true);
      const req = await axiosget(`ms_generated_rrr/applicant/${modules._id}`);
      if (req.status !== 200)
        throw new Error("Sorry something went wrong, please try again");
      if (req.data.type) throw new Error(req.data.message);
      const { data } = req;
      setrrr(data);
      setshowmodal(true);
    } catch ({ message }) {
      swal("error", message, undefined, false);
    }
    setloading(false);
  };

  return (
    <>
      <Modal
        isOpen={showmodal}
        onRequestClose={() => setshowmodal(false)}
        className="ReactModal"
      >
        <div className="modal-dialog large-modal" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-center text-uppercase">
                {modules.firstname}'s Generated RRR
              </h5>
              <span
                className="text-danger text-right"
                style={{ cursor: "pointer" }}
                onClick={() => setshowmodal(false)}
              >
                x
              </span>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12  table-responsive">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>SN</th>
                        <th>Programme</th>
                        <th>RRR</th>
                        <th>Date Time</th>
                      </tr>
                    </thead>
                    <tbody>
                      {rrr &&
                        rrr.map((r, i) => (
                          <tr key={`rrr_${i}`}>
                            <th>{i + 1}</th>
                            <td>{r.result[0].code}</td>
                            <td>{r.rrr}</td>
                            <td>{r.createdAt}</td>
                          </tr>
                        ))}
                    </tbody>
                    <tfoot>
                      <tr>
                        <th>SN</th>
                        <th>Programme</th>
                        <th>RRR</th>
                        <th>Date Time</th>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <button
        className="btn btn-icon btn-primary btn-rounded"
        onClick={_approve_application}
      >
        {loading ? (
          <i className="fa fa-refresh fa-spin" />
        ) : (
          <i className="fa fa-eye" />
        )}
      </button>
    </>
  );
};

export default BtnApplicationRRR;
