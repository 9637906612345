import React from "react";
import BtnStaff from "../../Buttons/BtnStaff";
import TblStaff from "../../tables/TblStaff";

const Staff = () => {
  return (
    <>
      <TblStaff />

      <BtnStaff />
    </>
  );
};

export default Staff;
