import React from "react";
import BtnTuitionFee from "../../Buttons/BtnTuitionFee";
import TblTuitionFee from "../../tables/TblTuitionFee";
import is_null from "../../helpers/is_null";
import { axiosget } from "../../helpers/httprequest";
import swal from "../../helpers/swal";
import SelectStatus from "../../Select/SelectStatus";

const TuitionFee = () => {
  const [loading, setloading] = React.useState(false);
  const [programme, setprogramme] = React.useState();
  const [selected, setselected] = React.useState();
  const [datatable, setdatatable] = React.useState();
  const [feetype, setfeetype] = React.useState();
  React.useEffect(() => {
    (async () => {
      try {
        const req = await axiosget("ms_programme/active");
        if (req.data?.type) {
          throw new Error(req.data?.message);
        }
        const data = req?.data?.map((v) => ({
          name: `${v.code} | ${v.name}`,
          value: v._id,
        }));
        setprogramme(data);
      } catch ({ message }) {
        console.warn(message);
      }
    })();
  }, []);
  const _fetch = async () => {
    try {
      setloading(true);
      if (is_null(feetype) || is_null(selected)) {
        swal("warning", "Please fill the form properly", setloading, false);
        return;
      }
      const req = await axiosget(
        `ms_programme_tuition_fee_items/programme/${selected}/${feetype}`
      );
      if (req.status !== 200) {
        throw new Error("Sorry something went wrong, please try again later");
      }
      if (req.data?.type) {
        throw new Error(req.data.message);
      }
      setdatatable(req.data);
      setloading(false);
    } catch ({ message }) {
      swal("error", message, setloading, false);
    }
  };
  return (
    <>
      <div className="row">
        <div className="col-md-4">
          <SelectStatus
            options={programme}
            label="Select Programme"
            setpagegroup={setselected}
          />
        </div>
        <div className="col-md-4">
          <SelectStatus
            options={[
              { name: "All Students", value: "null" },
              { name: "Fresh Students", value: true },
              { name: "Returning Students", value: false },
            ]}
            label="Select Fee Type"
            setpagegroup={setfeetype}
          />
        </div>
        <div className="col-md-4 pt-3">
          {loading ? (
            <button className="btn btn-primary mt-2 btn-lg btn-block">
              Please wait
            </button>
          ) : (
            <button
              onClick={_fetch}
              className="btn btn-primary mt-2 btn-lg btn-block"
            >
              Fetch Records
            </button>
          )}
        </div>
      </div>
      <div className="row">
        {datatable && (
          <div className="col-md-12 table-responsive">
            <TblTuitionFee dataset={datatable} programme={programme} />
          </div>
        )}
      </div>
      <BtnTuitionFee programme={programme} />
    </>
  );
};

export default TuitionFee;
