import React from "react";
import is_null from "../../helpers/is_null";
import { axiosget, axiospost } from "../../helpers/httprequest";
import SelectStatus from "../../Select/SelectStatus";
import AddFilterButton from "../../Buttons/AddFilterButton";
import swal from "../../helpers/swal";
import Loader from "../../components/Loader";
import DisplayInformationResult from "../../components/DisplayInformationResult";
import ExtractEmailAddress from "../../components/ExtractEmailAddress";
import ExtractPhoneNumber from "../../components/ExtractPhoneNumbers";

const ApplicationFilters = () => {
  const [programme, setprogramme] = React.useState(null);
  const [session, setsession] = React.useState(null);
  const [selected, setselected] = React.useState(null);
  const [data, setdata] = React.useState(null);
  const [selectedsession, setselectedsession] = React.useState(null);
  const [filters, setfilters] = React.useState([]);
  const [loading, setloading] = React.useState(false);
  React.useEffect(() => {
    (async () => {
      try {
        const req = await axiosget("ms_applications/filter");
        if (is_null(req.data?.error) && req.data) {
          const { programme, session: resession } = req?.data;
          const prog = programme?.map((v) => ({
            name: `${v.code} | ${v.name}`,
            value: v._id,
          }));
          const ses = resession?.map((v) => ({
            name: `${v.code} | ${v.name}`,
            value: v._id,
          }));
          setprogramme(prog);
          setsession(ses);
        }
      } catch ({ message }) {
        console.warn(message);
      }
    })();
  }, []);
  const _generate_report = async () => {
    try {
      setloading(true);
      if (selectedsession === null || selected === null || !filters.length) {
        swal(
          "error",
          `Please make sure you selected all the needed filter paramters,`,
          () => setloading(false),
          false
        );
      }
      const params = { session: selectedsession, programme: selected, filters };
      const req = await axiospost(`ms_applications/filter`, {
        ...params,
      });
      if (!is_null(req.data?.type)) {
        throw new Error(req.data?.message);
      }
      setdata(req.data);
      setloading(false);
    } catch ({ message }) {
      swal("error", message, () => setloading(false), false);
    }
  };
  return (
    <>
      <h2>Filter Applications</h2>
      <hr />
      <div className="row">
        <div className="col-md-4">
          <SelectStatus
            label={"Select Semester"}
            setpagegroup={setselectedsession}
            options={session}
          />
        </div>
        <div className="col-md-4">
          <SelectStatus
            label={"Select Programme"}
            setpagegroup={setselected}
            options={programme}
          />
        </div>
        <div className="col-md-1">
          <AddFilterButton setfilters={setfilters} filters={filters} />
        </div>
        <div className="col-md-3 mt-4">
          <button
            className="btn btn-primary btn-block btn-lg"
            onClick={_generate_report}
          >
            Generate Report
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="d-flex justify-content-between">
            <div>
              {filters && (
                <>
                  Filter Parameter:
                  {filters?.map((r, i) => (
                    <span key={`filter_key_${i}`} className="m-2 border p-1">
                      {`${r.subject} | ${r.score} `}
                    </span>
                  ))}
                </>
              )}
            </div>
            <div>
              {data && (
                <div className="d-flex">
                  Total: {data?.length} | <ExtractEmailAddress data={data} /> |{" "}
                  <ExtractPhoneNumber data={data} />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <hr />
          {loading ? (
            <Loader />
          ) : (
            <div className="p-3">
              {data && (
                <>
                  {data?.map((r, i) =>
                    r === null ? (
                      <></>
                    ) : (
                      <DisplayInformationResult
                        key={new Date().getTime() + i}
                        data={r}
                      />
                    )
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ApplicationFilters;
