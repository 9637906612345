import React from "react";
import ReactModal from "react-modal";
import { axiospost, axiosput } from "../../helpers/httprequest";
import is_null from "../../helpers/is_null";
import swal from "../../helpers/swal";
import SelectStatus from "../../Select/SelectStatus";

const BtnSession = ({ modules }) => {
  const [showmodal, setshowmodal] = React.useState(false);
  const [code, setcode] = React.useState(modules ? modules.code : "");
  const [name, setname] = React.useState(modules ? modules.name : "");
  const [status, setstatus] = React.useState("");
  const [loading, setloading] = React.useState(false);
  const _save = async () => {
    try {
      setloading(true);
      if (is_null(code) || is_null(name) || is_null(status)) {
        swal("error", "Please fill the form properly", setloading);
        return;
      }
      let req;
      if (modules !== undefined) {
        const { _id } = modules;
        req = await axiosput(`ms_session/${_id}`, {
          name,
          code,
          status,
        });
      } else {
        req = await axiospost("ms_session", {
          name,
          code,
          status,
        });
      }

      if (req.status !== 200)
        throw new Error("Sorry something went wrong, please try again");
      const { type, message } = req.data;
      if (type === "success") {
        swal(type, message, setloading, true);
        return;
      }
      swal(type, message, setloading, false);
    } catch ({ message }) {
      swal("error", message, setloading);
    }
  };

  const _close = () => {
    setshowmodal(false);
  };
  const _show = () => {
    setshowmodal(true);
  };

  return (
    <div>
      <ReactModal
        isOpen={showmodal}
        className="ReactModal"
        onRequestClose={_close}
      >
        <div className="modal-dialog " role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-center">
                {modules ? `Update ${modules.name}` : "Create New Session"}
              </h5>
              <span className="pointer text-danger" onClick={_close}>
                &times;
              </span>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <label htmlFor="exampleInputEmail2">Session Code</label>
                <input
                  type="number"
                  className="form-control form-control-lg"
                  id="exampleInputEmail2"
                  maxLength={4}
                  onChange={(e) => setcode(e.target.value)}
                  placeholder="Session Code"
                  value={code}
                />
              </div>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Session Name</label>
                <input
                  type="email"
                  autoFocus
                  onChange={(e) => setname(e.target.value)}
                  className="form-control form-control-lg"
                  id="exampleInputEmail1"
                  placeholder="Session Name"
                  value={name}
                />
              </div>
              <SelectStatus
                setpagegroup={setstatus}
                options={[
                  { name: "Active", value: true },
                  { name: "Inactive", value: false },
                ]}
                label="Select Status"
              />
            </div>
            <div className="modal-footer">
              {loading ? (
                <button type="button" className="btn btn-primary">
                  Please wait...
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={_save}
                >
                  Save changes
                </button>
              )}
            </div>
          </div>
        </div>
      </ReactModal>

      <div>
        {!modules && (
          <div
            style={{
              position: "fixed",
              right: "50px",
              bottom: "50px",
            }}
          >
            <button
              className="btn btn-icon btn-primary btn-rounded"
              onClick={_show}
            >
              <i className="ti-plus"></i>
            </button>
          </div>
        )}
      </div>
      <div>
        {modules && (
          <button
            className="btn btn-icon btn-primary btn-rounded"
            onClick={_show}
          >
            <i className="ti-pencil"></i>
          </button>
        )}
      </div>
    </div>
  );
};

export default BtnSession;
