import React from "react";
import { axiosget } from "../../helpers/httprequest";

const StudentCurrentCarryover = ({ id }) => {
  const [data, setdata] = React.useState(undefined);
  const [loading, setloading] = React.useState(true);
  React.useEffect(() => {
    (async () => {
      try {
        const req = await axiosget(`ms_course_result/carryover/${id}`);
        if (req.data?.type) {
          throw new Error(req.data?.message);
        }
        setdata(req.data);
      } catch ({ message }) {
        console.warn(message);
      }
      setloading(false);
    })();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      {loading ? (
        <>Please wait... laoding carryovers</>
      ) : (
        <div className="text-center pb-4">
          Student carryover unit(s): <b>{data?.carryover} unit(s)</b>, max
          credit unit(s) allowed for registration:{" "}
          <b>{data?.maxunit} unit(s)</b>
        </div>
      )}
    </div>
  );
};

export default StudentCurrentCarryover;
