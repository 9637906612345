import React, { useEffect, useContext } from "react";
import TblSession from "../../tables/TblSession";
import BtnSession from "../../Buttons/BtnSession";
import { contextapi } from "../../helpers/contextapi";

const Session = () => {
  const context = useContext(contextapi);
  useEffect(() => {
    context.setvalues({
      ...context.values,
      moduletitle: "Manage Academic Semester",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <h4>Manage Academic Semester</h4>
      <TblSession />
      <BtnSession />
    </>
  );
};

export default Session;
