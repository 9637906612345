import React from "react";
import BtnClassPost from "../../Buttons/BtnClassPost";
import BtnClassPostFile from "../../Buttons/BtnClassPostFile";
import DelClassPost from "../../Buttons/DelClassPost";

const ClassSubPosts = ({ info }) => {
  const [showfull, setshowfull] = React.useState(false);
  return (
    <>
      <div className="pt-3 pr-3 pl-3 pb-1 mb-2 d-flex justify-space-around align-items-bottom flex-grow flex-row">
        <div>{info.label}</div>
        <div className="d-flex pr-1">
          {info.icon === "paper" && <span className="icon-paper"></span>}
          {info.icon === "paper" && <BtnClassPost modules={info} />}

          {info.icon === "book" && (
            <a href={info?.file?.fulllink} target="_blank">
              <span className="icon-book"></span>
            </a>
          )}
          {info.icon === "book" && <BtnClassPostFile modules={info} />}
          <DelClassPost modules={info} />
        </div>
      </div>
      <span
        dangerouslySetInnerHTML={{
          __html: !showfull
            ? String(info.description)
                .replace(/\n/g, "<br /><br />")
                .substring(0, 300) + "..."
            : String(info.description).replace(/\n/g, "<br /><br />"),
        }}
      />
      {!showfull && (
        <a className="show-more-link" onClick={() => setshowfull(true)}>
          Read more
        </a>
      )}
      {showfull && (
        <a className="show-more-link" onClick={() => setshowfull(false)}>
          Read less
        </a>
      )}
    </>
  );
};

export default ClassSubPosts;
