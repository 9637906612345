import React from "react";
import ReactModal from "react-modal";
import { axiosget } from "../../helpers/httprequest";

const StudentProgressionHistory = ({ modules }) => {
  const [showmodal, setshowmodal] = React.useState(false);
  const [data, setdata] = React.useState(false);
  React.useEffect(() => {
    (async () => {
      try {
        const req = await axiosget(
          `ms_student_progression/student/${modules._id}`
        );
        if (req.data?.type) {
          throw new Error(req.data?.message);
        }
        setdata(req.data);
      } catch ({ message }) {
        console.warn(message);
      }
    })();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const _close = () => {
    setshowmodal(false);
  };
  const _show = () => {
    setshowmodal(true);
  };
  return (
    <div>
      <ReactModal
        isOpen={showmodal}
        className="ReactModal"
        onRequestClose={_close}
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-center text-uppercase">
                {modules.studentid} | {modules.firstname} {modules.middlename}{" "}
                {modules.lastname} Progression History
              </h5>
              <span className="pointer text-danger" onClick={_close}>
                &times;
              </span>
            </div>
            <div className="modal-body">
              <table className="table table-hover table-bordered">
                <thead>
                  <tr>
                    <th>SN</th>
                    <th>Level</th>
                    <th>Semester</th>
                    <th>Session</th>
                    <th>Carryover</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {data &&
                    data?.map((v, i) => (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>
                          {v.fromlevel} {`->`} {v.tolevel}
                        </td>
                        <td>
                          {v.fromsemester} {`->`} {v.tosemester}
                        </td>
                        <td>
                          {v.fromcode} {v.fromname} {`->`} {v.tocode} {v.toname}
                        </td>
                        <td>{v.carryover} Unit(s)</td>
                        <td>{v.status ? "Progressed" : "Not Progressed"}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </ReactModal>
      <span onClick={_show} className="text-primary pointer">
        {modules.studentid} | {modules.firstname} {modules.middlename}{" "}
        {modules.lastname} Progression History
      </span>
    </div>
  );
};

export default StudentProgressionHistory;
