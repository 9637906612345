import React from "react";
import { Link } from "react-router-dom";

const ClassroomNavbar = () => {
  const { innerWidth: width } = window;
  return (
    <nav className="navbar navbar-expand-lg fixed-top navbar-light bg-white">
      <div className="container">
        <Link className="navbar-brand" to="/my-module-profile">
          COE KANGERE [UG]
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="icon-menu"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className={`navbar-nav ${width < 600 ? "mr-auto" : "ml-auto"}`}>
            <li className="nav-item ">
              <Link className="nav-link" to="/staff/my-modules">
                Return to Portal
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/staff/class-materials">
                Course Materials
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/staff/my-module-contacts">
                Student Attendance
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/staff/my-class-result">
                Academic Results
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/staff/my-class-list">
                Student List
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/">
                Sign Out
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default ClassroomNavbar;
