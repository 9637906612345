import React from "react";
import TblUserGroup from "../../tables/TblUserGroup";
import BtnUserGroup from "../../Buttons/BtnUserGroup";

const UserGroup = () => {
  return (
    <>
      <TblUserGroup />
      <BtnUserGroup />
    </>
  );
};

export default UserGroup;
