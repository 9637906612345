import React from "react";
import { axiosget } from "../../helpers/httprequest";
import Loader from "../Loader";
import PickChangeOfCourseResultItem from "../PickChangeOfCourseResultItem";
const StudentResult = ({ id }) => {
  const [loading, setloading] = React.useState(false);
  const [data, setdata] = React.useState(undefined);
  React.useEffect(() => {
    (async () => {
      try {
        const req = await axiosget(`ms_course_result/change/${id}`);
        if (req.data?.type) {
          throw new Error(req.data?.message);
        }
        setdata(req.data);
      } catch ({ message }) {
        console.warn(message);
      }
      setloading(false);
    })();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      {loading ? (
        <Loader message={"Please wait... system loading"} />
      ) : (
        <>
          <>
            {data !== undefined && data.length <= 0 && (
              <div className="alert alert-info">
                Student does not have result at the moment, Click select the
                purple button to continue
              </div>
            )}
          </>
          <>
            {data !== undefined && data.length > 0 && (
              <>
                <div className="table-responsive">
                  <table className="table table-hover table-stripe">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Code</th>
                        <th>Title</th>
                        <th>Grade</th>
                        <th>Select</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((v, i) => (
                        <tr key={i}>
                          <th>{i + 1}</th>
                          <td>{String(v.code).toLocaleUpperCase()}</td>
                          <td>{v.name}</td>
                          <td>{v.grade}</td>
                          <td>
                            <PickChangeOfCourseResultItem item={v} />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </>
            )}
          </>
        </>
      )}
    </div>
  );
};

export default StudentResult;
