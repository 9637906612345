import React from "react";
import BtnSystemPages from "../../Buttons/BtnSystemPages";
import TblSystemPages from "../../tables/TblSystemPages";

const SystemPages = () => {
  return (
    <>
      <TblSystemPages />

      <BtnSystemPages />
    </>
  );
};

export default SystemPages;
