import React from "react";
import swal from "../../helpers/swal";
import { axiospost } from "../../helpers/httprequest";
import TblPublishResult from "../../tables/TblPublishResult";
import Alerts from "../../helpers/Alerts";

const PublishResult = () => {
  const [loading, setloading] = React.useState();
  const _publish = async () => {
    try {
      setloading(true);
      const req = await axiospost("ms_course_result/publish");
      if (req.status !== 200) {
        throw new Error("Sorry something went wrong, please try again later");
      }
      const { type, message } = req.data;
      swal(type, message, setloading, false);
    } catch ({ message }) {
      swal("error", message, undefined, false);
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          <Alerts
            type="info"
            title="[IMPORTANT] NOTIFICATION"
            message="Please note that this should be done at the end of a semester when lecturers have finished uploading their results, if this is done student result will have to be manually added one after the other, kindly trade with caution"
          />
          <TblPublishResult />
          {loading ? (
            <button className="btn my-4 btn-primary btn-block btn-lg">
              Please wait..., publishing results
            </button>
          ) : (
            <button
              className="btn my-4 btn-primary btn-block btn-lg"
              onClick={_publish}
            >
              Publish Academic Result
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default PublishResult;
