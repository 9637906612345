import React from "react";
import ReactModal from "react-modal";
import { axiosget, axiospost } from "../../helpers/httprequest";
import is_null from "../../helpers/is_null";
import swal from "../../helpers/swal";
import SelectStatus from "../../Select/SelectStatus";

const BtnCourseAllocation = () => {
  const [showmodal, setshowmodal] = React.useState(false);
  const [depts, setdepts] = React.useState(undefined);
  const [lecture, setlecture] = React.useState(undefined);
  const [department, setdepartment] = React.useState();
  const [staff, setstaff] = React.useState();
  const [loading, setloading] = React.useState(false);
  React.useEffect(() => {
    (async () => {
      try {
        const req = await axiosget("ms_course/allocation");
        if (req.data?.type) {
          throw new Error(req.data?.message);
        }
        const { lecturer, courses } = req.data;
        const dept = courses
          ?.map((v) => ({
            name: `${v.code} ${v.name}`,
            sort: v.name,
            value: v._id,
          }))
          ?.sort((a, b) => String(a.sort).localeCompare(b.sort));
        const lec = lecturer
          ?.map((v) => ({
            name: `${v.foldernumber} ${v.firstname} ${v.middlename} ${v.lastname}`,
            sort: v.firstname,
            value: v._id,
          }))
          ?.sort((a, b) => String(a.sort).localeCompare(b.sort));
        setdepts(dept);
        setlecture(lec);
      } catch ({ message }) {
        console.warn(message);
      }
      setloading(false);
    })();
  }, []);
  const _save = async () => {
    try {
      setloading(true);
      if (is_null(staff) || is_null(department)) {
        swal("error", "Please fill the form properly", setloading);
        return;
      }
      const req = await axiospost(`ms_course_allocation`, {
        staff,
        course: department,
      });

      if (req.status !== 200)
        throw new Error("Sorry something went wrong, please try again");
      const { type, message } = req.data;
      swal(type, message, setloading, false);
    } catch ({ message }) {
      swal("error", message, setloading, false);
    }
  };

  const _close = () => {
    setshowmodal(false);
    window.location.reload();
  };
  const _show = () => {
    setshowmodal(true);
  };

  return (
    <div>
      <ReactModal
        isOpen={showmodal}
        onRequestClose={_close}
        className="ReactModal"
      >
        <div className="modal-dialog " role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-center">
                Allocate Courses to Lecturers
              </h5>
              <span type="button" className=" text-danger" onClick={_close}>
                &times;
              </span>
            </div>
            <div className="modal-body">
              <SelectStatus
                setpagegroup={setdepartment}
                options={depts}
                label="Select Module"
              />
              <SelectStatus
                setpagegroup={setstaff}
                options={lecture}
                label="Select Course Lecturer"
              />
            </div>
            <div className="modal-footer">
              {loading ? (
                <button type="button" className="btn btn-primary">
                  Please wait...
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={_save}
                >
                  Save changes
                </button>
              )}
            </div>
          </div>
        </div>
      </ReactModal>

      <div>
        <div
          style={{
            position: "fixed",
            right: "50px",
            bottom: "50px",
          }}
        >
          <button
            className="btn btn-icon btn-primary btn-rounded"
            onClick={_show}
          >
            <i className="ti-plus"></i>
          </button>
        </div>
      </div>
    </div>
  );
};

export default BtnCourseAllocation;
