import React from "react";
import { useNavigate } from "react-router-dom";
import { contextapi } from "../../helpers/contextapi";

const BtnClassProfile = ({ id }) => {
  const navigate = useNavigate();
  const context = React.useContext(contextapi);
  return (
    <button
      onClick={() => {
        localStorage.setItem("classroomCourse", id);
        context.setvalues({ ...context.values, classroomCourse: id });
        navigate(`/my-module-profile`);
      }}
      className="btn btn-icon btn-primary btn-rounded"
    >
      <i className="icon-expand"></i>
    </button>
  );
};

export default BtnClassProfile;
