import React from "react";

const DisplayInformationResult = ({ data }) => {
  const { result: r } = data;
  const { results } = data?.check;
  return (
    <div className="row ">
      <div className="col-md-12 border m-1 p-1 mb-2">
        {`${r.firstname} ${r.middlename} ${r.lastname} | ${r.jamb} | ${r.phonenumber}`.toLocaleUpperCase()}
        <hr />
        <div className="mb-2">
          {results?.map((d, i) => (
            <span className="border p-2 m-1" key={new Date().getTime() + i}>
              {d.subject} | {d.score}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DisplayInformationResult;
