import React from "react";
import TblProgramme from "../../tables/TblProgramme";
import BtnProgramme from "../../Buttons/BtnProgramme";

const Programme = () => {
  return (
    <>
      <h4>Manage Academic Programme</h4>

      <TblProgramme />

      <BtnProgramme />
    </>
  );
};

export default Programme;
