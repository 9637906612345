import React, { useEffect } from "react";
import TblResultModiration from "../../tables/TblResultModiration";
import BtnResultModeration from "../../Buttons/BtnResultModeration";

const ResultModeration = () => {
  useEffect(() => {
    document.title = "Academic Result Moderation";
  }, []);
  return (
    <>
      <TblResultModiration />
      <BtnResultModeration />
    </>
  );
};

export default ResultModeration;
