import React from "react";
import is_null from "../../helpers/is_null";
import { axiosget, axiosput } from "../../helpers/httprequest";
import swal from "../../helpers/swal";
import ReactModal from "react-modal";

const BtnEditApplicant = ({ modules }) => {
  const [open, setopen] = React.useState(null);
  const [loading, setloading] = React.useState(null);
  const firstname = React.useRef();
  const middlename = React.useRef();
  const lastname = React.useRef();
  const jamb = React.useRef();
  const emailaddress = React.useRef();
  const phonenumber = React.useRef();
  const [fname, setfname] = React.useState("");
  const [mname, setmname] = React.useState("");
  const [lname, setlname] = React.useState("");
  const [jmb, setjmb] = React.useState("");
  const [email, setemail] = React.useState("");
  const [phone, setphone] = React.useState("");

  const [showmodal, setshowmodal] = React.useState(false);
  React.useEffect(() => {
    if (!is_null(open)) {
      (async () => {
        try {
          const { applicant } = modules;
          const req = await axiosget(`ms_applicant/one/${applicant}`);
          if (req.status !== 200) {
            throw new Error(
              "Sorry, something went wrong system could not fetch applicant information"
            );
          }
          if (req?.data?.type) {
            throw new Error(req.data.message);
          }
          const {
            firstname: firstname1,
            middlename: middlename1,
            lastname: lastname1,
            jamb: jamb1,
            emailaddress: emailaddress1,
            phonenumber: phonenumber1,
          } = req.data;
          setfname(firstname1);
          setmname(middlename1);
          setlname(lastname1);
          setemail(emailaddress1);
          setphone(phonenumber1);
          setjmb(jamb1);
          setopen(null);
        } catch ({ message }) {
          swal("error", message, undefined, false);
        }
      })();
    }
  }, [open]);
  const _save = async () => {
    try {
      setloading(true);
      const fn = firstname.current.value;
      const mn = middlename.current.value;
      const ln = lastname.current.value;
      const em = emailaddress.current.value;
      const ph = phonenumber.current.value;
      const jb = jamb.current.value;

      if (
        is_null(fn) ||
        is_null(ln) ||
        is_null(em) ||
        is_null(ph) ||
        is_null(jb)
      ) {
        throw new Error("Please fill the form correctly");
      }
      const { applicant } = modules;
      const req = await axiosput(`ms_applicant/update/${applicant}`, {
        firstname: fn,
        middlename: mn,
        lastname: ln,
        emailaddress: em,
        phonenumber: ph,
        jamb: jb,
      });
      if (Number.parseInt(req.status) !== 200) {
        throw new Error("Sorry something went wrong, please try again");
      }

      const { type, message } = req.data;
      swal(type, message, setloading, false);
    } catch ({ message }) {
      swal("error", message, setloading);
    }
  };
  return (
    <div>
      <ReactModal
        isOpen={showmodal}
        onRequestClose={() => setshowmodal(false)}
        className="ReactModal"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-center text-uppercase">
                Edit {modules.firstname}'s Application Profile
              </h5>
            </div>
            <div className="modal-body" style={{ minHeight: "70vh" }}>
              <div className="pt-1">
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="firstname">First Name</label>
                      <input
                        className="form-control form-control-lg"
                        id="firstname"
                        placeholder="First Name"
                        onChange={(r) => setfname(r.target.value)}
                        ref={firstname}
                        value={fname}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="middlename">Middle Name</label>
                      <input
                        className="form-control form-control-lg"
                        id="middlename"
                        placeholder="Middle Name"
                        ref={middlename}
                        onChange={(r) => setmname(r.target.value)}
                        value={mname}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="lastname">Last Name</label>
                      <input
                        className="form-control form-control-lg"
                        id="exampleInputEmail1"
                        placeholder="Last Name"
                        ref={lastname}
                        onChange={(r) => setlname(r.target.value)}
                        value={lname}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="lastname">Jamb Admission Number</label>
                      <input
                        className="form-control form-control-lg"
                        id="exampleInputEmail1"
                        placeholder="Jamb Admission Number"
                        ref={jamb}
                        onChange={(r) => setjmb(r.target.value)}
                        value={jmb}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="lastname">Email Address</label>
                      <input
                        type="email"
                        className="form-control form-control-lg"
                        id="exampleInputEmail1"
                        placeholder="Email Address"
                        ref={emailaddress}
                        onChange={(r) => setemail(r.target.value)}
                        value={email}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="lastname">Phone Number</label>
                      <input
                        className="form-control form-control-lg"
                        id="exampleInputEmail1"
                        placeholder="Phone Number"
                        ref={phonenumber}
                        onChange={(r) => setphone(r.target.value)}
                        value={phone}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <div className="mt-3">
                {!loading && (
                  <div className="d-flex">
                    <div className="mr-3">
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => setshowmodal(false)}
                      >
                        Close
                      </button>
                    </div>
                    <button
                      className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                      onClick={_save}
                    >
                      Update Applicant
                    </button>
                  </div>
                )}
                {loading && (
                  <button className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn">
                    Please Wait...
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </ReactModal>
      <button
        className="btn btn-icon btn-primary btn-rounded"
        onClick={() => {
          setopen(true);
          setshowmodal(true);
        }}
      >
        <i className="fa fa-edit" />
      </button>
    </div>
  );
};

export default BtnEditApplicant;
