import React from "react";
import ReactModal from "react-modal";
import { axiosget } from "../../helpers/httprequest";
import is_money from "../../helpers/is_money";
import is_null from "../../helpers/is_null";
import moment from "../../helpers/moment";

const BtnWalletHistory = ({ modules }) => {
  const [showmodal, setshowmodal] = React.useState(false);
  const [data, setdata] = React.useState(undefined);
  const [wallet, setwallet] = React.useState(0);

  const _close = () => {
    setshowmodal(false);
  };
  const _show = () => {
    setshowmodal(true);
  };

  React.useEffect(() => {
    (async () => {
      try {
        const req = await axiosget(
          `ms_student_wallet_transactions/student/${modules._id}`
        );
        if (req.data?.type) {
          throw new Error(req.data?.message);
        }
        const { find, wallet } = req.data;
        setdata(find);
        const account = !is_null(wallet?.amount) ? wallet.amount : 0;
        setwallet(account);
      } catch ({ message }) {
        console.warn(message);
      }
    })();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <ReactModal
        isOpen={showmodal}
        className="ReactModal"
        onRequestClose={_close}
      >
        <div className="modal-dialog " role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-center">
                {modules.firstname} Wallet History
              </h5>
              <span className="pointer text-danger" onClick={_close}>
                &times;
              </span>
            </div>
            <div className="modal-body">
              <p className="card-title text-uppercase">
                <p className="">{modules.studentid}</p>
                <p className="">
                  {modules.firstname} {modules.middlename} {modules.lastname}
                </p>
                <p className="">Available Wallet Balance</p>
                <div className="receipt_amount">{is_money(wallet)}</div>
              </p>
              <table className="table table-hover table-bordered w-100">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Transaction Code</th>
                    <th>Amount</th>
                    <th>Status</th>
                    <th>Transaction Type</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  {data &&
                    data.map((v, i) => (
                      <tr>
                        <th>{i + 1}</th>
                        <td>{v.trx}</td>
                        <td>{is_money(v.amount)}</td>
                        <td className="text-capitalize">{v.status}</td>
                        <td>{v.trxtype ? "Deposit" : "Widthdrawal"}</td>
                        <td className="text-capitalize">
                          {moment(v.payment.created_at)} ago
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </ReactModal>

      <div>
        <button
          className="btn btn-icon btn-primary btn-rounded"
          onClick={_show}
        >
          <i className="ti-timer"></i>
        </button>
      </div>
    </div>
  );
};

export default BtnWalletHistory;
