import React from "react";
import { Link } from "react-router-dom";
import { contextapi } from "../../helpers/contextapi";
import SubMenuItems from "../SubMenuItems";

const Navbar = () => {
  const context = React.useContext(contextapi);

  return (
    <nav className="navbar  navbar-expand-lg navbar-light bg-light navbar-fixed-top">
      <div className="container-fluid">
        <a className="navbar-brand" href="/app/dashboard">
          ATBCOE KANGERE
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <div className="mr-auto"></div>
          <ul className="navbar-nav">
            {context.values?.main_menu?.map((v, i) => (
              <li key={v._id} className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  data-toggle="dropdown"
                  href={`#navItem${i}`}
                  aria-controls="error"
                >
                  <i className={`${v.icon} menu-icon`} size={10} />
                  <span className="menu-title">{v.name}</span>
                  <i className="menu-arrow"></i>
                </a>

                <SubMenuItems sub={v.result} i={i} />
              </li>
            ))}
            <li className="nav-item " title="Sign Out">
              <Link
                className="nav-link"
                to="/"
                style={{ textDecoration: "none" }}
              >
                <i className="ti-power-off text-danger"></i>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
