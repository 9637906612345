const is_student_status = (status) => {
  let detail;
  if (status === 0) {
    detail = "Inactive";
  } else if (status === 1) {
    detail = "Active";
  } else if (status === 2) {
    detail = "Rusticated";
  } else if (status === 3) {
    detail = "Deferred";
  } else if (status === 4) {
    detail = "Death";
  } else {
    detail = "Undefined";
  }

  return detail;
};

export default is_student_status;
