import React from "react";
import { axiosput } from "../../helpers/httprequest";
import is_null from "../../helpers/is_null";
import swal from "../../helpers/swal";
import Modal from "react-modal";
import SelectStatus from "../../Select/SelectStatus";
import MainFinal from "../../components/MainFinal";

const BtnApplications = ({ modules }) => {
  const [showmodal, setshowmodal] = React.useState(false);
  const [showapprove, setshowapprove] = React.useState(false);
  const [showreject, setshowreject] = React.useState(false);
  const [condition, setcondition] = React.useState("");
  const [offer, setoffer] = React.useState(undefined);
  const [rejectloading, setrejectloading] = React.useState(false);
  const [approveloading, setapproveloading] = React.useState(false);
  const [level, setlevel] = React.useState(undefined);

  const _save_rejection = async () => {
    try {
      setrejectloading(true);
      const { _id: id } = modules;
      if (is_null(condition)) {
        swal("error", "Please fill the form properly", setrejectloading);
        return;
      }
      let req = await axiosput(`ms_applications/reject/${id}`, {
        condition,
      });

      if (req.status !== 200)
        throw new Error("Sorry something went wrong, please try again");
      const { type, message } = req.data;
      swal(type, message, setrejectloading, true);
      if (type === "success") {
        _close();
      }
    } catch ({ message }) {
      swal("error", message, setrejectloading);
    }
  };

  const _approve_application = async () => {
    try {
      setapproveloading(true);
      const { _id: id } = modules;
      if (offer !== "provisional" && is_null(condition)) {
        swal("error", "Please fill the form properly", setapproveloading);
        return;
      }

      let req = await axiosput(`ms_applications/approve/${id}`, {
        condition,
        offer,
        level,
      });

      if (req.status !== 200)
        throw new Error("Sorry something went wrong, please try again");
      const { type, message } = req.data;

      if (type === "success") {
        swal(type, message, setshowapprove, false);
      } else {
        swal(type, message, setapproveloading, false);
      }
    } catch ({ message }) {
      swal("error", message, setrejectloading);
    }
  };

  React.useEffect(() => {
    if (offer === "provisional") {
      setcondition("Meet all conditions");
    }
    // eslint-disable-next-line
  }, [offer]);

  const _close = () => {
    setshowmodal(false);
  };

  const _approve = () => {
    setshowapprove(true);
    _close();
  };
  const _reject = () => {
    setshowreject(true);
    _close();
  };

  return (
    <>
      <Modal
        isOpen={showapprove}
        onRequestClose={() => setshowmodal(false)}
        className="ReactModal"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-center text-uppercase">
                Approve {modules.firstname}'s Application
              </h5>
            </div>
            <div className="modal-body">
              <SelectStatus
                label="Select the type of offer"
                setpagegroup={setoffer}
                options={[
                  { name: "Conditional Admission", value: "conditional" },
                  { name: "Provisional Admission", value: "provisional" },
                ]}
              />
              <SelectStatus
                options={[
                  { name: "100 Level", value: "100" },
                  { name: "200 Level", value: "200" },
                ]}
                label="Select Admission Level"
                setpagegroup={setlevel}
              />

              {offer && offer === "conditional" && (
                <>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail2">
                      Kindly state the condition for this admission
                    </label>
                    <textarea
                      type="email"
                      className="form-control form-control-lg"
                      id="exampleInputEmail2"
                      rows={5}
                      onChange={(e) => setcondition(e.target.value)}
                      placeholder="Kindly state the condition for this admission"
                      value={condition}
                    />
                  </div>
                </>
              )}
            </div>
            <div className="modal-footer">
              {approveloading ? (
                <button type="button" className="btn btn-primary">
                  Please wait...
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={_approve_application}
                >
                  Offer Admission
                </button>
              )}
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => setshowapprove(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={showreject}
        onRequestClose={() => setshowmodal(false)}
        className="ReactModal"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-center text-uppercase">
                Reject {modules.firstname}'s Application
              </h5>
            </div>
            <div className="modal-body">
              <p>
                Applicant did not meet the requirement for conditional admission
              </p>
              <div className="form-group">
                <label htmlFor="exampleInputEmail2">
                  Kindly state the reason for rejecting this application
                </label>
                <textarea
                  type="email"
                  className="form-control form-control-lg"
                  id="exampleInputEmail2"
                  rows={5}
                  onChange={(e) => setcondition(e.target.value)}
                  placeholder="Kindly state the reason for rejecting this application"
                  value={condition}
                />
              </div>
            </div>
            <div className="modal-footer">
              {rejectloading ? (
                <button type="button" className="btn btn-primary">
                  Please wait...
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={_save_rejection}
                >
                  Reject Application
                </button>
              )}

              <button
                type="button"
                className="btn btn-danger"
                onClick={() => setshowreject(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={showmodal}
        onRequestClose={() => setshowmodal(false)}
        className="ReactModal"
      >
        <div className="modal-dialog large-modal" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-center text-uppercase">
                process {modules.firstname}'s Application
              </h5>
            </div>
            <div className="modal-body" style={{ minHeight: "75vh" }}>
              <MainFinal code={modules.code} id={modules._id} />
            </div>
            <div className="modal-footer">
              <>
                {(is_null(modules.status) || modules.status === 0) && (
                  <>
                    <button
                      type="button"
                      className="btn btn-info"
                      onClick={_reject}
                    >
                      Reject Application
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={_approve}
                    >
                      Approve Application
                    </button>
                  </>
                )}
              </>
              <>
                {!is_null(modules.status) &&
                  (modules.status === 1 || modules.status === 2) && (
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() =>
                        swal(
                          "success",
                          "This application has already been approved",
                          undefined,
                          false
                        )
                      }
                    >
                      Application has been approved
                    </button>
                  )}
              </>
              <>
                {!is_null(modules.status) && modules.status === 3 && (
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() =>
                      swal(
                        "error",
                        `This application has  been rejected<br> Thre reason for rejection is:<br><b>${modules.rejection}</b>`,
                        undefined,
                        false
                      )
                    }
                  >
                    Application has been rejected
                  </button>
                )}
              </>
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => setshowmodal(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <div>
        <button
          className="btn btn-icon btn-primary btn-rounded"
          onClick={() => {
            setshowmodal(true);
          }}
        >
          <i className="fa fa-eye" />
        </button>
      </div>
    </>
  );
};

export default BtnApplications;
