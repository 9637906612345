import React from "react";
import TblDepartment from "../../tables/TblDepartment";
import BtnDepartment from "../../Buttons/BtnDepartment";

const Department = () => {
  return (
    <>
      <h4>Manage Academic Department</h4>

      <TblDepartment />

      <BtnDepartment />
    </>
  );
};

export default Department;
