import React from "react";
import CheckIndividualPermission from "../../components/CheckIndividualPermission";
import Loader from "../../components/Loader";
import { axiosget } from "../../helpers/httprequest";
import swal from "../../helpers/swal";

const TblIndividualPermission = ({ staff }) => {
  const [loading, setloading] = React.useState(true);
  const [data, setdata] = React.useState(null);
  React.useEffect(() => {
    (async () => {
      try {
        const req = await axiosget("ms_pages");
        setdata(req.data);
        setloading(false);
      } catch ({ message }) {
        swal("error", message, setloading);
      }
    })();
  }, []);
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <table
          id="example"
          className="table table-hover table-striped display expandable-table w-100"
        >
          <thead>
            <tr>
              <th>#</th>
              <th>Page</th>
              <th>Group</th>
              <th>Link</th>
              <th>Permit</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((v, i) => (
              <tr key={i}>
                <th>{i + 1}</th>
                <td>{v.name}</td>
                <td>{v.page_group.name}</td>
                <td>{v.link}</td>
                <td>
                  <CheckIndividualPermission staff={staff} key={i} page={v} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </>
  );
};

export default TblIndividualPermission;
