import React from "react";
import { useParams } from "react-router-dom";
import is_null from "../../helpers/is_null";
import { axiosget } from "../../helpers/httprequest";
import Loader from "../../components/Loader";
import PrintHeader from "../../Print/PrintHeader";
import PrintStudentCardProfile from "../../Print/PrintStudentCardProfile";
import TblGPADisplay from "../../tables/TblGPADisplay";

const PrintResultStatement = () => {
  const param = useParams();
  const [loading, setloading] = React.useState(false);
  const [student, setstudent] = React.useState(undefined);
  const [session, setsession] = React.useState(undefined);
  const [programme, setprogramme] = React.useState(undefined);
  const [registration, setregistration] = React.useState(undefined);
  const { id } = param;
  if (is_null(id)) {
    window.close();
  }
  React.useEffect(() => {
    (async () => {
      try {
        setloading(true);
        const req = await axiosget(`ms_course_result/print-lecturer/${id}`);
        if (req.data?.type) {
          throw new Error(req.data?.message);
        }
        const {
          student: info,
          session: trimester,
          programme: prog,
          registration: reg,
        } = req.data;
        setstudent(info);
        setsession(trimester);
        setprogramme(prog);
        setregistration(reg);
      } catch ({ message }) {
        console.warn(message);
      }
      setloading(false);
    })();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="mainPrintContainer">
      {loading ? (
        <Loader />
      ) : (
        <>
          <PrintHeader />
          <>
            {student && (
              <PrintStudentCardProfile
                student={student}
                session={session}
                programme={programme}
                title="Statement of Result"
              />
            )}
          </>
          <>
            {registration && (
              <>
                {registration?.map((v, k) => (
                  <div className="printStudentRegistration" key={k}>
                    <div className="semesterInformation">
                      {v.level} {v.semester}
                    </div>
                    <div>
                      <table className="styled-tables">
                        <thead>
                          <tr>
                            <th>SN</th>
                            <th>Code</th>
                            <th>Name</th>
                            <th>Assessment</th>
                            <th>Examination</th>
                            <th>Total</th>
                            <th>Credit</th>
                            <th>Point</th>
                            <th>Grade</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {v?.register?.map((r, i) => (
                            <tr key={i}>
                              <th>{i + 1}</th>
                              <td>{r?.code}</td>
                              <td>{r?.name}</td>
                              <td>{r?.cascore}</td>
                              <td>{r?.examscore}</td>
                              <td>{r?.total}</td>
                              <td>{r?.credit}</td>
                              <td>{r?.point}</td>
                              <td>{r.grade}</td>
                              <td>{r.ispass ? "Pass" : "Fail"}</td>
                            </tr>
                          ))}
                        </tbody>
                        <tfoot>
                          <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>Total</td>
                            <td>
                              {v?.register
                                ?.map((v) => v.credit)
                                .reduce((a, b) => a + b, 0)}
                            </td>
                            <td>
                              {v?.register
                                ?.map((v) => v.point)
                                .reduce((a, b) => a + b, 0)}
                            </td>
                            <td></td>
                            <td></td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                    <TblGPADisplay sn={k} result={v.register} key={k} />
                  </div>
                ))}
              </>
            )}
          </>
        </>
      )}
    </div>
  );
};

export default PrintResultStatement;
