import React from "react";
import CheckGroupMember from "../../components/CheckGroupMember";
import Loader from "../../components/Loader";
import { axiosget } from "../../helpers/httprequest";
import is_null from "../../helpers/is_null";
import is_table from "../../helpers/is_table";

const TblGroupMembers = ({ dataset }) => {
  const [loading, setloading] = React.useState(true);
  const [data, setdata] = React.useState(undefined);
  React.useEffect(() => {
    (async () => {
      try {
        const req = await axiosget("ms_staff/basic");
        setdata(req.data);
        setloading(false);
      } catch ({ message }) {
        console.warn(message);
      }
    })();
  }, []);
  is_table();
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <table
          id="example"
          className="table table-hover table-striped display expandable-table w-100"
        >
          <thead>
            <tr>
              <th>#</th>
              <th>ID</th>
              <th>Full Name</th>
              <th>Department</th>
              <th>Assign</th>
            </tr>
          </thead>
          <tbody>
            {!is_null(data) &&
              data?.map((v, i) => (
                <tr key={i}>
                  <th>{i + 1}</th>
                  <td>{v.foldernumber}</td>
                  <td>
                    {v.firstname} {v.middlename} {v.lastname}
                  </td>
                  <td>{v.department}</td>
                  <td>
                    <CheckGroupMember
                      staff={v}
                      key={i}
                      usergroup={dataset._id}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      )}
    </>
  );
};

export default TblGroupMembers;
