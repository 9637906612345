import React from "react";
import { Link } from "react-router-dom";

const SubMenuItems = ({ sub, i }) => {
  return (
    <ul
      className="dropdown-menu"
      aria-labelledby="navbarDropdown"
      id={`navItem${i}`}
    >
      {sub?.map((v, i) => {
        if (v?.visible === false) {
          return;
        }
        return (
          <li key={i} className="nav-item">
            <Link className="nav-link" to={v.link}>
              {v.name}
            </Link>
          </li>
        );
      })}
    </ul>
  );
};

export default SubMenuItems;
