import React from "react";
import BtnClassTopic from "../../Buttons/BtnClassTopic";
import DelClassTopic from "../../Buttons/DelClassTopic";
import ClassSubPosts from "../ClassSubPost";

const FullTopicView = ({ data }) => {
  return (
    <div className="fullTopic ">
      <div className="d-flex justify-space-arounds align-items-bottom ">
        <h1 className="p1topic pt-3">{data?.label}</h1>
        <div className="d-flex flex-row">
          <BtnClassTopic modules={data} />
          <DelClassTopic modules={data} />
        </div>
      </div>

      {data?.result?.map((v, i) => (
        <ClassSubPosts info={v} key={i} />
      ))}
    </div>
  );
};

export default FullTopicView;
