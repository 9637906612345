import React from "react";
import { axiosget } from "../../helpers/httprequest";

const StudentProfile = ({ id }) => {
  const [bioinfo, setbioinfo] = React.useState(undefined);

  React.useEffect(() => {
    (async () => {
      try {
        const req = await axiosget(`ms_students/bio/${id}`);
        if (req.data) {
          const { bio } = req.data;
          setbioinfo(bio);
        }
      } catch ({ message }) {
        console.log(message);
      }
    })();
  }, []);

  return (
    <div className="row">
      <div className="col-md-12 grid-margin transparent">
        <div className="card">
          <div className="card-body">
            <div className="container">
              <div className="row">
                <div className="col-md-4">
                  <div className="pr-5">
                    <img
                      src={bioinfo?.photo?.fulllink}
                      className="w-100 br-10"
                      alt="passport"
                    />
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="row bordered_bottom">
                    <div className="col-md-4 text-muted ">Student ID</div>
                    <div className="col-md-8 ">{bioinfo?.studentid}</div>
                  </div>
                  <div className="row my-2 bordered_bottom">
                    <div className="col-md-4 text-muted">Full Name</div>
                    <div className="col-md-8">
                      {bioinfo?.firstname} {bioinfo?.middlename}{" "}
                      {bioinfo?.lastname}
                    </div>
                  </div>
                  <div className="row my-2 bordered_bottom">
                    <div className="col-md-4 text-muted">Date of Birth</div>
                    <div className="col-md-8">
                      {bioinfo &&
                        new Date(bioinfo?.dateofbirth).toLocaleDateString()}
                    </div>
                  </div>

                  <div className="row my-2 bordered_bottom">
                    <div className="col-md-4 text-muted">Phont Number</div>
                    <div className="col-md-8">{bioinfo?.phonenumber}</div>
                  </div>
                  <div className="row my-2 bordered_bottom">
                    <div className="col-md-4 text-muted">Email Address</div>
                    <div className="col-md-8">{bioinfo?.emailaddress}</div>
                  </div>
                  <div className="row my-2 bordered_bottom">
                    <div className="col-md-4 text-muted">Religion</div>
                    <div className="col-md-8">{bioinfo?.religion}</div>
                  </div>
                  <div className="row my-2 bordered_bottom">
                    <div className="col-md-4 text-muted">Gender</div>
                    <div className="col-md-8">{bioinfo?.gender}</div>
                  </div>
                  <div className="row my-2 bordered_bottom">
                    <div className="col-md-4 text-muted">Marital Status</div>
                    <div className="col-md-8">{bioinfo?.maritalstatus}</div>
                  </div>
                  <div className="row my-2 bordered_bottom">
                    <div className="col-md-4 text-muted">Qualification</div>
                    <div className="col-md-8">{bioinfo?.qualification}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentProfile;
