import React from "react";
import ReactModal from "react-modal";
import StudentProgressionHistory from "../../components/StudentProgressionHistory";
import { axiosget } from "../../helpers/httprequest";
import Alerts from "../../helpers/Alerts";
import swal from "../../helpers/swal";
import StudentCardProfile from "../../components/StudentCardProfile";
import RegisterModule from "../../components/RegisterModule";
import Loader from "../../components/Loader";

const BtnAddDrop = ({ modules }) => {
  const [showmodal, setshowmodal] = React.useState(false);
  const [loading, setloading] = React.useState(false);
  const [error, seterror] = React.useState(false);
  const [registration, setregistration] = React.useState(undefined);
  const _close = () => {
    setshowmodal(false);
  };
  const _show = async () => {
    try {
      setloading(true);
      setshowmodal(true);
      const req = await axiosget(
        `ms_course_registration/registration/${modules._id}`
      );
      if (!req.data.type) {
        setregistration(req.data);
      } else {
        seterror(req.data.message);
      }
      setloading(false);
    } catch ({ message }) {
      swal("error", message, undefined, false);
    }
  };

  return (
    <div>
      <ReactModal
        isOpen={showmodal}
        className="ReactModal"
        onRequestClose={_close}
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-center text-uppercase">
                {modules.studentid} | {modules.firstname} {modules.middlename}{" "}
                {modules.lastname} Add & Drop Registration
              </h5>
              <span
                type="button"
                className="pointer text-danger"
                onClick={_close}
              >
                &times;
              </span>
            </div>
            <div className="modal-body ">
              <StudentCardProfile student={modules} />
              <StudentProgressionHistory modules={modules} />
              <div className="row my-2">
                <>{loading && <Loader />}</>
                <>
                  {registration && (
                    <div className="col-md-12 table-responsive">
                      <table className="table table-hover table-bordered">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Code</th>
                            <th>Title</th>
                            <th>Level</th>
                            <th>Semester</th>
                            <th>Credit</th>
                            <th>Status</th>
                            <th>Register</th>
                          </tr>
                        </thead>
                        <tbody>
                          {registration?.map((v, i) => (
                            <tr key={i}>
                              <th>{i + 1}</th>
                              <td>{v.code}</td>
                              <td>{v.name}</td>
                              <td>{v.level}</td>
                              <td>{v.semester}</td>
                              <td>{v.credit} Unit</td>
                              <td>
                                {v.isfresh ? "Fresh" : "Carryover"} Registration
                              </td>
                              <td>
                                <RegisterModule modules={v} student={modules} />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </>
                {error && (
                  <Alerts
                    message={error}
                    type="danger"
                    title="ERROR ENCOUNTERED"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </ReactModal>
      <div>
        <button
          className="btn btn-icon btn-primary btn-rounded"
          onClick={_show}
        >
          <i className="ti-layout-list-thumb"></i>
        </button>
      </div>
    </div>
  );
};

export default BtnAddDrop;
